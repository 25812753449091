import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Search, Sliders } from 'react-feather';

export default function Billing() {
	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					<div className="searchbar">
						<div className="filters">
							<button>
								All filters <Sliders size={24} />
							</button>
						</div>
						<div className="search-input">
							<Search size={16} />
							<input type="text" placeholder="Search" />
						</div>
					</div>
					<div className="dashboard-content">
						<h1>Billing</h1>
						<p>Coming soon... Billing report</p>
					</div>
				</main>
			</div>
		</div>
	);
}
