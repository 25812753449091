
import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import api from '../config/api'; 



interface ErrorType {
  message: string; 
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  user: any; // Define user type based on your API response structure
}



interface LoginPayload {
    email: string;
    password: string;
  }

  export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ email, password }: LoginPayload, { rejectWithValue }) => {
      try {
        const response = await api.post<LoginResponse>('/Accounts/Login', { email, password });
        if (!response || !response.data) {
          throw new Error('No response from the server');
        }
        // Assuming the response data includes accessToken and refreshToken
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      } 
    }
  );

  export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (_, { getState, rejectWithValue }) => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        return rejectWithValue('Refresh token not found');
      }
      try {
        const response = await api.post('/Accounts/Refresh', { refreshToken });
        localStorage.setItem('token', response.data.accessToken);
        if (response.data.refreshToken) {
          localStorage.setItem('refreshToken', response.data.refreshToken);
        }
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );


  interface CreateUserPayload {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: string; 
    metadata: {
      [key: string]: string;
    };
  }

  
  export const createUser = createAsyncThunk(
    'auth/createUser',
    async (userData: CreateUserPayload, { rejectWithValue, getState }) => {
      try {
        // Fetch the token from the current state
        const token = (getState() as any).auth.token;
  
        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}`
        };
  
        const response = await api.post('/Accounts', userData, { headers });
        
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

export const fetchUserSetting = createAsyncThunk(
    'auth/fetchUserSetting',
    async (userId, { rejectWithValue, getState }) => {
        try {
            let token = (getState() as any).auth.token;
            let headers = { Authorization: `Bearer ${token}` };
            try {
                const response = await api.get(`/Users/${userId}`, { headers });
                return response.data;
            } catch (error: any) {
                if (error.response.status === 401) {
                    const refreshToken = localStorage.getItem('refreshToken');
                    if (!refreshToken) {
                        localStorage.removeItem('token');
                        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                        return rejectWithValue('Refresh token not found');
                    }
                    try {
                        const response = await api.post('/Accounts/Refresh', { refreshToken });
                        localStorage.setItem('token', response.data.accessToken);
                        if (response.data.refreshToken) {
                            localStorage.setItem('refreshToken', response.data.refreshToken);
                        }
                        // Retry the API call to /Users/${userId} with the new token
                        token = response.data.accessToken;
                        headers = { Authorization: `Bearer ${token}` };
                        const retryResponse = await api.get(`/Users/${userId}`, { headers });
                        return retryResponse.data;
                    } catch (error: any) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                        return rejectWithValue(error.response.data);
                    }
                }
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                return rejectWithValue(error.response.data);
            }
        } catch (error: any) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            return rejectWithValue(error.response.data);
        }
    }
);

  // create GET /Accounts/Selft token required

 export const fetchSelf = createAsyncThunk(
    'auth/fetchSelf',
    async (_, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.get('/Accounts/Self', { headers });
        return response.data;
      } catch (error: any) {
        if (error.response.status === 401) {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) {
                localStorage.removeItem('token');
                if (!window.location.search.includes('errorReload')) {
                    window.location.href += '?errorReload=true';
                }
                return rejectWithValue('Refresh token not found');
            }
            try {
                const response = await api.post('/Accounts/Refresh', { refreshToken });
                localStorage.setItem('token', response.data.accessToken);
                if (response.data.refreshToken) {
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                }
                return response.data;
            } catch (error: any) {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                if (!window.location.search.includes('errorReload')) {
                    window.location.href += '?errorReload=true';
                }
                return rejectWithValue(error.response.data);
            }
        }
        return rejectWithValue(error.response.data);
    }
    }
  );
  


  interface RoleModificationPayload {
    email: string;
    role: string;
  }

  export const addRoleToUser = createAsyncThunk(
    'auth/addRoleToUser',
    async (payload: RoleModificationPayload, { rejectWithValue, getState }) => {
      try {
        // Fetch the token from the current state
        const token = (getState() as any).auth.token;
  
        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}`
        };
  
        const response = await api.put('/Accounts/Role', payload, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const removeRoleFromUser = createAsyncThunk(
    'auth/removeRoleFromUser',
    async (payload: RoleModificationPayload, { rejectWithValue, getState }) => {
      try {
        // Fetch the token from the current state
        const token = (getState() as any).auth.token;
  
        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}`
        };
  
        const response = await api.delete('/Accounts/Role', {
          headers: headers,
          data: payload // For DELETE requests, the payload must be in the request body
        });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );



interface Tokens {
  token: string;
  refreshToken: string;
}

// Define the payload type
type fetchUsersPayload = {
  pageSize?: number;
  page?: number;
  sortOrder?: 'ASC' | 'DESC';
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
};

export const fetchUsers = createAsyncThunk(
  'auth/fetchUsers',
  async (
    { pageSize = 1000,  page, sortOrder, firstName, lastName, phone, email }: fetchUsersPayload = {},
    { rejectWithValue, getState, dispatch }: { rejectWithValue: any, getState: any, dispatch: any }
  ) => {
    try {
      const state = getState() as any;
      const token = state.auth.token;
      const refreshToken = state.auth.refreshToken;

      const headers = {
        Authorization: `Bearer ${token}`
      };

      let query = `/Accounts?`;
      if (pageSize) query += `&pageSize=${pageSize}`;
      if (page) query += `&page=${page}`;
      if (sortOrder) query += `&sortOrder=${sortOrder}`;
      if (firstName) query += `&firstName=${encodeURIComponent(firstName)}`;
      if (lastName) query += `&lastName=${encodeURIComponent(lastName)}`;
      if (phone) query += `&phone=${phone}`;
      if (email) query += `&email=${encodeURIComponent(email)}`;

      // Add sort parameter
      query += `&sort=firstName`;

      const response = await api.get(query, { headers });

      // If the token is expired, refresh it
      if (response.status === 401) {
        const refreshResponse = await api.post('/Accounts/Refresh', { refreshToken });

        // Dispatch an action to save the new tokens and wait for it to complete
        await dispatch(saveTokens({
          token: refreshResponse.data.accessToken,
          refreshToken: refreshResponse.data.refreshToken
        }));

        // Retry the original request
        const retryHeaders = {
          Authorization: `Bearer ${refreshResponse.data.accessToken}`
        };
        const retryResponse = await api.get(query, { headers: retryHeaders });
        return retryResponse.data;
      }

      return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            localStorage.removeItem('token');
            window.location.reload();
            return rejectWithValue('Refresh token not found');
          }
          try {
            const response = await api.post('/Accounts/Refresh', { refreshToken });
            localStorage.setItem('token', response.data.accessToken);
            if (response.data.refreshToken) {
              localStorage.setItem('refreshToken', response.data.refreshToken);
            }
            return response.data;
          } catch (error: any) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            window.location.reload();
            return rejectWithValue(error.response.data);
          }
        }
        return rejectWithValue(error.response.data);
      }
  }
);

export const saveTokens = createAsyncThunk<Tokens, Tokens>(
  'auth/saveTokens',
  async ({ token, refreshToken }: Tokens) => {
    return { token, refreshToken };
  }
);

interface UserUpdatePayload {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  profilePicture: string;
  
}



  // update user PUT /Accounts/{id} token required
  export const updateUser = createAsyncThunk(
    'users/updateUser',
    async ( payload: UserUpdatePayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
       
        const headers = {
          'Authorization': `Bearer ${token}`
        };
        const response = await api.put(`/Accounts/${payload.id}`, payload, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // Async thunk for creating a document for a user
  interface CreateDocumentPayload {
    userId: string;
    title: string;
    filePath: string;
    type: number;
  }

  export const createDocument = createAsyncThunk(
    'auth/createDocument',
    async (payload: CreateDocumentPayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/Users/${payload.userId}/Documents`, 
        {
          title: payload.title,
          filePath: payload.filePath,
          type: payload.type
        }
        , { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // Async thunk for fetching user documents

  interface FetchUserDocumentsPayload {
    userId: string;
}
 

export const fetchUserDocuments = createAsyncThunk(
  'auth/fetchUserDocuments',
  async (
     payload: FetchUserDocumentsPayload,
    { rejectWithValue, getState }
  ) => {
      try {
       
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        let query = `/Users/${payload.userId}/Documents?pageSize=1000`;
       
        const response = await api.get(query, { headers });
        
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // Delete user document DELETE /Users/{userId}/Documents/{documentId} token required

  interface DeleteUserDocumentPayload {
    userId: string;
    documentId: string;
  }

  export const deleteUserDocument = createAsyncThunk(
    'auth/deleteUserDocument',
    async ( payload: DeleteUserDocumentPayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        console.log('payload', payload);
        const headers = {
          'Authorization': `Bearer ${token}`
        };
        const response = await api.delete(`/Users/${payload.userId}/Documents/${payload.documentId}`, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  // export const deleteUserDocument = createAsyncThunk(
  //   'auth/deleteUserDocument',
  //   async (payload: DeleteUserDocumentPayload,  {thunkAPI, getState}) => {
  //     try {
  //       const token = (getState() as any).auth.token;
  //       const headers = {
  //                 'Authorization': `Bearer ${token}`
  //               };
  //       const response = await api.delete(`/Users/${payload.userId}/Documents/${payload.documentId}`, { headers });
  //       if (!response || !response.data) {
  //         return thunkAPI.rejectWithValue('No response data');
  //       }
  //       return response.data;
  //     } catch (error: any) {
  //       if (error.response && error.response.data) {
  //         return thunkAPI.rejectWithValue(error.response.data);
  //       } else {
  //         return thunkAPI.rejectWithValue('An error occurred');
  //       }
  //     }
  //   }
  // );


  
  // Async thunk for fetching a specific user by ID
  export const fetchUserById = createAsyncThunk(
    'auth/fetchUserById',
    async (userId: string, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/Accounts/${userId}`, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // Delete user DELETE /Accounts/{id} token required

  export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async ( id:string, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        console.log('id', id);
        const headers = {
          'Authorization': `Bearer ${token}`
        };
        const response = await api.delete(`/Accounts/${id}`, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );



interface Commissions {
  id: string;
  metadata: {
    [key: string]: string;
  };
}


  export const userInfo = createAsyncThunk(
    'users/userInfo',
    async ( payload: Commissions, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
       
        const headers = {
          'Authorization': `Bearer ${token}`
        };
        const response = await api.put(`/Users/${payload.id}`, payload, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );


  // update password /Accounts/Password and is requeries token
  interface PasswordModificationPayload {
    email: string;
    password: string;
  }


  export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (payload: PasswordModificationPayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json' // Make sure to set the content type as JSON
        };
         // This should match the expected API structure
        const response = await api.put('/Accounts/Password/Reset', payload, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const initialState = {
    user: null,
    self: null,
    users: [],
    userInfo: [],
    userDocuments: [],
    token: localStorage.getItem('token') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
    status: 'idle',
    error: null as ErrorType | null | any,
    userById: null,
    userSetting:  null,
    isAuthenticated: false,
  };



const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    //  remove cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    },
    setToken: (state, action) => {
      state.token = action.payload.accessToken;
      if (action.payload.refreshToken) {
        state.refreshToken = action.payload.refreshToken;
      }
      state.isAuthenticated = !!action.payload.accessToken;
    },
  },
  // ... rest of your slice code ...

extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.accessToken;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        // Assuming you want to store the error message if it exists
        state.error = action.payload ? { message: action.payload } : null;
      })
      .addCase(createUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful user creation here
        // e.g., you might want to add the new user to a list of users in your state
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful user update here
        
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchSelf.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSelf.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.self = action.payload; // Update the user object with fetched data
        state.isAuthenticated = true;
      })
      .addCase(fetchSelf.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(addRoleToUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addRoleToUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful addition of a role here
      })
      .addCase(addRoleToUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(removeRoleFromUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeRoleFromUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful removal of a role here
      })
      .addCase(removeRoleFromUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload; // Update the users array with fetched data
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.status = 'loading';
        state.userById = null; // Reset the userById object
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userById = action.payload; // Set the userById object with fetched data
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchUserSetting.pending, (state) => {
        state.status = 'loading';
       
      })
      .addCase(fetchUserSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userSetting = action.payload; // Set the userSetting object with fetched data
      })
      .addCase(fetchUserSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(createDocument.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userDocuments = action.payload; // Update the userDocument array with fetched data
        // Handle the successful document creation here
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchUserDocuments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDocuments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userDocuments = action.payload; // Update the userDocument array with fetched data
      })
      .addCase(fetchUserDocuments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(deleteUserDocument.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUserDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful document deletion here
      })
      .addCase(deleteUserDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful password update here
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful user deletion here
       state.userInfo = state.userInfo.filter((user: any) => user.id !== action.payload.id); 
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;

      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.accessToken;
        if (action.payload.refreshToken) {
          state.refreshToken = action.payload.refreshToken;
        }
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.error = action.payload ? { message: action.payload } : null;
      })
      .addCase(userInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the successful user commissions update here
      })
      .addCase(userInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      });

     

       
  },
  
});

export const { logoutUser, setToken } = authSlice.actions;
export default authSlice.reducer;
