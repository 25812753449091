import { useEffect } from 'react';
import CurrentUserProfile from '../components/CurrentUserProfile';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { fetchUserSetting } from '../slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function UserProfile() {
	const dispatch = useDispatch();

	const currentProfile = useSelector((state) => state.auth.self.id);

	useEffect(() => {
		dispatch(fetchUserSetting(currentProfile));
	}, [dispatch, currentProfile]);

	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<CurrentUserProfile currentProfile={currentProfile} />
			</div>
		</div>
	);
}
