import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import api from '../config/api'; 

interface Material {
    id: string;
    name: string;
    description: string;
    unitPrice: number;
    quantity: number;
  }

  interface Price {
    id: string;
    name: string;
    description: string;
    unitPrice: number;
    quantity: number;
  }
  
  interface LegPrice {
    id: string;
    name: string;
    description: string;
    unitPrice: number;
    quantity: number;
  }
  
  interface MaterialsState {
    items: Material[];
    price: Price[];
    legPrice: LegPrice[];
    material: Material | null;
    categories: any[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: ErrorType | null;
  }
  
  interface ErrorType {
    message: string; 
  }

interface materialsPayload {
            name: string,
            description: string,
            unitPrice: number,
            defaultPrice: number,
            measurementUnit: string,
            categoryId: number,
            width: number,
            height: number,
            length: number,
            prices: {
                OR: number,
                NV: number
            },
            metadata: {
                [key: string]: string;
                
            };
            quantity: number;
            stockLimit: number;
            active: boolean;
}

interface materialsUpdatePayload {
    id: number,
    name: string,
    description: string,
    unitPrice: number,
    defaultPrice: number,
    measurementUnit: string,
    categoryId: number,
    width: number,
    height: number,
    length: number,
    prices: {
        OR: number,
        NV: number
    },
    metadata: {
        [key: string]: string;
        
    };
    quantity: number;
    stockLimit: number;
    active: boolean;
    inventory: boolean;
}

interface FetchMaterialsArgs {
    pageSize: number;
    page: number;
    sortOrder: string;
    name?: string;
    description?: string;
    categoryId?: number;
    width?: number;
    height?: number;
    length?: number;
  }
  
  export const fetchMaterials = createAsyncThunk(
    'materials/fetchMaterials',
    async ({ pageSize, page = 1, sortOrder = 'DESC', name, description, categoryId, width, height, length }: FetchMaterialsArgs, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = { Authorization: `Bearer ${token}` };
        let query = `/Materials?pageSize=${pageSize}&page=${page}&sortOrder=${sortOrder}`;
        if (name) query += `&name=${name}`;
        if (description) query += `&description=${description}`;
        if (categoryId) query += `&categoryId=${categoryId}`;
        if (width) query += `&width=${width}`;
        if (height) query += `&height=${height}`;
        if (length) query += `&length=${length}`;
        const response = await api.get(query, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  interface FetchMaterialsPriceArgs {
    pageSize: number;
    page: number;
    sortOrder: string;
    name?: string;
    description?: string;
    categoryId?: number;
    width?: number;
    height?: number;
    length?: number;
  }


/////////////////////////////////////
//  ALAN                            // 
//  ORDERS API BELLOW              //
///////////////////////////////////

  export const fetchMaterialsPrice = createAsyncThunk(
    'materials/fetchMaterialsPrice',
    async ({ pageSize, page = 1, sortOrder = 'DESC', name, description, categoryId, width, height, length }: FetchMaterialsArgs, { rejectWithValue, getState }) => {
        try {
          const token = (getState() as any).auth.token;
          const headers = { Authorization: `Bearer ${token}` };
          let query = `/Materials?pageSize=${pageSize}&page=${page}&sortOrder=${sortOrder}`;
          if (name) query += `&name=${name}`;
          if (description) query += `&description=${description}`;
          if (categoryId) query += `&categoryId=${categoryId}`;
          if (width) query += `&width=${width}`;
          if (height) query += `&height=${height}`;
          if (length) query += `&length=${length}`;
          const response = await api.get(query, { headers });
          return response.data;
        } catch (error: any) {
          return rejectWithValue(error.response.data);
        }
      }
);

export const fetchLegsPrice = createAsyncThunk(
    'materials/fetchLegsPrice',
    async ({ categoryId, height, length, pageSize = 1000, page = 1, sortOrder = 'ASC' }: FetchMaterialsPriceArgs, { getState, rejectWithValue }) => {
        if (!categoryId || !height || !length) {
            return rejectWithValue('Missing parameters for fetching materials');
        }
        const token = (getState() as any).auth.token;
    
        if (!token) {
            return rejectWithValue('No authentication token available');
        }

        const headers = { Authorization: `Bearer ${token}` };
        let query = `/Materials?pageSize=${pageSize}&page=${page}&sortOrder=${sortOrder}&categoryId=${categoryId}&height=${height}&length=${length}`;

        try {
            const response = await api.get(query, { headers });
            return response.data;
        } catch (err: any) {
            if (err.response.status === 401) {
              const refreshToken = localStorage.getItem('refreshToken');
              if (!refreshToken) {
                return rejectWithValue('Refresh token not found');
              }
              try {
                const response = await api.post('/Accounts/Refresh', { refreshToken });
                localStorage.setItem('token', response.data.accessToken);
                if (response.data.refreshToken) {
                  localStorage.setItem('refreshToken', response.data.refreshToken);
                }
                // Retry the request with the new token
                const newHeaders = { Authorization: `Bearer ${response.data.accessToken}` };
                const retryResponse = await api.get(query, { headers: newHeaders });
                return retryResponse.data;
              } catch (error: any) {
                return rejectWithValue(error.response.data);
              }
            }
            return rejectWithValue(err.response.data);
          }
    }
);

  


export const fetchMaterialById = createAsyncThunk(
    'materials/fetchMaterialById',
    async (id, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as any).auth.token;
            const headers = {
                Authorization: `Bearer ${token}`
            };
            const response = await api.get(`/Materials/${id}`, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createMaterial = createAsyncThunk(
    'materials/createMaterial',
    async (materialData: materialsPayload, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as any).auth.token;
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await api.post('/Materials', materialData, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const updateMaterial = createAsyncThunk(
    'materials/updateMaterial',
    async (materialData: materialsUpdatePayload, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as any).auth.token;
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await api.put(`/Materials/${materialData.id}`, materialData, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

//  deleteMaterial
export const deleteMaterial = createAsyncThunk(
    'materials/deleteMaterial',
    async (id: string, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as any).auth.token;
            const headers = {
                Authorization: `Bearer ${token}`
            };
            const response = await api.delete(`/Materials/${id}`, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


//  Import and export in bulk 

export const exportMaterials = createAsyncThunk(
    'materials/exportMaterials',
    async (categoryId: { CategoryId: number }, { getState, rejectWithValue }) => {
        const token = (getState() as any).auth.token;
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await api.post('/Materials/Export', categoryId, { headers });
            return response.data; // Assuming the API returns a URL or blob for the file
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const importMaterials = createAsyncThunk(
    'materials/importMaterials',
    async (file: File, { getState, rejectWithValue }) => {
        const token = (getState() as any).auth.token;
        const headers = { Authorization: `Bearer ${token}` };
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await api.post('/Materials/Import', formData, { headers });
            return response.data; // Handle response accordingly
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Categories 


export const fetchCategories = createAsyncThunk(
    'materials/fetchCategories',
    async (_, { rejectWithValue, getState }) => {
        const token = (getState() as any).auth.token;
        const pageSize = 100;  
        const headers
            = { Authorization: `Bearer ${token}` };
        try {
            const response = await api.get(`/Categories?pageSize=${pageSize}`, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Create category


export const createCategory = createAsyncThunk(
    'materials/createCategory',
    async (categoryData: { name: string, metadata: any }, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as any).auth.token;
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await api.post('/Categories', categoryData, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Update category 


export const updateCategory = createAsyncThunk(
    'materials/updateCategory',
    async (categoryData: { id: number, name: string, metadata: any, active: boolean }, { rejectWithValue, getState }) => {
        try {
            const token = (getState() as any).auth.token;
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await api.put(`/Categories/${categoryData.id}`, categoryData, { headers });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
    


const initialState: MaterialsState = {
    items: [],
    price: [],
    legPrice: [],
    material: null,
    categories: [],
    status: 'idle',
    error: null,
  };



const materialsSlice = createSlice({
    name: 'materials',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMaterials.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMaterials.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload as Material[];
            })
            .addCase(fetchMaterials.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })          
            .addCase(fetchMaterialsPrice.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMaterialsPrice.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.price = action.payload;
            })
            .addCase(fetchLegsPrice.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.legPrice = action.payload;
            })
            .addCase(fetchMaterialsPrice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })          
            .addCase(fetchMaterialById.pending, (state) => {
                state.status = 'loading';
                state.material = null; // Clear the currently selected material
            })
            .addCase(fetchMaterialById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.material = action.payload; // Set the selected material
            })
            .addCase(fetchMaterialById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(createMaterial.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createMaterial.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // state.items.push(action.payload);
            })
            .addCase(createMaterial.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(updateMaterial.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateMaterial.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // state.items.push(action.payload);
            })
            .addCase(updateMaterial.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(deleteMaterial.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteMaterial.fulfilled, (state) => {
                state.status = 'succeeded';              
            })
            .addCase(deleteMaterial.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(exportMaterials.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(exportMaterials.fulfilled, (state) => {
                state.status = 'succeeded';              
            })
            .addCase(exportMaterials.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(importMaterials.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(importMaterials.fulfilled, (state) => {
                state.status = 'succeeded';              
            })
            .addCase(importMaterials.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            // .addCase(fetchMaterialsTotalCount.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(fetchMaterialsTotalCount.fulfilled, (state) => {
            //     state.status = 'succeeded';              
            // })
            // .addCase(fetchMaterialsTotalCount.rejected, (state, action) => {
            //     state.status = 'failed';
            //     state.error = action.error.message ? { message: action.error.message } : null;
            // })
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';              
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(createCategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCategory.fulfilled, (state) => {
                state.status = 'succeeded';              
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            })
            .addCase(updateCategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCategory.fulfilled, (state) => {
                state.status = 'succeeded';              
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message ? { message: action.error.message } : null;
            });

            
    }
});


export default materialsSlice.reducer;
