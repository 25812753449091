import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown, ChevronRight, X } from 'react-feather';
import {
	createMaterial,
	fetchMaterials,
	fetchCategories,
	createCategory,
} from '../../slices/materialsSlice';

export default function NewPricing({ onClose }) {
	const [sections, setSections] = useState({
		generalTab: true,
		measureTab: false,
		priceTab: false,
		statusTab: false,
	});

	const categories = useSelector(
		(state) => state?.materials?.categories?.items
	);
	const [openNewCategory, setOpenNewCategory] = useState(false);

	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	const {
		register: registerCat,
		handleSubmit: handleSubmitCat,
		reset: resetCat,
	} = useForm();

	const toggleNewCategory = () => {
		setOpenNewCategory(!openNewCategory);
		// close usen ESC key
		document.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				setOpenNewCategory(false);
			}
		});
	};

	const onSubmitCat = async (data) => {
		await dispatch(
			createCategory({
				name: data.categoryName,
				metadata: {}, // Assuming metadata structure or make it dynamic based on your needs
			})
		);
		dispatch(fetchCategories()); // Refetch categories to update the list
		setOpenNewCategory(false);
		resetCat(); // Reset category form
	};

	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		// setValue,
		formState: { errors },
	} = useForm();

	// const [val, setVal] = useState(true);
	const [selectedUnit, setSelectedUnit] = useState('ft');

	const currentPage = 1;
	const pageSize = 1000;

	const onSubmit = (data) => {
		const material = {
			name: data.name,
			description: data.description,
			unitPrice: Number(data.unitPrice),
			defaultPrice: Number(data.californiaPrice),
			measurementUnit: selectedUnit,
			categoryId: Number(data.categoryId),
			width: Number(data.width),
			height: Number(data.height),
			length: Number(data.length) || 0, // Assuming 0 if length is empty or not provided
			prices: {
				OR: Number(data.oregonPrice),
				NV: Number(data.nevadaPrice),
			},
			quantity: Number(data.quantity),
			stockLimit: data.stockLimit,
			active: Boolean('true'),
		};

		dispatch(createMaterial(material));
		dispatch(fetchMaterials({ pageSize, page: currentPage }));
		onClose();
	};

	useState(() => {
		dispatch(fetchCategories());
	}, [dispatch]);

	return (
		<div className="details-tab-panel">
			<aside className="new-details-panel">
				<div className="details-number">
					<h2 className="details-number-title">New item</h2>
					<div className="block-right">
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>
				{openNewCategory && (
					<div className="modal-form">
						<form
							onSubmit={handleSubmitCat(onSubmitCat)}
							className="form-container"
						>
							<input
								{...registerCat('categoryName', { required: true })}
								type="text"
								className="new-category-input"
								placeholder="New Category Name"
							/>
							{errors.categoryName && <p>{errors.categoryName.message}</p>}
							<button type="submit" className="saveBtn-modal">
								Save Category
							</button>
						</form>
						<div
							className="modal-backdrop"
							onClick={() => setOpenNewCategory(false)}
						></div>
					</div>
				)}
				<div
					className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
					id="modal"
				>
					<div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<div className="fixed inset-0 transition-opacity">
							<div className="absolute inset-0 bg-gray-900 opacity-75" />
						</div>
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen">
							&#8203;
						</span>
						<div
							className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
							role="dialog"
							aria-modal="true"
							aria-labelledby="modal-headline"
						>
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<label className="font-medium text-gray-800">Name</label>
								<input
									type="text"
									className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3"
								/>
								<label className="font-medium text-gray-800">Url</label>
								<input
									type="text"
									className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3"
								/>
							</div>
							<div className="bg-gray-200 px-4 py-3 text-right">
								<button
									type="button"
									className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
									onclick="toggleModal()"
								>
									<i className="fas fa-times"></i> Cancel
								</button>
								<button
									type="button"
									className="py-2 px-4 bg-blue-500 text-white rounded font-medium hover:bg-blue-700 mr-2 transition duration-500"
								>
									<i className="fas fa-plus"></i> Create
								</button>
							</div>
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className=" px-8 pb-8 mb-4">
					<div className="tab-information">
						<div
							className="title-block"
							onClick={() => toggleSection('generalTab')}
						>
							<h2 className="title-h2">General</h2>
							<strong>
								{sections.generalTab ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</strong>
						</div>
						{sections.generalTab && (
							<div className="content-block">
								<div className="field-section-full">
									<label className="block text-gray-700 text-sm font-bold mb-2">
										Name
									</label>
									<input
										{...register('name', { required: 'Name is required' })}
										className="reg-input"
									/>
									{errors.name && (
										<p className="text-redColor-500 text-s italic p-2">
											{errors.name.message}
										</p>
									)}
								</div>
								<hr className="mb-5" />

								<div className="field-section-full">
									<label className="block text-gray-700 text-sm font-bold mb-2">
										Description
									</label>
									<textarea
										{...register('description')}
										className="reg-textarea"
									/>
									{errors.description && (
										<p className="text-redColor-500 text-s italic p-2">
											{errors.description.message}
										</p>
									)}
								</div>

								<hr className="mb-5" />

								<div className="field-section-half">
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Unit
										</label>
										<select
											value={selectedUnit}
											onChange={(e) => setSelectedUnit(e.target.value)}
											className="hover:cursor-pointer text-gray-500 active:text-black hover:text-black selection:text-black"
										>
											<option value="ft">
												ft (Foot) - Area coverage in imperial system
											</option>
											<option value="sqft">
												Sqft (Square Feet) - Area coverage in imperial system
											</option>
											<option value="sqm">
												Sqm (Square Meters) - Area coverage in metric system
											</option>
											<option value="lf">
												Lf (Linear Feet) - Length measurement in feet
											</option>
											<option value="m">
												M (Meters) - Length measurement in metric system
											</option>
											<option value="in">
												In (Inches) - Small length measurement in imperial
												system
											</option>
											<option value="kg">
												Kg (Kilograms) - Weight measurement in metric system
											</option>
											<option value="lb">
												Lb (Pounds) - Weight measurement in imperial system
											</option>
											<option value="ton">
												Ton - Heavy weight measurement (imperial or metric tons)
											</option>
											<option value="gallon">
												Gallon - Liquid volume measurement in imperial system
											</option>
											<option value="liter">
												Liter - Liquid volume measurement in metric system
											</option>
											<option value="other">
												Other - For unspecified measurements
											</option>
										</select>

										{errors.measurementUnit && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.measurementUnit.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Category
										</label>

										<select {...register('categoryId', { required: true })}>
											{categories?.map((category) => (
												<option key={category.id} value={category.id}>
													{category.name}
												</option>
											))}
										</select>
										{errors.categoryId && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.categoryId.message}
											</p>
										)}
									</div>
								</div>

								<hr className="mb-5" />

								<div className="field-section-half">
									<button onClick={toggleNewCategory} className="redBtn">
										New Category
									</button>
								</div>

								<hr className="mb-5" />

								<div className="field-section-half">
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Quantity
										</label>
										<input
											type="number"
											{...register('quantity', {
												required: 'Quantity is required',
												valueAsNumber: true,
											})}
											className="reg-input !w-36"
										/>
										{errors.quantity && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.quantity.message}
											</p>
										)}
									</div>
								</div>
								<hr className="mb-5" />

								<div className="field-section-one-half ">
									<label className="block text-gray-700 text-sm font-bold mb-2">
										Color
									</label>
									<input
										type="text"
										{...register('color')}
										className="reg-input !w-52"
									/>
									{errors.color && (
										<p className="text-redColor-500 text-s italic p-2">
											{errors.color.message}
										</p>
									)}
								</div>
							</div>
						)}
					</div>
					<div className="tab-information">
						<div
							className="title-block"
							onClick={() => toggleSection('measureTab')}
						>
							<h2 className="title-h2">Measure</h2>
							<strong>
								{sections.measureTab ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</strong>
						</div>
						{sections.measureTab && (
							<div className="content-block">
								<div className="field-section-half">
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Width
										</label>
										<input
											{...register('width')}
											type="number"
											className="reg-input"
										/>
										{errors.width && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.width.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Length
										</label>
										<input
											{...register('length')}
											type="number"
											className="reg-input"
										/>
										{errors.length && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.length.message}
											</p>
										)}
									</div>
								</div>
								<hr className="mb-5" />
								<div className="field-section-one-half">
									<label className="block text-gray-700 text-sm font-bold mb-2">
										Height
									</label>

									<input
										{...register('height')}
										type="number"
										className="reg-input !w-36"
									/>

									{errors.height && (
										<p className="text-redColor-500 text-s italic p-2">
											{errors.height.message}
										</p>
									)}
								</div>
							</div>
						)}
					</div>

					<div className="tab-information">
						<div
							className="title-block"
							onClick={() => toggleSection('priceTab')}
						>
							<h2 className="title-h2">Prices</h2>
							<strong>
								{sections.priceTab ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</strong>
						</div>
						{sections.priceTab && (
							<div className="content-block">
								<div className="field-section-half">
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											California
										</label>
										<input
											type="number"
											step="0.01"
											{...register('californiaPrice')}
											className="reg-input"
										/>
										{errors.californiaPrice && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.californiaPrice.message}
											</p>
										)}
									</div>
								</div>

								<hr className="mb-5" />

								<div className="field-section-half">
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Oregon
										</label>
										<input
											type="number"
											step="0.01"
											{...register('oregonPrice')}
											className="reg-input"
										/>
										{errors.oregonPrice && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.oregonPrice.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Nevada
										</label>
										<input
											type="number"
											step="0.01"
											{...register('nevadaPrice')}
											className="reg-input"
										/>
										{errors.nevadaPrice && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.nevadaPrice.message}
											</p>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="w-full align-middle text-center">
						<button type="submit" className="saveBtn">
							Add New Item
						</button>
					</div>
				</form>
			</aside>
		</div>
	);
}
