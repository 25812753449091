import { useState, useEffect } from 'react';

export default function useCalculateTotalTime(attendanceRecords) {
	const [totalTime, setTotalTime] = useState('');

	useEffect(() => {
		const totalTimeToday = attendanceRecords?.reduce((total, item) => {
			if (
				item?.checkOutTime &&
				new Date(item.checkOutTime) > new Date(item.checkInTime)
			) {
				const checkIn = new Date(item.checkInTime);
				const checkOut = new Date(item.checkOutTime);
				const timeDifference = checkOut.getTime() - checkIn.getTime(); // Difference in milliseconds
				return total + timeDifference;
			}
			return total;
		}, 0); // Initial total time is 0 milliseconds

		// Convert total time in milliseconds to hours and minutes
		const hours = Math.floor(totalTimeToday / (1000 * 60 * 60));
		const minutes = Math.floor(
			(totalTimeToday % (1000 * 60 * 60)) / (1000 * 60)
		);

		// Set the total time as a formatted string
		setTotalTime(`${hours} hours and ${minutes} minutes`);
	}, [attendanceRecords]); // Re-run when attendanceRecords changes

	return totalTime;
}
