import { useEffect } from 'react';

export default function SimpleClock({ value, setValue }) {
	useEffect(() => {
		const interval = setInterval(() => setValue(new Date()), 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	const digitalValue = value.toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: true,
	});

	return <>{digitalValue}</>;
}
