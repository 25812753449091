import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, fetchOrders } from '../slices/ordersSlice';
import { fetchSelf, fetchUsers } from '../slices/userSlice';
import { updateClientNotes } from '../slices/clientSlice';
// notes
import axios from 'axios';
import usePopup from '../hooks/popuphook';
import { PlusCircle, X, Download } from 'react-feather';

import { fetchClients } from '../slices/clientSlice';

import {
	createClient,
	fetchClientById,
	fetchClientsOrder,
} from '../slices/clientSlice';
import { ChevronDown, ChevronRight, List } from 'react-feather';

import CreateNewClient from './orderSections/CreateNewClient';

export default function NewOrderTemp({ onClose, selectedClient }) {
	const dispatch = useDispatch();
	const clientObjectStorage = localStorage.getItem('clientId');
	const clientObject = JSON.parse(clientObjectStorage);
	const clientIdStorage = clientObject?.id;
	const [existingClient, setExistingClient] = useState(true);
	// const clients = useSelector((state) => state?.clients.items.items);
	const client = useSelector((state) => state?.clients.clientById);
	const user = useSelector((state) => state?.auth?.self?.id);
	const usersList = useSelector((state) => state.auth?.users?.items);
	const pageSize = 100;

	const getUserName = (userId) => {
		if (!userId) {
			return 'Unknown';
		}

		const user = usersList?.find(
			(user) => user?.id?.trim().toLowerCase() === userId.trim().toLowerCase()
		);

		return user ? user.firstName : 'Unknown';
	};

	// notes
	// popup
	const { isOpen, openPopup, closePopup } = usePopup();
	// const [editFrom, setEditForm] = useState(false);
	const api = process.env.REACT_APP_API_URL;
	const formRef = useRef();

	const currentUser = useSelector((state) => state.auth.self);

	// Sections state

	const [sections, setSections] = useState({
		client: false,
		address: false,
		installerTab: false,
		orderForm: false,
		styleTab: false,
		sizeTab: false,
		sidesTab: false,
		additionalsTab: false,
		doorsTab: false,
		colorsTab: false,
		warrantyTab: false,
		notesTab: false,
	});

	const toggleSection = (section, e) => {
		e.preventDefault(); // Prevent the default form submission
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	// Fetch clients and client by id

	useEffect(() => {
		dispatch(fetchClientsOrder());
		if (clientIdStorage || client?.firstName) {
			dispatch(fetchClientById(clientIdStorage || client.Id));
		}
		dispatch(fetchSelf());
	}, [dispatch]);

	// fetch user data and create a list only with role of INSTALLER
	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const installers = useSelector((state) =>
		state?.auth?.users?.items?.filter((user) => user.role === 'INSTALLER')
	);

	// ...

	// set a unique client id to select a different

	// Create a new client
	const createNewClient = (data) => {
		const newClient = {
			firstName: data.firstName,
			lastName: data.lastName,
			companyName: data.companyName,
			email: data.email,
			phoneNumber: data.phoneNumber,
			source: user,
			address: {
				address: data.address,
				city: data.city,
				state: data.state,
				postalCode: data.postalCode,
			},
			notes: [],
		};
		dispatch(createClient(newClient));
	};

	// Handle existing client

	const handleExistingClient = async (e) => {
		await dispatch(fetchClientById(e));
	};

	// Handle different client

	const handdelDifferentClient = () => {
		localStorage.removeItem('clientId');
		dispatch(fetchClientById(''));
	};

	//

	//  add more fileds to upload file
	const [fileInputs, setFileInputs] = useState([0]);
	// sending files
	const existingNotes = client?.notes || [];
	// here is the file url
	const [urlState, setUrlState] = useState([]);
	const [newNoteText, setNewNoteText] = useState('');
	// Uploading progress bar
	const [Msg, setMsg] = useState(null);
	const [progress, setProgress] = useState({ started: false, pc: 0, msg: '' });

	// pendding to clean up

	// clean functions

	// upload and remove inputs
	const addFileInput = () => {
		setFileInputs([...fileInputs, fileInputs.length]);
	};
	const removeFileInput = () => {
		if (fileInputs.length > 1) {
			const newFileInputs = fileInputs.slice(0, -1);
			setFileInputs(newFileInputs);
		}
	};

	// upload file
	const fileUploadHandler = async (file) => {
		const token = localStorage.getItem('token');

		// upload file by file and wait for the response to copy the url
		const formData = new FormData();
		if (file) {
			// Create a new File object with the same file data but a modified name
			const newFile = new File([file], file.name.replace(/\s/g, '_'), {
				type: file.type,
			});

			formData.append('file', newFile);

			setMsg('Uploading...');
			setProgress({ started: true, pc: 0, msg: 'Uploading...' });
		}

		try {
			const response = await axios.post(`${api}/files/upload`, formData, {
				onUploadProgress: (progressEvent) => {
					const pc = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setProgress({ started: true, pc, msg: 'Uploading...' });
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			// console.log('response', response.data.fileUrl);
			setUrlState([...urlState, response.data.fileUrl]);
			setMsg('File uploaded successfully!');
			setProgress({
				started: false,
				pc: 0,
				msg: 'File uploaded successfully ready to send or add more files!',
			});
		} catch (error) {
			setMsg('Error uploading file!');
		}
	};

	// Client's notes
	const onSubmitFile = async (data) => {
		// Check if newText is not empty
		const newNote = {
			userId: currentUser.id,
			createdAt: new Date().toISOString(),
			text: data.textNote,
			attachments: urlState.map((url) => ({
				description: 'Attachment',
				url: url,
			})),
		};

		// Add the new note to the existing notes
		const newNotes = [...existingNotes, newNote];

		// dispatch the action to update the notes
		await dispatch(
			updateClientNotes({
				id: client.id,
				notes: newNotes,
			})
		);
		// Clear the input fields
		dispatch(fetchClients(pageSize));
		setNewNoteText('');
		setUrlState([]);

		reset({
			textNote: '',
		});
		// Clear file inputs
		setFileInputs([0]);

		// Reset the form to clear the file input
		// Reset the form to clear the file input
		if (formRef.current) {
			formRef.current.reset();
		}

		dispatch(fetchClientById(client.id));
	};

	const onDeleteSubmit = (data) => {
		openPopup(data.id);
	};

	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			installationSurface: 'concrete',
			roofStyle: 'standard',
			roofPitchOptions: '3/12',
			centerStorageOptions: 'none',
			// width: 0,
			// length: 0,
			// height: 6,
			items: [
				{
					materialId: '389',
					description: '14 Gauge Base Only',
					quantity: '1',
					// unitPrice: '',
					// metadata: {},
				},
			],
		},
	});

	// call data from local storage to add to the order

	const onSubmit = (data) => {
		console.log(data?.installerId, 'data');
		const installerCommission =
			data.roofStyle === 'a-frame-vertical' ? true : false;

		const orderPayload = {
			clientId: client.id,
			installerId: data?.installerId, // replace with actual installerId
			salesmanId: user,
			isVerticalRoof: installerCommission,
			commission: 10.0, // adjust as needed
			transportationFee: 0.0, // adjust as needed
			drawingsAmount: 0.0, // adjust as needed
			equipmentAmount: 0.0, // adjust as needed
			discountAmount: 0.0, // adjust as needed
			installationAmount: 20.0, // adjust as needed
			reference: 0, // adjust as needed
			address: data.address,
			city: data.city,
			state: data.state,
			postalCode: data.postalCode,
			source: user,
			items: [],

			// items: [
			// 	...data.items.map((item) => ({
			// 		description: item.description,
			// 		materialId: parseInt(item.materialId, 10),
			// 		quantity: parseInt(item.quantity, 10),
			// 		unitPrice: parseFloat(item.unitPrice) || 0,
			// 	})),
			// 	...(lLWidth && lLLenght && lLHeight
			// 		? [
			// 				{
			// 					description: 'Left Lean-To',
			// 					materialId: lLWidth, // replace with actual materialId
			// 					quantity: lLLenght, // replace with actual quantity
			// 					unitPrice: lLHeight, // replace with actual unitPrice
			// 				},
			// 		  ]
			// 		: []),
			// 	...(rLWidth && rLLenght && rLHeight
			// 		? [
			// 				{
			// 					description: 'Right Lean-To',
			// 					materialId: rLWidth, // replace with actual materialId
			// 					quantity: rLLenght, // replace with actual quantity
			// 					unitPrice: rLHeight, // replace with actual unitPrice
			// 				},
			// 		  ]
			// 		: []),
			// ],
			metadata: {
				installerId: data?.installerId,
				installationSurface: data.installationSurface,
				roofStyle: data.roofStyle,
				roofPitchOptions: data.roofPitchOptions,
				centerStorageOptions: data.centerStorageOptions,
			},
		};

		dispatch(createOrder(orderPayload));
		dispatch(fetchClientsOrder(pageSize));
		dispatch(fetchOrders(pageSize));

		if (onClose) onClose();
	};

	// Preload address

	const [preloadedAddress, setPreloadedAddress] = useState({
		address: '',
		city: '',
		state: '',
		postalCode: '',
	});

	useEffect(() => {
		if (client) {
			setPreloadedAddress({
				address: client.address,
				city: client.city,
				state: client.state,
				postalCode: client.zipcode,
			});
		}
	}, [client]);

	return (
		<div className="new-order-page">
			<aside className="new-details-panel">
				<div className="details-number-quote">
					<h2 className="details-number-title">Create a New Order</h2>
					<div className="block-right">
						<button onClick={onClose} className="btn-tab-selection">
							Cancel quote
						</button>
					</div>
				</div>
				{client?.id ? (
					<>
						<div className="action-btns-container">
							<div className="action-btns">
								<button
									className="btn-tab-selection"
									onClick={() => handdelDifferentClient()}
								>
									Different Client <List size={24} />
								</button>
							</div>
						</div>
						<div className="tab-information">
							<div className="tab-information-block">
								<div className="user-information">
									<>
										<div
											className="title-block"
											onClick={(e) => toggleSection('client', e)}
										>
											<h2 className="title-h2">Client Information</h2>
											<button>
												{sections.client ? (
													<ChevronDown size={24} />
												) : (
													<ChevronRight size={24} />
												)}
											</button>
										</div>
										{sections.client && (
											<>
												<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
													<div className="data-content">
														<p>
															<strong>First Name</strong> {client?.firstName}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Last Name</strong> {client?.lastName}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Email</strong> {client?.email}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Phone</strong>{' '}
															{client?.phoneNumber
																? client?.phoneNumber
																: 'N/A'}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Street</strong> {client?.address}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>City</strong> {client?.city}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>State</strong> {client?.state}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>Zipcode</strong> {client?.postalCode}
														</p>
													</div>
													<div className="data-content">
														<p>
															<strong>User Created </strong>{' '}
															{new Date(client.createdAt).toLocaleString(
																'en-US',
																{
																	month: '2-digit',
																	day: '2-digit',
																	year: '2-digit',
																	hour: '2-digit',
																	minute: '2-digit',
																}
															)}
														</p>
													</div>
												</div>
											</>
										)}
									</>
								</div>
							</div>
						</div>
					</>
				) : (
					<CreateNewClient
						createNewClient={createNewClient}
						setExistingClient={setExistingClient}
						handleExistingClient={handleExistingClient}
						clientIdStorage={clientIdStorage}
						existingClient={existingClient}
						sections={sections}
						toggleSection={toggleSection}
						setSections={setSections}
					/>
				)}

				{client?.firstName && (
					<>
						<form onSubmit={handleSubmit(onSubmit)}>
							{/* Installation addres input */}
							<div className="tab-information">
								<div className="tab-information-block">
									<div
										className="title-block"
										onClick={(e) => toggleSection('address', e)}
									>
										<h2 className="title-h2">Installation Address</h2>

										<button>
											{sections.address ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.address && (
										<>
											<div className="new-order-form">
												<p className="instructions-p">
													Please enter the address where the building will be
													installed.
												</p>

												<div className="field-section-half">
													<div className="inp-col">
														<label htmlFor="address">
															<strong>Address</strong>
														</label>
														<input
															{...register('address', {
																required: 'Address is required',
															})}
															value={preloadedAddress.address}
														/>
														{errors.address && (
															<p className="text-redColor-500 text-s italic p-2">
																{errors?.address.message}
															</p>
														)}
													</div>
													<div className="inp-col">
														<label htmlFor="city">
															<strong>City</strong>
														</label>
														<input
															{...register('city', {
																required: 'City is required',
															})}
															value={preloadedAddress.city}
														/>
														{errors.city && (
															<p className="text-redColor-500 text-s italic p-2">
																{errors?.city.message}
															</p>
														)}
													</div>
													<div className="inp-col">
														<label htmlFor="state">
															<strong>State</strong>
														</label>
														<input
															{...register('state', {
																required: 'State is required',
															})}
															value={preloadedAddress.state}
														/>
														{errors.state && (
															<p className="text-redColor-500 text-s italic p-2">
																{errors?.state.message}
															</p>
														)}
													</div>
													<div className="inp-col">
														<label htmlFor="postalCode">
															<strong>Postal Code</strong>
														</label>
														<input
															{...register('postalCode', {
																required: 'Postal Code is required',
															})}
															value={preloadedAddress.postalCode}
														/>
														{errors.postalCode && (
															<p className="text-redColor-500 text-s italic p-2">
																{errors?.postalCode.message}
															</p>
														)}
													</div>
												</div>
											</div>
											<button
												onClick={() =>
													setPreloadedAddress({
														address: '',
														city: '',
														state: '',
														postalCode: '',
													})
												}
												className="btn-tab-selection"
											>
												Different Address
											</button>
										</>
									)}
								</div>
							</div>
							<div className="tab-information">
								<div className="tab-information-block">
									<div
										className="title-block"
										onClick={(e) => toggleSection('installerTab', e)}
									>
										<h2 className="title-h2">Installer</h2>
										<button>
											{sections.installerTab ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.installerTab && (
										<>
											<div className="new-order-form">
												<div className="field-section-full">
													<div className="inp-col">
														<label htmlFor="installerId">
															<strong>Assign an installer</strong>
														</label>
														<select {...register('installerId')}>
															{installers?.map((installer) => (
																<option key={installer.id} value={installer.id}>
																	{installer?.firstName} {installer?.lastName}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</div>

							{/* Price sumary */}

							<button type="submit" className="redBtn mr-4">
								Submit Order
							</button>
						</form>
						{/* Notes */}
						<div className="tab-information notes-special-block">
							<div className="tab-information-block">
								<div
									className="title-block"
									onClick={(e) => toggleSection('notesTab', e)}
								>
									<h2 className="title-h2">
										This notes will be saved in the {client?.firstName}{' '}
										{client?.lastName} client's profile.
									</h2>
									<button>
										{sections.notesTab ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.notesTab && (
									<div className="content-block ">
										{/* upload multiple files */}
										<div className="field-section-full formSubmitFile ">
											<form ref={formRef}>
												<textarea
													placeholder="Add new note"
													className="reg-textarea"
													{...register('textNote', { required: true })}
												/>
												{errors.textNote && (
													<span className="error">This field is required</span>
												)}
												{fileInputs.map((index, i) => (
													<input
														key={`${index}-${i}`}
														type="file"
														name="file"
														className="reg-input"
														onChange={(e) => {
															if (e.target.files.length > 0) {
																fileUploadHandler(e.target.files[0]);
															}
														}}
													/>
												))}
												<div className="field-section-full">
													<button
														type="button"
														onClick={removeFileInput}
														className="delete-file-input"
													>
														<X size={20} className="ml-2 text-redColor-500 " />{' '}
													</button>
													<button type="button" onClick={addFileInput}>
														<PlusCircle
															size={20}
															className="ml-2 text-redColor-500"
														/>
													</button>
												</div>
												{Msg && (
													<strong className="text-greenColor-500 w-full block font-light">
														{Msg}
													</strong>
												)}
												{progress.started && (
													<div className="progress-bar">
														<div
															className="progress-bar-fill"
															style={{ width: `${progress.pc}%` }}
														></div>
														<span>{progress.msg}</span>
													</div>
												)}
												<button
													type="submit"
													className="btn-save"
													onClick={handleSubmit(onSubmitFile)}
												>
													Save Note
												</button>
											</form>
										</div>
										<div className="content-block">
											{client?.notes &&
												[...client.notes].reverse().map((note, index) => (
													<div key={index} className="notesList">
														<span className="notes-item-title">
															<strong>Date:</strong>{' '}
															{new Date(note?.createdAt).toLocaleString(
																'en-US',
																{
																	month: '2-digit',
																	day: '2-digit',
																	year: '2-digit',
																	hour: '2-digit',
																	minute: '2-digit',
																}
															)}
														</span>
														<p className="notes-item-title">
															{note && note?.userId && (
																<strong>Account manager:</strong>
															)}
															{note &&
																note?.userId &&
																getUserName(note?.userId)}
														</p>
														<span className="whitespace-normal"></span>
														<div className="notes-item-value">
															<strong>Comments:</strong>
															<br />
															{note?.text}
														</div>
														<div className="field-section-full download-array">
															{note?.attachments?.length > 0 ? (
																<strong className="w-full">
																	Download attachments
																</strong>
															) : (
																''
															)}
															{note?.attachments &&
																note.attachments.map((file, index) => (
																	<div
																		key={index}
																		className="notes-item-attachment"
																	>
																		<a
																			href="#"
																			onClick={(e) => {
																				e.preventDefault();
																				window.open(
																					file.url,
																					'_blank',
																					'noopener,noreferrer'
																				);
																			}}
																			className="btn-download-file"
																		>
																			<Download size={12} />
																		</a>
																	</div>
																))}
														</div>
													</div>
												))}
										</div>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</aside>
		</div>
	);
}
