import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createClient, fetchClients } from '../../slices/clientSlice';
import { fetchSelf } from '../../slices/userSlice';
import { ChevronDown, ChevronRight, X } from 'react-feather';

export default function NewClient({ onClose, closeSlide }) {
	const dispatch = useDispatch();
	const [phoneNumber, setPhoneNumber] = useState('');

	const [sections, setSections] = useState({
		client: false,
	});
	const self = useSelector((state) => state.auth.self.id);

	const error = useSelector((state) => state.clients.error);

	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const watchedPhoneNumber = watch('phoneNumber');

	useEffect(() => {
		dispatch(fetchSelf());
	}, [dispatch]);

	useEffect(() => {
		const formattedPhoneNumber = formatPhoneNumber(watchedPhoneNumber);
		setPhoneNumber(formattedPhoneNumber);
	}, [watchedPhoneNumber]);

	const formatPhoneNumber = (input) => {
		const cleaned = ('' + input).replace(/\D/g, '');
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			const intlCode = match[1] ? '+1 ' : '';
			return intlCode + '(' + match[2] + ') ' + match[3] + '-' + match[4];
		}
		return input;
	};

	const createNewClient = (data) => {
		const newClient = {
			firstName: data.firstName,
			lastName: data.lastName,
			companyName: data.companyName,
			email: data.email,
			phoneNumber: data.phoneNumber,
			source: self,
			address: {
				address: data.address,
				city: data.city,
				state: data.state,
				postalCode: data.postalCode,
			},
			notes: [],
		};
		dispatch(createClient(newClient));
		dispatch(fetchClients());
		onClose();
	};

	const closeNewClient = () => {
		closeSlide();
		localStorage.removeItem('clientId');
	};

	return (
		<div className="new-user-details-panel">
			<aside className="details-panel">
				<div className="details-number">
					<h1>Create a New Client</h1>
					<div className="block-right">
						<button onClick={closeNewClient} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>
				<div className="tab-information">
					<div className="tab-information-block">
						<div
							className="title-block"
							onClick={() => toggleSection('client')}
						>
							<h2 className="title-h2">Client Information</h2>
							<button>
								{sections.client ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.client && (
							<>
								<form onSubmit={handleSubmit(createNewClient)}>
									<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
										<div className="mb-4 p-2">
											<input
												{...register('firstName')}
												placeholder="First Name"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.firstName && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.firstName.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												{...register('lastName')}
												placeholder="Last Name"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.lastName && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.lastName.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												{...register('companyName')}
												placeholder="Company Name"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.companyName && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.companyName.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												{...register('email')}
												placeholder="Email"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.email && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.email.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												placeholder="Phone Number"
												value={phoneNumber}
												onChange={(e) =>
													setValue('phoneNumber', e.target.value)
												}
												{...register('phoneNumber', {
													// pattern: {
													// 	value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
													// 	message:
													// 		'Phone number must be in the format 123-456-7890',
													// },
												})}
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.phoneNumber && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.phoneNumber.message}
												</p>
											)}
										</div>

										<div className="mb-4 p-2">
											<input
												{...register('address')}
												placeholder="Address"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.address && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.address.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												{...register('city')}
												placeholder="City"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.city && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.city.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												{...register('state')}
												placeholder="State"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.state && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.state.message}
												</p>
											)}
										</div>
										<div className="mb-4 p-2">
											<input
												{...register('postalCode')}
												placeholder="Postal Code"
												className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.postalCode && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.postalCode.message}
												</p>
											)}
										</div>
									</div>
									<div className="w-full ">
										<button type="submit" className="redBtn">
											Create Client
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			</aside>
		</div>
	);
}
