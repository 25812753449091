import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import store from './config/store'; // Adjust import path as needed
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom';
import './main.scss';


const container = document.getElementById('app'); 


if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
    <Provider store={store}>
     <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element');
}