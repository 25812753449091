const ProgressBar = ({ currentStatus }) => {
	const statusCodes = {
		Quote: 1,
		'Order set': 2,
		'Installation permits': 3,
		Invoice: 4,
		Installation: 5,
		Payment: 6,
	};

	const statuses = [
		'Quote',
		'Order set',
		'Installation permits',
		'Invoice',
		'Installation',
		'Payment',
	];

	return (
		<div className="progress-bar-container">
			{statuses.map((status) => {
				const isActive = statusCodes[status] <= currentStatus;

				return (
					<div
						key={status}
						className={`progress-bar-item ${isActive ? 'active' : 'inactive'}`}
					>
						<div
							className={`circle-indicator ${isActive ? 'active' : 'inactive'}`}
						>
							<div className="status-label">{status}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ProgressBar;
