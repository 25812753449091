import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import api from '../config/api'; 

interface ErrorType {
    message: string; 
  }

interface clientsPayload {
    id: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email: string;
    phoneNumber?: string;
    address: {
      address?: string;
      city?: string;
      state?: string;
      postalCode?: string;
    };
    status: number;
    source?: string;
    active: boolean;
    // array with notes objects
    notes?: string;

}

interface fetchClientsPayload {
  pageSize? : number;
  page?: number;
  sortOrder?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  phone?: number;
  status?: number;
  source?: string;
  
}


// Create fetchClients it's loading full and the option to filter by name, lastName, phone and status
export const fetchClients = createAsyncThunk(
    'clients/fetchClients',
    async (
        { pageSize, page, sortOrder, firstName, lastName, companyName, phone, status, source }: fetchClientsPayload = {},
        { rejectWithValue, getState }
    ) => {
        try {
            let token = (getState() as any).auth.token || localStorage.getItem('token');
            let headers = { Authorization: `Bearer ${token}` };
            let query = `/Clients?`;
           
            if (pageSize) query += `pageSize=${pageSize}`;
            if (page) query += `&page=${page}`;
            // if (sortOrder) query += `&sortOrder=${sortOrder}`;
            if (firstName) query += `&firstName=${encodeURIComponent(firstName)}`;
            if (lastName) query += `&lastName=${encodeURIComponent(lastName)}`;
            if (companyName) query += `&companyName=${encodeURIComponent(companyName)}`;
            if (phone) query += `&phone=${phone}`;
            if (status) query += `&status=${status}`;
            if (source) query += `&source=${source}`;

            // sort by firstName 
          
            // query += `&sort=firstName`;


            try {
                const response = await api.get(query, { headers });
                return response.data;
            } catch (error: any) {
                if (error.response.status === 401) {
                    const refreshToken = localStorage.getItem('refreshToken');
                    if (!refreshToken) {
                        return rejectWithValue('Refresh token not found');
                    }
                    const response = await api.post('/Accounts/Refresh', { refreshToken });
                    localStorage.setItem('token', response.data.accessToken);
                    if (response.data.refreshToken) {
                        localStorage.setItem('refreshToken', response.data.refreshToken);
                    }
                    // Retry the API call to /Clients with the new token
                    token = response.data.accessToken;
                    headers = { Authorization: `Bearer ${token}` };
                    const retryResponse = await api.get(query, { headers });
                    return retryResponse.data;
                }
                return rejectWithValue(error.response.data);
            }
            
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);





export const fetchClientsOrder = createAsyncThunk(
  'clients/fetchClientsOrder',
  async (_, { rejectWithValue, getState }) => {
      try {
          const token = (getState() as any).auth.token;
          const headers = { Authorization: `Bearer ${token}` };
          let query = `/Clients?pageSize=1000`;
          
          const response = await api.get(query, { headers });
          return response.data;
      } catch (err: any) {
        if (err.response.status === 401) {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            return rejectWithValue('Refresh token not found');
          }
          try {
            const response = await api.post('/Accounts/Refresh', { refreshToken });
            localStorage.setItem('token', response.data.accessToken);
            if (response.data.refreshToken) {
              localStorage.setItem('refreshToken', response.data.refreshToken);
            }
            return response.data;
          } catch (error: any) {
            return rejectWithValue(error.response.data);
          }
        }
                 

          return rejectWithValue(err.response.data);
      }
  }
);


export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { getState, rejectWithValue }) => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken || refreshToken === 'undefined') {
      return rejectWithValue('Refresh token not found');
    }
    try {
      const response = await api.post('/Accounts/Refresh', { refreshToken });
      localStorage.setItem('token', response.data.accessToken);
      if (response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

  // create refresh token



  export const fetchClientById = createAsyncThunk(
    'clients/fetchClientById',
    async (id, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;

        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/Clients/${id}`, { headers });
        return response.data;
      } catch (error: any) {
        // if 401 error, get refresh token POST /Accounts/Refresh with refreshToken from local storage
       if (error.response.status === 401) {
         const refreshToken = localStorage.getItem('refreshToken');
         if (!refreshToken) {
           return rejectWithValue('Refresh token not found');
         }
         try {
           const response = await api.post('/Accounts/Refresh', { refreshToken });
           localStorage.setItem('token', response.data.accessToken);
           if (response.data.refreshToken) {
             localStorage.setItem('refreshToken', response.data.refreshToken);
           }
           return response.data;
         } catch (error: any) {
           return rejectWithValue(error.response.data);
         }

       }
       return rejectWithValue(error.response.data);

     }
    }
  );

  export const createClient = createAsyncThunk(
    'clients/createClient',
    async (clientData: clientsPayload, { rejectWithValue, getState }) => {
      try {
        // Fetch the token from the current state
        const token = (getState() as any).auth.token;
  
        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}`
        };
  
        const response = await api.post('/Clients', clientData, { headers });
                 
          localStorage.setItem('clientId', JSON.stringify( response.data));
          
          return response.data;
    
        
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const updateClient = createAsyncThunk(
    'clients/updateClient',
    async (clientData: clientsPayload, { rejectWithValue }) => {
      try {
     
        // Fetch the new token from local storage
        const token = localStorage.getItem('token');

        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}`
        };
        
        // Call the update client API
        const updateResponse = await api.put(`/Clients/${clientData.id}`, {
          firstName: clientData.firstName,
          lastName: clientData.lastName,
          companyName: clientData.companyName,
          email: clientData.email,
          phoneNumber: clientData.phoneNumber,
          address: {
            address: clientData.address.address,
            city: clientData.address.city,
            state: clientData.address.state,
            postalCode: clientData.address.postalCode,
          },
          source: clientData.source,
          status: clientData.status,
        
        }, { headers });
       

        return updateResponse.data;
      } catch (error: any) {
        console.error("Error during client update:", error);
        return rejectWithValue(error.response?.data || error.message || "Unknown error");
    }
    }
);

/*  PUT /Clients/{id}/Notes

*Requires authorization

PUT /Clients/{id}/Notes

Request

{
"Notes":[
    {
        "createdAt": "2024-04-30T10:00:18.255Z",
        "text": "test",
        "attachments": [{"description": "test description", "url": "testfile"}],
        "file": "afile.pdf"
    },
    {
        "createdAt": "2024-04-30T16:44:46.798Z",
        "text": "test2",
        "attachments": null,
        "file": null
    },
    {
        "createdAt": "2024-05-05T16:44:46.798Z",
        "text": "test4",
        "attachments": null,
        "file": null
    },
    {
        "createdAt": "2024-05-05T22:12:31.04Z",
        "text": "",
        "attachments": null,
        "file": null
    },
    {
        "createdAt": "2024-05-05T22:34:19.065Z",
        "text": "",
        "attachments": null,
        "file": null
    }
]
}

*/

interface NotesPayload {
  
  id: string;
  notes: { userId: string; createdAt: string; text: string; attachments: { description: string; url: string }[]; file: string }[]
}

export const updateClientNotes = createAsyncThunk(
  'clients/updateClientNotes',
  async (notesData: NotesPayload, { rejectWithValue, getState }) => {
    try {
      // Fetch the token from the current state
      const token = (getState() as any).auth.token;

      // Set up headers for authentication
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await api.put(`/Clients/${notesData.id}/Notes`, notesData, { headers });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);




  export const deleteClient = createAsyncThunk(
    'clients/deleteClient',
    async (id: string, { rejectWithValue, getState }) => {
      try {
        // Fetch the token from the current state
        const token = (getState() as any).auth.token;
  
        // Set up headers for authentication
        const headers = {
          Authorization: `Bearer ${token}` 
        };

        const response = await api.delete(`/Clients/${id}`, { headers });
        return response.data;
        }
         catch (error: any) {
        return rejectWithValue(error.response.data);
        }
    }
    );

   

const clientSlice = createSlice({
    name: 'clients',
    initialState: {
      items: [],
      notes: [],
      status: 'idle',
      error: null as ErrorType | null,
      clientById: null,
      token: localStorage.getItem('token') || null,
      refreshToken: localStorage.getItem('refreshToken') || null,
      isAuthenticated: false,
    },
    reducers: {
      setToken: (state, action) => {
        state.token = action.payload.accessToken;
        if (action.payload.refreshToken) {
          state.refreshToken = action.payload.refreshToken;
        }
        state.isAuthenticated = !!action.payload.accessToken;
      },
    },

    extraReducers: (builder) => {
        builder
          .addCase(fetchClients.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchClients.fulfilled, (state, action) => {
            state.status = 'succeeded';
            
            state.items = action.payload;
          })
          .addCase(fetchClients.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ? { message: action.error.message } : null;
          })
          .addCase(fetchClientsOrder.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchClientsOrder.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.items = action.payload;
          })
          .addCase(fetchClientsOrder.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ? { message: action.error.message } : null;
          })
          .addCase(fetchClientById.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchClientById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.clientById = action.payload;
          })
          .addCase(fetchClientById.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ? { message: action.error.message } : null;
          })
          .addCase(createClient.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(createClient.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.items = action.payload;
          })
          .addCase(createClient.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ? { message: action.error.message } : null;
          })
          .addCase(updateClient.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(updateClient.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.items = action.payload;
          })
          .addCase(updateClient.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ? { message: action.error.message } : null;
          })
          .addCase(deleteClient.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(deleteClient.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.items = action.payload;
          })
          .addCase(deleteClient.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message ? { message: action.error.message } : null;
            })
          .addCase(refreshToken.fulfilled, (state, action) => {
              state.token = action.payload.accessToken;
              if (action.payload.refreshToken) {
                state.refreshToken = action.payload.refreshToken;
              }
            })
          .addCase(refreshToken.rejected, (state, action) => {
              state.error = action.error.message ? { message: action.error.message } : null;
            })
          .addCase(updateClientNotes.pending, (state) => {
              state.status = 'loading';
            })
          .addCase(updateClientNotes.fulfilled, (state, action) => {
              state.status = 'succeeded';
              state.notes = action.payload;
            })
          .addCase(updateClientNotes.rejected, (state, action) => {
              state.status = 'failed';
              state.error = action.error.message ? { message: action.error.message } : null;
            })
            
          
        }
    }
);


export default clientSlice.reducer;

