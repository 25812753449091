import { useEffect, useState } from 'react';
import {
	Sliders,
	PlusSquare,
	File,
	ArrowLeftCircle,
	ArrowRightCircle,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients, fetchClientById } from '../slices/clientSlice';
import ClientDetails from '../components/clients/ClientDetails';

import NewClient from '../components/clients/NewClient';
import { formatPhoneNumber, removePhoneFormatting } from '../hooks/phoneFormat';
import { fetchSelf, fetchUsers } from '../slices/userSlice';
import { useNavigate } from 'react-router-dom';

export default function ClientsContent() {
	const dispatch = useDispatch();
	const clients = useSelector((state) => state.clients.items.items);
	const clientById = useSelector((state) => state.clients.clientById);
	const [selectedClient, setSelectedClient] = useState(null);
	const [newClientForm, setNewClientForm] = useState(false);
	const [newCLientDescription, setNewClientDescription] = useState(null);
	const usersList = useSelector((state) => state.auth?.users?.items);
	// Search bar
	const [filterFirstName, setFilterFirstName] = useState('');
	const [filterLastName, setFilterLastName] = useState('');
	const [filterCompanyName, setFilterCompanyName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [filterStatus, setFilterStatus] = useState('');
	const [filterSource, setFilterSource] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	// Toggle sections
	const [filterSection, setFilterSections] = useState({
		filterOptions: true,
	});
	const [pageSize, setPageSize] = useState(100);

	const clientIdString = localStorage.getItem('clientId');
	const clientId = JSON.parse(clientIdString)?.id;

	// Checking if the user is logged in
	// const user = useSelector((state) => state.user);
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchUsers({ pageSize: 100 }));
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			fetchClients({
				pageSize,
				page: currentPage,
			}),
			fetchClientById()
		);
	}, [dispatch]);

	useEffect(() => {
		if (clientId !== null) {
			setSelectedClient(clientId);
			dispatch(fetchClientById(clientId));
		}
	}, [clientId]);

	const openClient = (clientId) => {
		setSelectedClient(clientId);
		dispatch(fetchClientById(clientId));
	};

	const closeDetails = () => {
		setNewClientForm(null);
		// remove localStorage item clientId
		setNewClientDescription(clientId);
		setSelectedClient(clientId);
		dispatch(fetchClientById(clientId));
	};

	const closeSlide = () => {
		setNewClientForm(false);
		setNewClientForm(null);
		setSelectedClient(null);
		localStorage.removeItem('clientId');
	};

	// user list

	const accountManager = usersList
		?.filter((user) => !user?.email.includes('_deactivate_'))
		.map((user) => ({
			// label: user.firstName + ' ' + user.lastName,
			value: user.id,
			label: user.firstName + ' ' + user.lastName,
		}));

	// Search filters

	const applyFilters = () => {
		const newPhone = removePhoneFormatting(filterPhone);
		dispatch(
			fetchClients({
				pageSize: pageSize,
				page: currentPage,
				sortOrder: 'ASC',
				firstName: filterFirstName,
				lastName: filterLastName,
				source: filterSource,
				companyName: filterCompanyName,
				phone: newPhone,
				status: filterStatus,
			})
		);
		console.log('filterSource', filterSource);
	};

	const resetFilters = () => {
		setFilterFirstName('');
		setFilterLastName('');
		setFilterCompanyName('');
		setFilterPhone('');
		setFilterStatus('');
		setFilterSource('');

		applyFilters(); // Fetch all materials without filters
		dispatch(fetchClients());
	};

	// Toggle sections

	const toggleSection = (filterSection) => {
		setFilterSections((prevSections) => ({
			...prevSections,
			[filterSection]: !prevSections[filterSection],
		}));
	};

	const optionStatus = [
		{ id: 1, name: 'New' },
		{ id: 2, name: 'Contacted' },
		{ id: 3, name: 'Confirmed' },
	];

	// Pagination Handlers
	const handleNextPage = () => {
		const newPage = currentPage + 1;
		setCurrentPage(newPage);

		// Dispatch the fetchClients action with the new page number
		dispatch(fetchClients({ page: newPage, pageSize }));
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			const newPage = currentPage - 1;
			setCurrentPage(newPage);

			// Dispatch the fetchClients action with the new page number
			dispatch(fetchClients({ page: newPage, pageSize }));
		}
	};

	const handlePageSizeChange = (event) => {
		const newPageSize = parseInt(event.target.value, 10);
		setPageSize(newPageSize);
		setCurrentPage(1); // Reset to first page with new page size

		// Dispatch the fetchClients action with the new page size
		dispatch(fetchClients({ pageSize: newPageSize }));
	};
	const handleNewClient = async () => {
		const actionResult = await dispatch(fetchSelf());
		if (fetchSelf.fulfilled.match(actionResult)) {
			setNewClientForm(true);
		} else {
			navigate('/');
		}
	};

	return (
		<main className="flex-1 overflow-auto p-8">
			<div className="searchbar">
				<div className="filters">
					<button onClick={() => toggleSection('filterOptions')}>
						All filters <Sliders size={24} />
					</button>
				</div>
			</div>
			{/* Filters options going here */}
			{filterSection.filterOptions && (
				<div className="filters-options mt-10">
					<input
						type="text"
						placeholder="Filter by name..."
						value={filterFirstName}
						onChange={(e) => setFilterFirstName(e.target.value)}
					/>
					<input
						type="text"
						placeholder="Filter by last name..."
						value={filterLastName}
						onChange={(e) => setFilterLastName(e.target.value)}
					/>
					<input
						type="text"
						placeholder="Filter by company name..."
						value={filterCompanyName}
						onChange={(e) => setFilterCompanyName(e.target.value)}
					/>

					{/* <input
								type="number"
								placeholder="Phone"
								value={filterPhone}
								onChange={(e) => setFilterPhone(e.target.value)}
							/> */}
					<select
						value={filterStatus}
						onChange={(e) => setFilterStatus(e.target.value)}
					>
						<option value="">Select a Status</option>
						{optionStatus.map((status) => (
							<option key={status.id} value={status.id}>
								{status.name}
							</option>
						))}
					</select>
					<select
						value={filterSource}
						onChange={(e) => setFilterSource(e.target.value)}
					>
						<option value="">Select an Account Manager</option>
						{accountManager?.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>

					<button onClick={applyFilters} className="filter-btn">
						Search
					</button>
					<button onClick={resetFilters} className="filter-btn">
						Reset Inputs
					</button>
				</div>
			)}
			<div className="dashboard-content">
				<h2 className="text-2xl font-semibold text-gray-800">
					Clients managment
				</h2>
				<div className="pagination-controls">
					<div className="prevNext">
						<button onClick={handlePreviousPage} disabled={currentPage === 1}>
							<ArrowLeftCircle size={24} /> Prev
						</button>
						<strong>{currentPage}</strong>
						<button onClick={handleNextPage}>
							Next <ArrowRightCircle size={24} />
						</button>
					</div>
					<div className="page-size-select">
						<label htmlFor="pageSize">Items per page: </label>
						<select value={pageSize} onChange={handlePageSizeChange}>
							<option value="10">10</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="500">500</option>
							<option value="1000">1000</option>
						</select>
					</div>
				</div>

				<div className="action-btns">
					<button
						className="btn-new-user"
						// onClick={() => setNewClientForm(true)}
						onClick={handleNewClient}
					>
						Create a new client <PlusSquare size={24} />
					</button>
				</div>
				<table className="min-w-full">
					<thead>
						<tr>
							<th>Full Name / Company</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{clients?.map((client) => (
							<tr
								key={client?.id}
								className="select-by-name"
								onClick={() => openClient(client?.id)}
							>
								<td className="!text-left">
									{client?.firstName ? client?.firstName : ''}{' '}
									{client?.lastName ? client?.lastName : ''} <br />
									{client?.companyName ? (
										<strong>{client?.companyName}</strong>
									) : (
										''
									)}
								</td>
								<td>{client?.email}</td>
								<td>{formatPhoneNumber(client?.phoneNumber)}</td>
								<td>
									{client?.status === 1
										? 'New'
										: client?.status === 2
										? 'Contacted'
										: 'Confirmed'}
								</td>
								<td>{client?.clientEmail}</td>
								<td>
									<button
										onClick={() => openClient(client?.id)}
										// className="btn-details"
									>
										<File size={24} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{selectedClient && ( // Adjust based on your store setup
				<ClientDetails
					selectedClient={selectedClient}
					clientDetails={clientById}
					onClose={closeDetails}
					closeSlide={closeSlide}
					newCLientDescription={clientId}
				/>
			)}

			{newClientForm && (
				<NewClient
					onClose={closeDetails}
					closeSlide={closeSlide}
					selectedClient={selectedClient}
					clientDetails={clientById}
				/>
			)}
		</main>
	);
}
