import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchSelf, loginUser } from '../slices/userSlice';
import logo from '../assets/Best_National_Carports-logo-blk.svg';
import { AlertCircle } from 'react-feather';
import { refreshToken } from '../slices/userSlice';

const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authStatus = useSelector((state) => state.auth.status);
	const errorMessage = useSelector(
		(state) => state.auth?.error?.message?.Message
	);
	const token = useSelector((state) => state.auth.token);
	const localToken = localStorage.getItem('token');

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		await dispatch(loginUser(data));
		await dispatch(fetchSelf());
	};

	useEffect(() => {
		const handleAuthentication = async () => {
			if (token || localToken) {
				navigate('/dashboard');
			} else if (!token && authStatus === 'error' && !localToken) {
				// Attempt to refresh token on login error
				try {
					const action = await dispatch(refreshToken());
					if (refreshToken.fulfilled.match(action)) {
						// Token refreshed successfully, navigate to the orders page
						navigate('/dashboard');
					} else {
						// Refresh token failed, navigate to the homepage
						navigate('/');
					}
				} catch (error) {
					console.error('Error refreshing token:', error);
					navigate('/');
				}
			}
		};

		handleAuthentication();
	}, [token, localToken, authStatus, navigate, dispatch]);

	return (
		<div className="w-full p-5 flex min-h-screen justify-center items-center flex-col login-page">
			<div className="logo-login inline-flex">
				<img src={logo} alt="BNC logo" />
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="w-80 ">
				<div className="bg-gray-100 login-form">
					<h1 className="text-2xl font-bold mb-2">Login</h1>
					<input
						{...register('email', { required: 'Your email is required' })}
						placeholder="email"
						className="inline-block w-full p-2 my-2 border border-gray-300 rounded-md"
					/>
					<span className="text-red-500 text-xs italic">
						{errors.email && (
							<span className="form-error">
								<AlertCircle size={20} />
								{errors.email.message}
							</span>
						)}
					</span>
					<input
						{...register('password', { required: 'Your password is rquired' })}
						type="password"
						placeholder="password"
						className="inline-block w-full p-2 my-2 border border-gray-300 rounded-md"
					/>
					<span className="text-red-500 text-xs italic inline-block w-full mb-4">
						{errors.password && (
							<span className="form-error">
								<AlertCircle size={20} /> {errors.password.message}
							</span>
						)}
					</span>
					{/* Display error message from the api */}
				</div>
				{errorMessage && (
					<span className="text-red-500 text-base italic inline-block w-full mb-4 form-error">
						<AlertCircle size={20} /> {errorMessage}
					</span>
				)}
				<div className="align-btn">
					<button type="submit" className="btn-login">
						Login
					</button>
				</div>
			</form>
		</div>
	);
};

export default LoginForm;
