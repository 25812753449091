import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import {
	fetchAttendance,
	createAttendance,
	updateAttendance,
	searchAttendance,
} from '../slices/attendanceSlice';
import { fetchSelf } from '../slices/userSlice';

import { useForm } from 'react-hook-form';
import {
	Circle,
	Coffee,
	Edit2,
	Play,
	ChevronDown,
	ChevronRight,
} from 'react-feather';
import SimpleClock from '../components/SimpleClock';

import useCalculateTotalTime from '../hooks/useCalculateTotalTime';

export default function Clockin() {
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const { register, handleSubmit, reset } = useForm();
	const attendanceData = useSelector((state) => state?.attendance?.items.items);
	const searResults = useSelector(
		(state) => state?.attendance?.searchItems?.items
	);
	const selftAccount = useSelector((state) => state?.auth?.self);
	const selftAccountId = selftAccount?.id;
	const selftAccountIdString = String(selftAccount?.id);
	const lastEntryWithNullCheckOut = attendanceData
		?.filter(
			(item) => item?.userId.toLowerCase() === selftAccountId?.toLowerCase()
		) // Ensure only user's data
		?.reverse()
		.find((item) => item?.checkOutTime === null && item?.isBreak === false);
	// braek check
	const lastEntryWithNullCheckOutBreak = attendanceData
		?.filter(
			(item) => item?.userId.toLowerCase() === selftAccountId?.toLowerCase()
		) // Ensure only user's data
		?.reverse()
		.find((item) => item?.checkOutTime === null && item?.isBreak === true);

	const [value, setValue] = useState(new Date());
	const [editClockActive, setEditClockActive] = useState(false);
	const [currentEditingItem, setCurrentEditingItem] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	// useEffect(() => {
	// 	const interval = setInterval(() => setValue(new Date()), 1000);
	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, []);

	// const test = attendanceData?.filter((item) =>
	// 	selftAccount?.role === 'SUPER' ? true : selftAccountId === item?.userId
	// );

	// const userAttendanceRecords = attendanceData?.filter((item) => {
	// 	console.log(item.userId); // Check the value of item.userId
	// 	return item?.userId === selftAccountIdString;
	// });

	const userAttendanceRecords = attendanceData?.filter((item) => {
		return item?.userId.toLowerCase() === selftAccountIdString.toLowerCase();
	});

	useEffect(() => {
		// if this compone is loaded fetch self and fetch attendance after 15 min of inactivity

		dispatch(fetchSelf());
		if (token) {
			dispatch(fetchAttendance());
		}
	}, [dispatch, token]);

	const [sections, setSections] = useState({
		todayInOut: false,
		todayBreakInOut: false,
		teamRecords: false,
	});

	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	// const formatDateToISO = (timeString) => {
	// 	// Fixed date part (YYYY-MM-DD)
	// 	const fixedDate = new Date().toISOString().slice(0, 10);

	// 	// Convert 12-hour format to 24-hour format
	// 	const time24hr = new Date(`2022-01-01 ${timeString}`).toLocaleTimeString(
	// 		'en-US',
	// 		{
	// 			hour: '2-digit',
	// 			minute: '2-digit',
	// 			second: '2-digit',
	// 			hour12: false,
	// 		}
	// 	);

	// 	// Combine the fixed date with the 24-hour format time and convert to ISO string
	// 	return new Date(`${fixedDate} ${time24hr}`).toISOString();
	// };

	// const isoFormattedTime = formatDateToISO(digitalValue);

	const onClockIn = (e) => {
		e.preventDefault();
		const checkInDateTime = new Date().toISOString();
		// : null;

		dispatch(
			createAttendance({
				UserId: selftAccount.id,
				Date: checkInDateTime,
				CheckInTime: checkInDateTime,
				CheckOutTime: null,
				IsBreak: false,
			})
		)
			.then(() => {
				dispatch(fetchAttendance());
			})
			.catch((error) => {
				console.error('Error updating attendance:', error);
			});

		reset();
	};

	const onClockInBreak = (e) => {
		e.preventDefault();
		const checkInDateTime = new Date().toISOString();
		// : null;

		dispatch(
			createAttendance({
				UserId: selftAccount.id,
				Date: checkInDateTime,
				CheckInTime: checkInDateTime,
				CheckOutTime: null,
				IsBreak: true,
			})
		)
			.then(() => {
				dispatch(fetchAttendance());
			})
			.catch((error) => {
				console.error('Error updating attendance:', error);
			});

		reset();
	};

	const onClockOut = (e) => {
		e.preventDefault();
		// Convert local time to UTC and format
		const checkOutTimeUTC = new Date().toISOString();

		dispatch(
			updateAttendance({
				id: lastEntryWithNullCheckOut.id,
				date: lastEntryWithNullCheckOut.date,
				CheckInTime: lastEntryWithNullCheckOut.checkInTime,
				CheckOutTime: checkOutTimeUTC,
				IsBreak: false,
			})
		)
			.then(() => {
				dispatch(fetchAttendance());
				// setCurrentEditingItem(null);
			})
			.catch((error) => {
				console.error('Error updating attendance:', error);
			});
	};

	const onClockOutBreak = (e) => {
		e.preventDefault();
		// Convert local time to UTC and format
		const checkOutTimeUTC = new Date().toISOString();

		dispatch(
			updateAttendance({
				id: lastEntryWithNullCheckOutBreak.id,
				date: lastEntryWithNullCheckOutBreak.date,
				CheckInTime: lastEntryWithNullCheckOutBreak.checkInTime,
				CheckOutTime: checkOutTimeUTC,
				IsBreak: true,
			})
		)
			.then(() => {
				dispatch(fetchAttendance());
				// setCurrentEditingItem(null);
			})
			.catch((error) => {
				console.error('Error updating attendance:', error);
			});
	};

	const editClock = (data, e) => {
		e.preventDefault();
		// Convert local time to UTC and format
		const checkInTimeUTC = new Date(data.CheckInTime)
			? new Date(data.CheckInTime).toISOString()
			: null;
		const checkOutTimeUTC = data.CheckOutTime
			? new Date(data.CheckOutTime).toISOString()
			: null;
		const date = new Date(data.date).toISOString();

		dispatch(
			updateAttendance({
				id: data.id,
				date: date,
				CheckInTime: checkInTimeUTC,
				CheckOutTime: checkOutTimeUTC,
			})
		)
			.then(() => {
				dispatch(fetchAttendance());
				setEditClockActive(false);
			})
			.catch((error) => {
				console.error('Error updating attendance:', error);
			});
	};

	// searchAttendance userId: string;
	// startDate: string;
	// endDate: string;

	const searchAttendanceByDate = (data) => {
		const startDate = new Date(data.startDate).toISOString();
		const endDate = new Date(data.endDate).toISOString();

		dispatch(
			searchAttendance({
				userId: selftAccountId,
				startDate: startDate,
				endDate: endDate,
			})
		)
			.then(() => {
				dispatch(fetchAttendance());
			})
			.catch((error) => {
				console.error('Error searching attendance:', error);
			});
	};

	// Filter attendance records for today
	// const todayAttendance = attendanceData?.filter(
	// 	(item) =>
	// 		new Date(item?.date).toLocaleDateString() ===
	// 		new Date().toLocaleDateString()
	// );

	// useEffect(() => {
	// 	// Calculate total time for today's attendance records
	// 	const totalTimeToday = todayAttendance?.reduce((total, item) => {
	// 		if (item?.checkOutTime) {
	// 			const checkIn = new Date(item.checkInTime);
	// 			const checkOut = new Date(item.checkOutTime);
	// 			const timeDifference = checkOut.getTime() - checkIn.getTime(); // Difference in milliseconds
	// 			return total + timeDifference;
	// 		}
	// 		return total;
	// 	}, 0); // Initial total time is 0 milliseconds

	// 	// Convert total time in milliseconds to hours and minutes
	// 	const hours = Math.floor(totalTimeToday / (1000 * 60 * 60));
	// 	const minutes = Math.floor(
	// 		(totalTimeToday % (1000 * 60 * 60)) / (1000 * 60)
	// 	);

	// 	// Set the total time using setTotalTime
	// 	setTotalTime(`${hours} hours and ${minutes} minutes`);
	// }, [todayAttendance]); // Dependency array, re-run this effect when todayAttendance changes

	// Calculate total time worked today isBerake === false

	const todayAttendance = attendanceData?.filter((item) => {
		return (
			new Date(item.date).toLocaleDateString() ===
			new Date().toLocaleDateString()
		);
	});

	const todayAtandanceWithOutBreak = todayAttendance?.filter(
		(item) => item?.userId.toLowerCase() === selftAccountId?.toLowerCase()
	);

	const todayAttendanceTotal = todayAtandanceWithOutBreak?.filter((item) => {
		return item?.isBreak === false;
	});
	const todayBreak = todayAtandanceWithOutBreak?.filter((item) => {
		return item?.isBreak === true;
	});

	// const todayAttendance = attendanceData?.filter((item) => {
	// 	return (
	// 		new Date(item.date).toLocaleDateString() ===
	// 		new Date().toLocaleDateString()
	// 	);
	// });

	const totalTime = useCalculateTotalTime(todayAttendanceTotal);
	const totalBreakTime = useCalculateTotalTime(todayBreak);

	return (
		<div className="flex flex-col h-screen clockin-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8" id="mobile-display">
					<div className="clockin-content text-center">
						<h1 className="title-time">
							<strong>Attendance Records</strong>{' '}
							<SimpleClock value={value} setValue={setValue} />
						</h1>

						<div className="today-bar">
							{/* total check in and check out in minutes and hours */}
							<div className="total-time inline-flex mr-10">
								<h2 className="text-base font-bold m-1">
									Time worked today:
									<strong className="text-xs font-extralight ml-3">
										{totalTime ? totalTime : 'Dispay after clock out'}
									</strong>
								</h2>

								<h2 className="text-base font-bold m-1 ml-6">
									Breaks today:
									<strong className="text-xs font-extralight ml-3">
										{totalBreakTime
											? totalBreakTime
											: 'Display after breaks ends'}
									</strong>
								</h2>
							</div>
							{!lastEntryWithNullCheckOutBreak && (
								<div className="btns-clockin">
									{lastEntryWithNullCheckOut === undefined && (
										<form
											onSubmit={(e) => handleSubmit(onClockIn(e))}
											className=""
										>
											<input
												{...register('CheckInTime', { required: true })}
												defaultValue={new Date().toISOString().slice(0, 16)}
												className="border border-gray-300 rounded px-2 py-1 hidden"
											/>
											<button type="submit" className="btn-clockin">
												Clock In <Play size={16} />
											</button>
										</form>
									)}
								</div>
							)}

							{/* clockout */}
							<div className="btns-clockin">
								{lastEntryWithNullCheckOutBreak && (
									<form
										onSubmit={(e) => handleSubmit(onClockOutBreak(e))}
										className=""
									>
										<input
											type="hidden"
											{...register('id')}
											defaultValue={selftAccountId}
										/>
										<input
											type="hidden"
											{...register('date')}
											defaultValue={lastEntryWithNullCheckOutBreak?.date}
										/>
										<input
											type="hidden"
											{...register('checkInTime')}
											defaultValue={lastEntryWithNullCheckOutBreak?.checkInTime}
										/>
										<input
											type="hidden"
											{...register('CheckOutTime', { required: true })}
											defaultValue={new Date().toISOString().slice(0, 16)}
											className="border border-gray-300 rounded px-2 py-1"
										/>

										<button type="submit" className="btn-clockout">
											Stop Break <Circle size={16} />
										</button>
									</form>
								)}
								{!lastEntryWithNullCheckOutBreak && (
									<>
										{lastEntryWithNullCheckOutBreak === undefined && (
											<>
												{lastEntryWithNullCheckOut && (
													<form
														onSubmit={(e) => handleSubmit(onClockInBreak(e))}
														className=""
													>
														<input
															{...register('CheckInTime', { required: true })}
															defaultValue={new Date()
																.toISOString()
																.slice(0, 16)}
															className="border border-gray-300 rounded px-2 py-1 hidden"
														/>
														<button type="submit" className="btn-clockin">
															Break time <Coffee size={16} />
														</button>
													</form>
												)}
											</>
										)}
										{lastEntryWithNullCheckOut && (
											<form
												onSubmit={(e) => handleSubmit(onClockOut(e))}
												className=""
											>
												<input
													type="hidden"
													{...register('id')}
													defaultValue={selftAccountId}
												/>
												<input
													type="hidden"
													{...register('date')}
													defaultValue={lastEntryWithNullCheckOut?.date}
												/>
												<input
													type="hidden"
													{...register('checkInTime')}
													defaultValue={lastEntryWithNullCheckOut?.checkInTime}
												/>
												<input
													type="hidden"
													{...register('CheckOutTime', { required: true })}
													defaultValue={new Date().toISOString().slice(0, 16)}
													className="border border-gray-300 rounded px-2 py-1"
												/>
												<button type="submit" className="btn-clockout">
													Clock Out <Circle size={16} />
												</button>
											</form>
										)}{' '}
									</>
								)}
							</div>
							{/* table with columns  date, check-in, check-out, breaks, lunch edit */}

							<table className="min-w-full border-collapse block md:table table-records ">
								<thead className="block md:table-header-group">
									<tr className="border border-gray-300 md:table-row">
										<th className="block md:table-cell">Date</th>
										<th className="block md:table-cell">Check In</th>
										<th className="block md:table-cell">Check Out</th>
										<th className="block md:table-cell">Breaks start</th>
										<th className="block md:table-cell">Breaks End</th>
										<th className="block md:table-cell">Total time</th>
									</tr>
								</thead>
								<tbody className="block md:table-row-group">
									{userAttendanceRecords &&
										userAttendanceRecords
											.filter((item) => {
												return (
													new Date(item.date).toLocaleDateString() ===
													new Date().toLocaleDateString()
												);
											})
											?.map((item) => (
												<tr key={item?.id} className="border  md:table-row">
													<td className="block md:table-cell">
														{new Date(item?.date).toLocaleDateString()}
													</td>

													<td className="block md:table-cell">
														{item?.isBreak === false &&
															new Date(item?.checkInTime).toLocaleTimeString()}
													</td>
													<td className="block md:table-cell">
														{item?.isBreak === false && (
															<>
																{item?.checkOutTime &&
																	new Date(
																		item?.checkOutTime
																	).toLocaleTimeString()}{' '}
															</>
														)}
													</td>
													<td className="block md:table-cell">
														{item?.isBreak === true &&
															new Date(item?.checkInTime).toLocaleTimeString()}
													</td>
													<td className="block md:table-cell">
														{item?.isBreak === true && (
															<>
																{item?.checkOutTime &&
																	new Date(
																		item?.checkOutTime
																	).toLocaleTimeString()}{' '}
															</>
														)}
													</td>
													<td className="block md:table-cell">
														{item?.checkOutTime && (
															<>
																{Math.floor(
																	Math.abs(
																		new Date(item?.checkOutTime).getTime() -
																			new Date(item?.checkInTime).getTime()
																	) / 3600000
																)}
																h:
																{Math.floor(
																	(Math.abs(
																		new Date(item?.checkOutTime).getTime() -
																			new Date(item?.checkInTime).getTime()
																	) %
																		3600000) /
																		60000
																)}
																m:
																{Math.floor(
																	(Math.abs(
																		new Date(item?.checkOutTime).getTime() -
																			new Date(item?.checkInTime).getTime()
																	) %
																		60000) /
																		1000
																)}
																s
															</>
														)}
													</td>
												</tr>
											))}
								</tbody>
							</table>
						</div>

						{/* Search attendance by date */}
						{/* <div className="w-full content-center object-center">
							<h2 className="text-xl font-bold mb-4">
								Search attendance by date
							</h2>
						</div>
						<form
							onSubmit={handleSubmit(searchAttendanceByDate)}
							className="flex gap-4 items-center justify-center mt-10 mb-10"
						>
							<input
								type="date"
								{...register('startDate', { required: true })}
								className="border border-gray-300 rounded px-2 py-1"
							/>
							<input
								type="date"
								{...register('endDate', { required: true })}
								className="border border-gray-300 rounded px-2 py-1"
							/>
							<button
								type="submit"
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center"
							>
								Search
							</button>
						</form> */}
						{/* display search if results searResults */}
						{/* display results if searchResults id is = to selftAccountd */}
						{searResults && (
							<div className="attendance-list">
								<table className="min-w-full border-collapse block md:table">
									<thead className="block md:table-header-group">
										<tr className="border border-gray-300 md:table-row">
											<th className="block md:table-cell">Date</th>
											<th className="block md:table-cell">Check In</th>
											<th className="block md:table-cell">Check Out</th>
											<th className="block md:table-cell">
												Time passed / Minutes
											</th>
											{selftAccount?.role === 'SUPER' && (
												<th className="block md:table-cell">Edit</th>
											)}
										</tr>
									</thead>
									<tbody className="block md:table-row-group">
										{searResults?.map((item) => (
											<tr key={item?.id} className="border  md:table-row">
												<td className="block md:table-cell">
													{new Date(item?.date).toLocaleDateString()}
												</td>

												<td className="block md:table-cell">
													{new Date(item?.checkInTime).toLocaleTimeString()}
												</td>
												<td className="block md:table-cell">
													{item?.checkOutTime
														? new Date(item?.checkOutTime).toLocaleTimeString()
														: ''}
												</td>
												<td className="block md:table-cell">
													{item?.checkOutTime &&
														(
															Math.floor(
																Math.abs(
																	new Date(item?.checkOutTime).getTime() -
																		new Date(item?.checkInTime).getTime()
																) / 3600000
															) +
															Math.floor(
																(Math.abs(
																	new Date(item?.checkOutTime).getTime() -
																		new Date(item?.checkInTime).getTime()
																) %
																	3600000) /
																	60000
															) /
																60
														).toFixed(2)}
												</td>
												{selftAccount?.role === 'SUPER' && (
													<td className="block md:table-cell">
														<button
															className=" text-black font-bold py-2 px-4 rounded"
															onClick={() => {
																reset();

																setCurrentEditingItem(item);
																setEditClockActive(true);
															}}
														>
															<Edit2 size={16} />
														</button>
													</td>
												)}
											</tr>
										))}
										{/* total hours works in the search */}
										<tr className="border  md:table-row">
											<td className="block md:table-cell">
												Total hours worked
											</td>
											<td className="block md:table-cell"></td>
											<td className="block md:table-cell"></td>
											<td className="block md:table-cell">
												{searResults
													?.reduce((acc, item) => {
														if (item.checkOutTime) {
															acc +=
																Math.abs(
																	new Date(item.checkOutTime).getTime() -
																		new Date(item.checkInTime).getTime()
																) / 3600000;
														}
														return acc;
													}, 0)
													.toFixed(2)}
											</td>
											{selftAccount?.role === 'SUPER' && (
												<td className="block md:table-cell"></td>
											)}
										</tr>
									</tbody>
								</table>
							</div>
						)}

						{/* Calculate the total hours worked in the same date */}

						{/* 	{editClockActive && currentEditingItem && (
							<div className="edit-clock">
								{selftAccount?.role === 'SUPER' && (
									<form
										onSubmit={(e) => handleSubmit(editClock, e)}
										className="flex gap-4"
									>
										<input
											type="hidden"
											{...register('id')}
											defaultValue={currentEditingItem.id}
										/>
										<input
											type="hidden"
											{...register('date')}
											defaultValue={new Date(currentEditingItem.date)
												.toISOString()
												.slice(0, 16)}
										/>

										<input
											type="datetime-local"
											{...register('CheckInTime', { required: true })}
											defaultValue={new Date(currentEditingItem.checkInTime)
												.toISOString()
												.slice(0, 16)}
											className="border border-gray-300 rounded px-2 py-1"
										/>
										<input
											type="datetime-local"
											{...register('CheckOutTime')}
											defaultValue={
												currentEditingItem.checkOutTime
													? new Date(currentEditingItem.checkOutTime)
															.toISOString()
															.slice(0, 16)
													: ''
											}
											className="border border-gray-300 rounded px-2 py-1"
										/>
										<button
											type="submit"
											className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
										>
											Update
										</button>
									</form>
								)}
							</div>
						)} */}
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('todayInOut')}
								>
									<h2 className="title-h2">Your attendance records</h2>
									<button>
										{sections.todayInOut ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.todayInOut && (
									<table className="min-w-full border-collapse block md:table">
										<thead className="block md:table-header-group">
											<tr className="border border-gray-300 md:table-row">
												<th className="block md:table-cell">Date</th>
												<th className="block md:table-cell">Check In</th>
												<th className="block md:table-cell">Check Out</th>
												<th className="block md:table-cell">
													Time passed / Minutes
												</th>
												{selftAccount?.role === 'SUPER' && (
													<th className="block md:table-cell">Edit</th>
												)}
											</tr>
										</thead>
										<tbody className="block md:table-row-group">
											{/* filter if isBreake === false and if the item.date is the same */}
											{userAttendanceRecords
												?.filter((item) => item?.isBreak === false)
												.filter((item) => {
													return (
														new Date(item.date).toLocaleDateString() ===
														new Date().toLocaleDateString()
													);
												})
												?.map((item) => (
													<tr key={item?.id} className="border  md:table-row">
														<td className="block md:table-cell">
															{new Date(item?.date).toLocaleDateString()}
														</td>

														<td className="block md:table-cell">
															{new Date(item?.checkInTime).toLocaleTimeString()}
														</td>
														<td className="block md:table-cell">
															{item?.checkOutTime
																? new Date(
																		item?.checkOutTime
																  ).toLocaleTimeString()
																: ''}
														</td>
														<td className="block md:table-cell">
															{item?.checkOutTime && (
																<>
																	{Math.floor(
																		Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) / 3600000
																	)}
																	h:
																	{Math.floor(
																		(Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) %
																			3600000) /
																			60000
																	)}
																	m:
																	{Math.floor(
																		(Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) %
																			60000) /
																			1000
																	)}
																	s
																</>
															)}
														</td>
														{selftAccount?.role === 'SUPER' && (
															<td className="block md:table-cell">
																<button
																	className=" text-black font-bold py-2 px-4 rounded"
																	onClick={() => {
																		reset();

																		setCurrentEditingItem(item);
																		setEditClockActive(true);
																	}}
																>
																	<Edit2 size={16} />
																</button>
															</td>
														)}
													</tr>
												))}
										</tbody>
									</table>
								)}
							</div>
						</div>

						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('todayBreakInOut')}
								>
									<h2 className="title-h2">Your break records</h2>
									<button>
										{sections.todayBreakInOut ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.todayBreakInOut && (
									<table className="min-w-full border-collapse block md:table">
										<thead className="block md:table-header-group">
											<tr className="border border-gray-300 md:table-row">
												<th className="block md:table-cell">Date</th>
												<th className="block md:table-cell">Check In</th>
												<th className="block md:table-cell">Check Out</th>
												<th className="block md:table-cell">
													Time passed / Minutes
												</th>
												{selftAccount?.role === 'SUPER' && (
													<th className="block md:table-cell">Edit</th>
												)}
											</tr>
										</thead>
										<tbody className="block md:table-row-group">
											{userAttendanceRecords
												?.filter((item) => item?.isBreak === true)
												.filter((item) => {
													return (
														new Date(item.date).toLocaleDateString() ===
														new Date().toLocaleDateString()
													);
												})
												?.map((item) => (
													<tr key={item?.id} className="border  md:table-row">
														<td className="block md:table-cell">
															{new Date(item?.date).toLocaleDateString()}
														</td>

														<td className="block md:table-cell">
															{new Date(item?.checkInTime).toLocaleTimeString()}
														</td>
														<td className="block md:table-cell">
															{item?.checkOutTime
																? new Date(
																		item?.checkOutTime
																  ).toLocaleTimeString()
																: ''}
														</td>
														<td className="block md:table-cell">
															{item?.checkOutTime && (
																<>
																	{Math.floor(
																		Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) / 3600000
																	)}
																	h:
																	{Math.floor(
																		(Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) %
																			3600000) /
																			60000
																	)}
																	m:
																	{Math.floor(
																		(Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) %
																			60000) /
																			1000
																	)}
																	s
																</>
															)}
														</td>
														{selftAccount?.role === 'SUPER' && (
															<td className="block md:table-cell">
																<button
																	className=" text-black font-bold py-2 px-4 rounded"
																	onClick={() => {
																		reset();

																		setCurrentEditingItem(item);
																		setEditClockActive(true);
																	}}
																>
																	<Edit2 size={16} />
																</button>
															</td>
														)}
													</tr>
												))}
										</tbody>
									</table>
								)}
							</div>
						</div>

						{selftAccount?.role === 'SUPER' && (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('teamRecords')}
									>
										<h2 className="title-h2">Team time records</h2>
										<button>
											{sections.teamRecords ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.teamRecords && (
										<table className="min-w-full border-collapse block md:table">
											<thead className="block md:table-header-group">
												<tr className="border border-gray-300 md:table-row">
													<th className="block md:table-cell">Date</th>
													<th className="block md:table-cell">Username</th>
													<th className="block md:table-cell">Check In</th>
													<th className="block md:table-cell">Check Out</th>
													<th className="block md:table-cell">Break Yes/No</th>
													<th className="block md:table-cell">
														Time passed
														<br />
														Minutes
													</th>
													<th className="block md:table-cell">Edit</th>
												</tr>
											</thead>
											<tbody className="block md:table-row-group">
												{attendanceData?.map((item) => (
													<tr
														key={item?.id}
														className="border border-gray-300 md:table-row"
													>
														<td className="block md:table-cell">
															{new Date(item?.date).toLocaleDateString()}
														</td>
														<td className="block md:table-cell">
															{item?.userName}
														</td>
														<td className="block md:table-cell">
															{new Date(item?.checkInTime).toLocaleTimeString()}
														</td>
														<td className="block md:table-cell">
															{item?.checkOutTime
																? new Date(
																		item?.checkOutTime
																  ).toLocaleTimeString()
																: ''}
														</td>
														<td className="block md:table-cell">
															{item?.isBreak ? 'Yes' : 'No'}
														</td>
														<td className="block md:table-cell">
															{/* sum the time worked to display in hours and minutes exp 1h:20m */}
															{item?.checkOutTime && (
																<>
																	{Math.floor(
																		Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) / 3600000
																	)}
																	h:
																	{Math.floor(
																		(Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) %
																			3600000) /
																			60000
																	)}
																	m:
																	{Math.floor(
																		(Math.abs(
																			new Date(item?.checkOutTime).getTime() -
																				new Date(item?.checkInTime).getTime()
																		) %
																			60000) /
																			1000
																	)}
																	s
																</>
															)}
														</td>

														<td className="block md:table-cell">
															<button
																className=" text-black font-bold py-2 px-4 rounded"
																onClick={() => {
																	reset();

																	setCurrentEditingItem(item);
																	setEditClockActive(true);
																}}
															>
																<Edit2 size={16} />
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									)}
								</div>
							</div>
						)}
					</div>
				</main>
			</div>
		</div>
	);
}
