import { useState } from 'react';

import {
	Clipboard,
	Smile,
	User,
	ShoppingCart,
	Clock,
	Settings,
	Grid,
	// DollarSign,
	Tool,
	ChevronRight,
	ChevronLeft,
	ShoppingBag,
} from 'react-feather';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Sidebar() {
	const [sidebarMenu, setSidebarMenu] = useState(false);
	const selfPermissions = useSelector((state) => state?.auth?.self?.role);

	return (
		<div
			className={
				sidebarMenu
					? 'sidebar bg-secondColor-300  text-sm max-w-[220px] dispMenu'
					: 'sidebar bg-secondColor-200  text-sm max-w-[90px]'
			}
		>
			<ul>
				<li>
					<button
						className="text-secondColor-600"
						onClick={() => setSidebarMenu(!sidebarMenu)}
					>
						{sidebarMenu ? (
							<ChevronLeft size={30} />
						) : (
							<ChevronRight size={30} />
						)}
					</button>
				</li>
				<li>
					<NavLink to={'/dashboard'} className="text-secondColor-600">
						<Grid size={24} />
						<strong>Dashboard</strong>
					</NavLink>
				</li>

				{selfPermissions === 'EMPLOYEE' ||
				selfPermissions === 'SALESMAN' ||
				selfPermissions === 'ADMIN' ||
				selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/inventory'} className="text-secondColor-600">
							<Clipboard size={24} /> <strong>Inventory</strong>
						</NavLink>
					</li>
				)}
				{selfPermissions === 'EMPLOYEE' ||
				selfPermissions === 'SALESMAN' ||
				selfPermissions === 'ADMIN' ||
				selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/pricing'} className="text-secondColor-600">
							<ShoppingBag size={24} /> <strong>Pricing</strong>
						</NavLink>
					</li>
				)}
				{selfPermissions === 'EMPLOYEE' || selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/clients'} className="text-secondColor-600">
							<User size={24} /> <strong>Clients</strong>
						</NavLink>
					</li>
				)}
				{selfPermissions === 'EMPLOYEE' ||
				selfPermissions === 'SALESMAN' ||
				selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/leads'} className="text-secondColor-600">
							<Smile size={24} /> <strong>Leads & Quotes</strong>
						</NavLink>
					</li>
				)}
				{selfPermissions === 'EMPLOYEE' || selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/orders'} className="text-secondColor-600">
							<ShoppingCart size={24} /> <strong>Orders</strong>
						</NavLink>
					</li>
				)}
				{selfPermissions === 'EMPLOYEE' ||
				selfPermissions === 'SALESMAN' ||
				selfPermissions === 'ADMIN' ||
				selfPermissions === 'SUPER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/installations'} className="text-secondColor-600">
							<ShoppingCart size={24} /> <strong>Ready to Install</strong>
						</NavLink>
					</li>
				)}
				{selfPermissions === 'EMPLOYEE' ||
				selfPermissions === 'SALESMAN' ||
				selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/repairs'} className="text-secondColor-600">
							<Tool size={24} /> <strong>Repairs</strong>
						</NavLink>
					</li>
				)}

				<li>
					<NavLink to={'/clock-in'} className="text-secondColor-600">
						<Clock size={24} /> <strong>Clock in</strong>
					</NavLink>
				</li>
				{/* {selfPermissions === 'EMPLOYEE' ||
				selfPermissions === 'SALESMAN' ||
				selfPermissions === 'INSTALLER' ? (
					' '
				) : (
					<li>
						<NavLink to={'/billing'} className="text-secondColor-600">
							<DollarSign size={24} /> <strong>Billing</strong>
						</NavLink>
					</li>
				)} */}
			</ul>
			{selfPermissions === 'EMPLOYEE' ||
			selfPermissions === 'SALESMAN' ||
			selfPermissions === 'ADMIN' ||
			selfPermissions === 'INSTALLER' ? (
				' '
			) : (
				<ul>
					<li>
						<NavLink to={'/settings'} className="text-secondColor-600">
							<Settings scale={24} /> <strong>Settings</strong>
						</NavLink>
					</li>
				</ul>
			)}
		</div>
	);
}
