import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../config/api';

interface ErrorType {
    message: string; 
}

export const fetchWorkOrders = createAsyncThunk(
  'orders/fetchWorkOrders',
  async (_, { rejectWithValue, getState  }) => {
    try {
      const token = (getState() as any).auth.token;

      // Set up headers for authentication
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await api.get(`/WorkOrders`, { headers });
      return response.data;
    } catch (err: any) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue(err.response.data);
    }
  }
);

export const fetchWorkOrderById = createAsyncThunk(
  'orders/fetchWorkOrderById',
  async (id, { rejectWithValue, getState }) => {
    try {
      const token = (getState() as any).auth.token;

      // Set up headers for authentication
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await api.get(`/workOrders/${id}`, { headers });
      return response.data;
    } catch (err: any) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue(err.response.data);
    }
  }
);

export const createWorkOrder = createAsyncThunk(
  'orders/createWorkOrder',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const token = (getState() as any).auth.token;
      // Set up headers for authentication
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await api.post('/WorkOrder', payload, { headers });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);



const workOrdersSlice = createSlice({
  name: 'orders',
  initialState: {
    items: [],
    status: 'idle',
    error: null as ErrorType | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
        state.items = action.payload;
      })
      .addCase(fetchWorkOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchWorkOrderById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkOrderById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(fetchWorkOrderById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null; 
      })
      .addCase(createWorkOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createWorkOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(createWorkOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null; 
      });
      
  },
});

export default workOrdersSlice.reducer;
