import { useEffect } from 'react';
import { fetchSelf } from '../slices/userSlice';
import { useSelector } from 'react-redux';
import { LogOut } from 'react-feather';
import logo from '../assets/Best_National_Carports-logo-blk.svg';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../slices/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import user_placeholder from '../assets/user_placeholder.png';

function Header() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSignOut = () => {
		dispatch(logoutUser());
		navigate('/');
	};

	const selftAccount = useSelector((state) => state?.auth?.self);

	const token = localStorage.getItem('token');

	useEffect(() => {
		// if this compone is loaded fetch self and fetch attendance after 15 min of inactivity

		dispatch(fetchSelf());
	}, [dispatch, token]);

	useEffect(() => {
		// if this component is loaded fetch self and fetch attendance after 15 min of inactivity
		dispatch(fetchSelf());

		const timeout = setTimeout(() => {
			if (!selftAccount?.firstName) {
				localStorage.clear();
				document.cookie = '';
				window.location.reload();
			}
		}, 5000);

		return () => clearTimeout(timeout);
	}, [dispatch, selftAccount?.firstName]);

	return (
		<div className="header p-5 bg-secondColor-100">
			<div className="flex justify-between items-center">
				<div className="flex items-center">
					<Link to={'/'}>
						<div className="w-40">
							<img src={logo} alt="Best National Carports Logo" />
						</div>
					</Link>
				</div>
				<div className="top-left-nav">
					<div className="up-left">
						<p>Hi</p>
						<Link to={'/profile'} className="name_pic">
							{selftAccount?.profilePicture ? (
								<div className="profilePict">
									<img src={selftAccount?.profilePicture} alt="profile" />
								</div>
							) : (
								<div className="profilePict">
									<img src={user_placeholder} alt="profile" />
								</div>
							)}{' '}
							<p>{selftAccount?.firstName}</p>
						</Link>{' '}
					</div>
					<div
						className="w-10 h-10 bg-gray-200 rounded-full flex justify-center items-center font-medium hover:cursor-pointer"
						onClick={handleSignOut}
					>
						<LogOut size={24} onClick={handleSignOut} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;
