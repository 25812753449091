import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ClientsContent from './ClientsContent';

export default function Clients() {
	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<ClientsContent />
			</div>
		</div>
	);
}
