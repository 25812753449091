import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, fetchUserById } from '../slices/userSlice';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { UserPlus } from 'react-feather';
import Users from './Users';
import NewUser from '../components/users/NewUser';
// import Loading from '../components/Loading';

export default function Settigns() {
	const dispatch = useDispatch();
	const [newUserForm, setNewUserForm] = useState(false);
	const token = useSelector((state) => state.auth.token);

	// const [isLoading, setIsLoading] = useState(true);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 1700);
	// }, []);

	// useEffect(() => {
	// 	dispatch(fetchUsers());
	// }, [dispatch]);

	// if (isLoading) {
	// 	return <Loading />;
	// }

	const closeDetails = () => {
		setNewUserForm(null);
		if (token) dispatch(fetchUsers(), fetchUserById());
	};

	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					<div className="searchbar">
						<div className="filters"></div>
						{/* <div className="search-input">
							<Search size={16} />
							<input type="text" placeholder="Search" />
						</div> */}
					</div>
					<div className="dashboard-content">
						<div className="action-btns">
							<button
								className="btn-new-user"
								onClick={() => setNewUserForm(true)}
							>
								Create a new user <UserPlus size={24} />
							</button>
						</div>
					</div>
					<Users />
					{newUserForm && <NewUser onClose={() => closeDetails()} />}
				</main>
			</div>
		</div>
	);
}
