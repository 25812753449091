import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Sliders,
	File,
	ArrowRightCircle,
	ArrowLeftCircle,
	DownloadCloud,
	Download,
	UploadCloud,
	FilePlus,
	Save,
} from 'react-feather';
import {
	fetchMaterials,
	fetchMaterialById,
	importMaterials,
	exportMaterials,
	fetchCategories,
} from '../slices/materialsSlice';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import PricingDetails from '../components/Pricing/PricingDetails';
import NewPricing from '../components/Pricing/NewPricing';

export default function Pricing() {
	const dispatch = useDispatch();
	const material = useSelector((state) => state.materials.items.items);
	const [newMaterialForm, setNewMaterialForm] = useState(false);
	const token = useSelector((state) => state.auth.token);
	// const [inventory, setInventory] = useState(false);
	const [materialId, setMaterialId] = useState(null);

	const openDetails = (materialId) => {
		setMaterialId(materialId);
		dispatch(fetchMaterials());
		dispatch(fetchMaterialById(materialId));
	};

	const closeDetails = () => {
		setNewMaterialForm(null);
		if (token) dispatch(fetchMaterials(), fetchMaterialById());
	};

	// const [isLoading, setIsLoading] = useState(true);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 1500);
	// }, []);

	// if (isLoading) {
	// 	return <p>Loading...</p>;
	// }

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [categoryList, setCategoryList] = useState([]);

	useEffect(() => {
		dispatch(fetchMaterials({ pageSize, page: currentPage }));
	}, [dispatch, pageSize, currentPage]);

	useEffect(() => {
		dispatch(fetchMaterials());
		// fetchCategories and setCategoryList
		dispatch(fetchCategories()).then((action) => {
			if (fetchCategories.fulfilled.match(action)) {
				setCategoryList(action.payload);
			}
		});
		const fetchTotalCountAndMaterials = async () => {
			dispatch(fetchMaterials({ pageSize }));
		};

		fetchTotalCountAndMaterials();
	}, [dispatch]);

	useEffect(() => {}, [dispatch, pageSize]);

	// Pagination Handlers
	const handleNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(1); // Reset to first page with new page size
	};

	// Import and export data in bulk

	const fileInputRef = useRef(null);
	const [downloadCategories, setDownloadCategories] = useState(0);

	const handleImport = (event) => {
		const files = event.target.files;
		if (files.length > 0) {
			const file = files[0];
			dispatch(importMaterials(file));
		}
	};

	// Filter components
	const [filterName, setFilterName] = useState('');
	const [filterDescription, setFilterDescription] = useState('');
	const [filterCategoryId, setFilterCategoryId] = useState('');
	const [filterWidth, setFilterWidth] = useState('');
	const [filterLength, setFilterLength] = useState('');
	const [filterHeight, setFilterHeight] = useState('');

	const applyFilters = () => {
		dispatch(
			fetchMaterials({
				categoryId: filterCategoryId,
				pageSize: 10000,
				page: currentPage,
				sortOrder: 'ASC',
				name: filterName,
				width: filterWidth,
				length: filterLength,
				height: filterHeight,
				description: filterDescription,
			})
		);
	};

	const resetFilters = () => {
		setFilterName('');
		setFilterDescription('');
		setFilterCategoryId('');
		setFilterWidth('');
		setFilterLength('');

		applyFilters(); // Fetch all materials without filters
	};

	// Toggle sections
	const [filterSection, setFilterSections] = useState({
		filterOptions: false,
		exportMaterials: false,
		importMaterials: false,
	});

	const toggleSection = (filterSection) => {
		setFilterSections((prevSections) => ({
			...prevSections,
			[filterSection]: !prevSections[filterSection],
		}));
	};

	const handleExport = () => {
		// Example CategoryId, adjust based on your UI/state
		const categoryId = { CategoryId: downloadCategories };

		dispatch(exportMaterials(categoryId)).then((action) => {
			if (action.meta.requestStatus === 'fulfilled') {
				// API returns the data in the response, convert to csv and download
				const csv = action.payload; // Ensure this is a string in CSV format
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				const url = window.URL.createObjectURL(blob);

				// Correctly create the <a> element
				const a = document.createElement('a');
				a.href = url;
				a.download = 'prices.csv'; // Name the download file here
				document.body.appendChild(a); // Append the <a> element to the body
				a.click(); // Simulate click on the <a> element to start the download

				// Cleanup
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a); // Remove the <a> element after download
			} else {
				// Handle rejection or errors here
				console.error(
					'Export failed:',
					action.error?.message || 'Unknown error'
				);
			}
		});
	};

	return (
		<div className="flex flex-col h-screen inventory-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					<h1 className="big-title">Pricing Management</h1>
					<div className="searchbar">
						<div className="filters">
							<button onClick={() => toggleSection('filterOptions')}>
								All filters <Sliders size={24} />
							</button>
							<button onClick={() => toggleSection('importMaterials')}>
								Import Materials <UploadCloud size={24} />
							</button>
							<button onClick={() => toggleSection('exportMaterials')}>
								Export Materials <DownloadCloud size={24} />
							</button>
						</div>
						<div className="action-btns">
							<button
								className="btn-new-user"
								onClick={() => setNewMaterialForm(true)}
							>
								Add new Item <FilePlus size={24} />
							</button>
						</div>

						{/* <div className="search-input">
							<Search size={16} />
							<input type="text" placeholder="Search" />
						</div> */}
					</div>
					<div className="searchbar">
						<div className="action-btns w-full">
							{filterSection.importMaterials && (
								<div className="exp-imp-tab">
									<button
										className="exp-imp-btn"
										onClick={() => fileInputRef.current.click()}
									>
										Import Materials <Save size={24} />
									</button>
									<input
										ref={fileInputRef}
										type="file"
										hidden
										onChange={handleImport}
									/>
								</div>
							)}

							{filterSection.exportMaterials && (
								<div className="exp-imp-tab">
									<select
										onChange={(e) => setDownloadCategories(e.target.value)}
										className="select-export"
									>
										<option value="0">Select Category</option>
										{categoryList?.items &&
											categoryList?.items.map((category) => (
												<option key={category.id} value={category.id}>
													{category.name}
												</option>
											))}
									</select>
									<button className="exp-imp-btn" onClick={handleExport}>
										Export Materials <Download size={24} />
									</button>
								</div>
							)}
						</div>
					</div>
					{/* Filters options going here */}
					{filterSection.filterOptions && (
						<div className="filters-options mt-10">
							<input
								type="text"
								placeholder="Filter by name..."
								value={filterName}
								onChange={(e) => setFilterName(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Filter by description..."
								value={filterDescription}
								onChange={(e) => setFilterDescription(e.target.value)}
							/>
							<input
								type="number"
								placeholder="width..."
								value={filterWidth}
								onChange={(e) => setFilterWidth(e.target.value)}
							/>
							<input
								type="number"
								placeholder="Height..."
								value={filterHeight}
								onChange={(e) => setFilterHeight(e.target.value)}
							/>
							<input
								type="number"
								placeholder="length..."
								value={filterLength}
								onChange={(e) => setFilterLength(e.target.value)}
							/>
							{/* Assuming you have a state for categories fetched from the API */}
							<select
								value={filterCategoryId}
								onChange={(e) => setFilterCategoryId(e.target.value)}
							>
								<option value="">Select a Category</option>
								{categoryList?.items?.map((categoryFilter) => (
									<option key={categoryFilter?.id} value={categoryFilter?.id}>
										{categoryFilter?.name}
									</option>
								))}
							</select>
							<button onClick={applyFilters} className="filter-btn">
								Apply Filters
							</button>
							<button onClick={resetFilters} className="filter-btn">
								Reset Filters
							</button>
						</div>
					)}

					<div className="dashboard-content">
						<div className="pagination-controls">
							<div className="prevNext">
								<button
									onClick={handlePreviousPage}
									disabled={currentPage === 1}
								>
									<ArrowLeftCircle size={24} /> Prev
								</button>
								<strong>{currentPage}</strong>
								<button onClick={handleNextPage}>
									Next <ArrowRightCircle size={24} />
								</button>
							</div>
							<div className="page-size-select">
								<label htmlFor="pageSize">Items per page: </label>
								<select value={pageSize} onChange={handlePageSizeChange}>
									<option value="10">10</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="500">500</option>
									<option value="1000">1000</option>
								</select>
							</div>
						</div>

						<table className="min-w-full">
							<thead>
								<tr>
									<th>Product</th>
									<th>Category</th>
									<th>Size</th>
									{/* <th>Active</th> */}
								</tr>
							</thead>
							<tbody>
								{material
									?.slice() // Create a shallow copy of the array
									.reverse()
									.filter((mate) => mate.active)
									.map((mate) => (
										<tr key={mate?.id} className="user-list">
											<td>{mate?.name}</td>
											<td>{mate?.categoryName}</td>
											<td>
												{mate?.width ? <>{mate?.width} X</> : ''}{' '}
												{mate?.length ? <>{mate?.length}</> : ''}
												{mate?.height ? <>X {mate?.height}</> : ''}{' '}
												{mate?.measurementUnit}
											</td>

											{/* <td className="flex items-center justify-center">
												{mate?.StockLimit === 0 || mate?.active === false ? (
													<div className="w-5 h-5 rounded-full bg-redColor-400"></div>
												) : (
													<div className="w-5 h-5 rounded-full bg-greenColor-400"></div>
												)}
											</td> */}
											<td>
												<button onClick={() => openDetails(mate?.id)}>
													<File size={16} />
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>

						{materialId && (
							<PricingDetails
								onClose={() => setMaterialId(null)}
								materialId={materialId}
							/>
						)}
						{newMaterialForm && <NewPricing onClose={() => closeDetails()} />}
					</div>
				</main>
			</div>
		</div>
	);
}
