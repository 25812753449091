import { useEffect, useState } from 'react';
import { Sliders, PlusSquare, File } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients, fetchClientById } from '../slices/clientSlice';
import ClientDetails from '../components/clients/ClientDetails';
import Loading from '../components/Loading';
import NewClient from '../components/clients/NewClient';
import { formatPhoneNumber, removePhoneFormatting } from '../hooks/phoneFormat';
import { fetchSelf } from '../slices/userSlice';
import { useNavigate } from 'react-router-dom';

export default function ClientsDashboard() {
	const dispatch = useDispatch();
	const clients = useSelector((state) => state.clients.items.items);
	const clientById = useSelector((state) => state.clients.clientById);
	const [selectedClient, setSelectedClient] = useState(null);
	const [newClientForm, setNewClientForm] = useState(false);
	const [newCLientDescription, setNewClientDescription] = useState(null);
	// Search bar
	const [filterFirstName, setFilterFirstName] = useState('');
	const [filterLastName, setFilterLastName] = useState('');
	const [filterCompanyName, setFilterCompanyName] = useState('');
	const [filterPhone, setFilterPhone] = useState('');
	const [filterStatus, setFilterStatus] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	// Toggle sections
	const [filterSection, setFilterSections] = useState({
		filterOptions: false,
	});
	const [pageSize, setPageSize] = useState(5);
	const [isLoading, setIsLoading] = useState(true);

	const clientIdString = localStorage.getItem('clientId');
	const clientId = JSON.parse(clientIdString)?.id;

	// Checking if the user is logged in
	// const user = useSelector((state) => state.user);
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 1700);
	}, []);

	useEffect(() => {
		dispatch(
			fetchClients({
				pageSize,
				page: currentPage,
			}),
			fetchClientById()
		);
	}, [dispatch]);

	useEffect(() => {
		if (clientId !== null) {
			setSelectedClient(clientId);
			dispatch(fetchClientById(clientId));
		}
	}, [clientId]);

	const openClient = (clientId) => {
		setSelectedClient(clientId);
		dispatch(fetchClientById(clientId));
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}

	const closeDetails = () => {
		setNewClientForm(null);
		// remove localStorage item clientId
		setNewClientDescription(clientId);
		setSelectedClient(clientId);
		dispatch(fetchClientById(clientId));
	};

	const closeSlide = () => {
		setNewClientForm(false);
		setNewClientForm(null);
		setSelectedClient(null);
		localStorage.removeItem('clientId');
	};

	// Search filters

	const applyFilters = () => {
		const newPhone = removePhoneFormatting(filterPhone);
		dispatch(
			fetchClients({
				pageSize: pageSize,
				page: currentPage,
				firstName: filterFirstName,
				lastName: filterLastName,
				companyName: filterCompanyName,
				phone: newPhone,
				status: filterStatus,
			})
		);
	};

	const resetFilters = () => {
		setFilterFirstName('');
		setFilterLastName('');
		setFilterCompanyName('');
		setFilterPhone('');
		setFilterStatus('');

		applyFilters(); // Fetch all materials without filters
		dispatch(fetchClients());
	};

	// Toggle sections

	const toggleSection = (filterSection) => {
		setFilterSections((prevSections) => ({
			...prevSections,
			[filterSection]: !prevSections[filterSection],
		}));
	};

	const optionStatus = [
		{ id: 1, name: 'New' },
		{ id: 2, name: 'Contacted' },
		{ id: 3, name: 'Confirmed' },
	];

	const handleNewClient = async () => {
		const actionResult = await dispatch(fetchSelf());
		if (fetchSelf.fulfilled.match(actionResult)) {
			setNewClientForm(true);
		} else {
			navigate('/');
		}
	};

	return (
		<main className="dashboardList">
			<div className="searchbar">
				<div className="filter"></div>
			</div>
			{/* Filters options going here */}
			{filterSection.filterOptions && (
				<div className="filters-options mt-10">
					<input
						type="text"
						placeholder="Filter by name..."
						value={filterFirstName}
						onChange={(e) => setFilterFirstName(e.target.value)}
					/>
					<input
						type="text"
						placeholder="Filter by last name..."
						value={filterLastName}
						onChange={(e) => setFilterLastName(e.target.value)}
					/>
					<input
						type="text"
						placeholder="Filter by company name..."
						value={filterCompanyName}
						onChange={(e) => setFilterCompanyName(e.target.value)}
					/>

					<button onClick={applyFilters} className="filter-btn">
						Search
					</button>
					<button onClick={resetFilters} className="filter-btn">
						Reset Inputs
					</button>
				</div>
			)}
			<div className="dashboard-content">
				<div className="action-btns">
					<button onClick={() => toggleSection('filterOptions')}>
						<Sliders size={24} />
					</button>
					<button
						className="btn-new-users ml-5"
						// onClick={() => setNewClientForm(true)}
						onClick={handleNewClient}
					>
						<PlusSquare size={24} />
					</button>
				</div>
				<table className="min-w-full">
					<thead>
						<tr>
							<th>Full Name / Company</th>
						</tr>
					</thead>
					<tbody>
						{clients?.map((client) => (
							<tr
								key={client?.id}
								className="select-by-name"
								onClick={() => openClient(client?.id)}
							>
								<td className="!text-left">
									{client?.firstName ? client?.firstName : ''}{' '}
									{client?.lastName ? client?.lastName : ''} <br />
									{client?.companyName ? (
										<strong>{client?.companyName}</strong>
									) : (
										''
									)}
								</td>

								<td>
									<button
										onClick={() => openClient(client?.id)}
										// className="btn-details"
									>
										<File size={24} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{selectedClient && ( // Adjust based on your store setup
				<ClientDetails
					selectedClient={selectedClient}
					clientDetails={clientById}
					onClose={closeDetails}
					closeSlide={closeSlide}
					newCLientDescription={clientId}
				/>
			)}

			{newClientForm && (
				<NewClient
					onClose={closeDetails}
					closeSlide={closeSlide}
					selectedClient={selectedClient}
					clientDetails={clientById}
				/>
			)}
		</main>
	);
}
