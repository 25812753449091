import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../config/api';

interface ErrorType {
    message: string; // Adjust this based on the actual error structure you expect
  }

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (
    {
      clientFirstName = '',
      clientLastName = '',
      filterPostalCode = '',
      pageSize = 100,
      currentPage = 1,
      sortOrder = 'DESC',
    }: {
      clientFirstName?: string;
      clientLastName?: string;
      filterPostalCode?: string;
      pageSize?: number;
      sortOrder?: 'ASC' | 'DESC';
      currentPage?: number;
    } = {},
    { rejectWithValue, getState }: { rejectWithValue: any; getState: any; dispatch: any }
  ) => {
    try {
      const state = getState() as any;
      const token = state.auth.token;
      const refreshToken = state.auth.refreshToken;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let query = '/Orders?';
      if (pageSize) query += `&pageSize=${pageSize}`;
      if (sortOrder) query += `&sortOrder=${sortOrder}`;
      if (currentPage) query += `&page=${currentPage}`;
      if (clientFirstName) query += `&clientFirstName=${encodeURIComponent(clientFirstName)}`;
      if (clientLastName) query += `&clientLastName=${encodeURIComponent(clientLastName)}`;
      if (filterPostalCode) query += `&deliveryPostalCode=${encodeURIComponent(filterPostalCode)}`;

      // Add sort parameter
      // query += `&sortOrder=ASC`;

      const response = await api.get(query, { headers });

      // If the token is expired, refresh it
      if (response.status === 401) {
        const refreshResponse = await api.post('/Accounts/Refresh', { refreshToken });

        // Retry the original request
        const retryHeaders = {
          Authorization: `Bearer ${refreshResponse.data.accessToken}`,
        };
        const retryResponse = await api.get(query, { headers: retryHeaders });
        return retryResponse.data;
      }

      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          localStorage.removeItem('token');
          window.location.reload();
          return rejectWithValue('Refresh token not found');
        }
        try {
          const response = await api.post('/Accounts/Refresh', { refreshToken });
          localStorage.setItem('token', response.data.accessToken);
          if (response.data.refreshToken) {
            localStorage.setItem('refreshToken', response.data.refreshToken);
          }
          return response.data;
        } catch (error: any) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.reload();
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue(error.response.data);
    }
  }
);


interface Tokens {
  token: string;
  refreshToken: string;
}

export const saveTokens = createAsyncThunk<Tokens, Tokens>(
  'auth/saveTokens',
  async ({ token, refreshToken }: Tokens) => {
    return { token, refreshToken };
  }
);

export const fetchOrderById = createAsyncThunk(
  'orders/fetchOrderById',
  async (orderId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/Orders/${orderId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


interface UpdateOrderStatusPayload {
   status: number;
   orderId: number;
}

export const updateOrderStatus = createAsyncThunk(
  'orders/updateOrderStatus',
  async ({status, orderId}: UpdateOrderStatusPayload, { rejectWithValue, getState }) => {
    try {
      const token = (getState() as any).auth.token;
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await api.put(`/Orders/${orderId}/Status`, {status}, { headers });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define the initial state of the orders slice
interface OrderPayload {
  clientId: string;
  installerId: string; // added this field
  salesmanId: string;
  isVerticalRoof: boolean; // added this field
  // commission: number;
  transportationFee: number;
  drawingsAmount: number;
  equipmentAmount: number;
  discountAmount: number;
  installationAmount: number;
  reference: number;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  source: string;
  items: [
    {
    description: string;
    materialId: number;
    quantity: number;
    unitPrice: number;
    // removed the metadata field from each item
  },];
  metadata: {
    [key: string]: string;
  };
}

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (orderData: OrderPayload, { rejectWithValue, getState }) => {
    try {
      if (!orderData.source) {
        throw new Error("Source cannot be null");
      }
      const token = (getState() as any).auth.token;
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const response = await api.post('/Orders', orderData, { headers });
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);





const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    items: [],
    orders: {
      items: [],
      status: 'idle',
      error: null,
    },
    order: null as OrderPayload | null,
    status: 'idle',
    error: null as ErrorType | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(fetchOrderById.pending, (state) => {
        state.status = 'loading';
        state.order = null; // Clear the currently selected order
      })
      .addCase(fetchOrderById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.order = action.payload; // Set the selected order
      })
      .addCase(fetchOrderById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(updateOrderStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.order = action.payload;
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      })
      .addCase(createOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.order = action.payload;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ? { message: action.error.message } : null;
      });
    },
});

export default ordersSlice.reducer;
