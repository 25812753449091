// import React, { useState } from 'react';
import { X } from 'react-feather';

export default function Checklist({ onClose, workOrders }) {
	// if (!details) return null;

	return (
		<div className="details-details-panel">
			<aside className="details-panel">
				<div className="details-number">
					<h1>Checklist</h1>
					<div className="block-left">
						{/* <h3 className="order-number-title">Order {order.reference}</h3> */}
						<p className="details-number-value">
							{/* Date {new Date(order.createdAt).toLocaleDateString()} */}
						</p>
					</div>
					<div className="block-right">
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>
				{/* <div className="progress-guide">
					<div className="progress-guide-block">
						<h2 className="progress-guide-title">Progress Guide</h2>
					</div>
				</div> */}

				{/* <div className="sale-information">
					<div className="sale-information-block">
						<div className="title-block" onClick={() => toggleSection('sale')}>
							<h2 className="sale-information-title">Sale Information</h2>
							<button>
								{sections.sale ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.sale && (
							<div className="content-block">
								<p className="sale-information-value">Sale Information</p>
							</div>
						)}
					</div> 
				</div>*/}
				{/* <div className="client-information">
					<div className="client-information-block">
						<div
							className="title-block"
							onClick={() => toggleSection('client')}
						>
							<h2 className="client-information-title">Client Information</h2>
							<button>
								{sections.client ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.client && (
							<p className="client-information-value">Client Information</p>
						)}
					</div>
				</div> */}
				{/* <div className="structure-details">
					<div className="structure-details-block">
						<div
							className="title-block"
							onClick={() => toggleSection('structure')}
						>
							<h2 className="structure-details-title">Structure Details</h2>
							<button>
								{sections.structure ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.structure && (
							<p className="structure-details-value">Structure Details</p>
						)}
					</div>
				</div> */}
				{/* <div className="notes-block">
					<div className="title-block" onClick={() => toggleSection('notes')}>
						<h2 className="notes-title">Notes</h2>
						<button>
							{sections.notes ? (
								<ChevronDown size={24} />
							) : (
								<ChevronRight size={24} />
							)}
						</button>
					</div>
					{sections.notes && <p className="notes-value">Notes</p>}
				</div> */}
				<div className="installation-permits">
					<div className="instalation-permits-block">
						{/* <div
							className="title-block"
							onClick={() => toggleSection('permits')}
						>
							<h2 className="instalation-permits-title">
								Installation Permits
							</h2>
							<button>
								{sections.permits ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div> */}
						{/* {sections.permits && (
							<p className="instalation-permits-value">Installation Permits</p>
						)} */}
					</div>
				</div>
			</aside>
		</div>
	);
}
