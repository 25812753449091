import { useState } from 'react';
import { X, ChevronRight, ChevronDown, Download } from 'react-feather';
import { useSelector } from 'react-redux';
import { formatMyDate } from '../hooks/useFormattedDate';
import ProgressBar from './ProgressBar';
import { updateOrderStatus } from '../slices/ordersSlice';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

export default function OrderDetails({ order, onClose }) {
	const dispatch = useDispatch();
	const orderDetails = useSelector((state) => state.orders.order);
	const usersList = useSelector((state) => state.auth?.users?.items);
	const client = useSelector((state) => state.clients.clientById);
	const currerntUser = useSelector((state) => state.auth.self);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			status: orderDetails.status,
		},
	});

	const [sections, setSections] = useState({
		sale: false,
		client: false,
		structure: false,
		notes: false,
		permits: false,
		recomendations: false,
	});

	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	if (!order) return null;

	const orderId = orderDetails.id;

	const handleStatus = (data) => {
		console.log(data);
		dispatch(
			updateOrderStatus({
				orderId,
				status: Number(data.status), // Convert to number
			})
		);
	};

	// Notes section
	const getUserName = (userId) => {
		if (!userId) {
			return 'Unknown';
		}

		const user = usersList?.find(
			(user) => user?.id?.trim().toLowerCase() === userId.trim().toLowerCase()
		);

		return user ? user.firstName : 'Unknown';
	};

	return (
		<div className="order-details-panel">
			<aside className="details-panel">
				<div className="order-number">
					<div className="block-left">
						<h3 className="order-number-title">Order {order.reference}</h3>
						<p className="order-number-value">
							Date{' '}
							{new Date(order?.createdAt).toLocaleString('en-US', {
								month: '2-digit',
								day: '2-digit',
								year: '2-digit',
								hour: '2-digit',
								minute: '2-digit',
							})}
						</p>
					</div>
					<div className="block-right">
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>
				<div className="progress-guide">
					<div className="progress-guide-block w-full">
						<h2 className="progress-guide-title">
							<ProgressBar currentStatus={orderDetails.status} />
						</h2>
					</div>
				</div>
				<div className="tab-information">
					<div className="tab-information-block">
						<div className="title-block" onClick={() => toggleSection('sale')}>
							<h2 className="title-h2">Sale Information</h2>
							<button>
								{sections.sale ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.sale && (
							<>
								<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
									<p className="data-content">
										<strong>First Name</strong>
										{orderDetails.salesman.firstName}
									</p>
									<p className="data-content">
										<strong>Last Name</strong>
										<div>{orderDetails.salesman.lastName}</div>
									</p>
									<p className="data-content">
										<strong>Email</strong>
										<a href={`mailto:${orderDetails.salesman.email}`}>
											{orderDetails.salesman.email}
										</a>
									</p>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="tab-information">
					<div className="tab-information-block">
						<div
							className="title-block"
							onClick={() => toggleSection('client')}
						>
							<h2 className="title-h2">Client Information</h2>
							<button>
								{sections.client ? (
									<ChevronDown size={24} />
								) : (
									<ChevronRight size={24} />
								)}
							</button>
						</div>
						{sections.client && (
							<>
								<div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2 content-block">
									<p className="data-content">
										<strong>First Name</strong>
										{orderDetails.client.firstName}
									</p>
									<p className="data-content">
										<strong>Last Name</strong>
										<div>{orderDetails.client.lastName}</div>
									</p>
									<p className="data-content">
										<strong>Phone</strong>
										<a href={`tel:+1${orderDetails.client.phoneNumber}`}>
											{orderDetails.client.phoneNumber}
										</a>
									</p>
									<p className="data-content">
										<strong>Email</strong>
										<a href={`mailto:${orderDetails.client.email}`}>
											{orderDetails.client.email}
										</a>
									</p>
								</div>
								<div className="w-full block-subtitle">
									<h3 className="text-lg text-secondColor-700 mt-3">
										Delivery Address
									</h3>
								</div>
								<div className="content-box grid grid-cols-2 content-block">
									<p className="data-content">
										<strong>Street</strong>
										<div>{orderDetails.address}</div>
									</p>
									<p className="data-content">
										<strong>City</strong>
										<div>{orderDetails.city}</div>
									</p>
									<p className="data-content">
										<strong>State</strong>
										<div>{orderDetails.state}</div>
									</p>
									<p className="data-content">
										<strong>Zipcode</strong>
										<div>{orderDetails.postalCode}</div>
									</p>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="tab-information">
					<div className="title-block" onClick={() => toggleSection('notes')}>
						<h2 className="title-h2">Notes</h2>
						<button>
							{sections.notes ? (
								<ChevronDown size={24} />
							) : (
								<ChevronRight size={24} />
							)}
						</button>
					</div>
					{sections.notes ? (
						<div className="content-box-notes">
							{client?.notes &&
								[...client.notes].reverse().map((note, index) => (
									<div key={index} className="notesList">
										<span className="notes-item-title">
											<strong>Date:</strong>{' '}
											{new Date(note?.createdAt).toLocaleString('en-US', {
												month: '2-digit',
												day: '2-digit',
												year: '2-digit',
												hour: '2-digit',
												minute: '2-digit',
											})}
										</span>
										<p className="notes-item-title">
											{note && note?.userId && (
												<strong>Account manager:</strong>
											)}
											{note && note?.userId && getUserName(note?.userId)}
										</p>
										<span className="whitespace-normal"></span>
										<div className="notes-item-value">
											<strong>Comments:</strong>
											<br />
											{note?.text}
										</div>
										<div className="field-section-full download-array">
											{note?.attachments?.length > 0 ? (
												<strong className="w-full">Download attachments</strong>
											) : (
												''
											)}
											{note?.attachments &&
												note.attachments.map((file, index) => (
													<div key={index} className="notes-item-attachment">
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																window.open(
																	file.url,
																	'_blank',
																	'noopener,noreferrer'
																);
															}}
															className="btn-download-file"
														>
															<Download size={12} />
														</a>
													</div>
												))}
										</div>
									</div>
								))}
						</div>
					) : (
						<p>Currently, there are no notes.</p>
					)}
				</div>

				{currerntUser.role !== 'INSTALLER' && (
					<div className="tab-information">
						<div className="status-box-block">
							<div className="title-block">
								<h2 className="title-h2">Order Status</h2>
							</div>
							<div className="content-box">
								<form
									onSubmit={handleSubmit(handleStatus)}
									className=" px-8 pb-8 mb-4"
								>
									<div className="mb-4">
										<label
											className="block text-gray-700 text-s font-bold mb-2"
											htmlFor="status"
										>
											Change status
										</label>
										<div className="inline-block relative w-full">
											<select
												{...register('status', {
													defaultValue: orderDetails.status,
												})}
												className="block appearance-none w-full bg-white border  hover:border-gray-500 hover:cursor-pointer px-4 py-2  rounded shadow leading-tight focus:outline-none focus:shadow-outline"
											>
												<option value="1">Quote</option>
												<option value="2">Order set</option>
												<option value="3">Permits</option>
												<option value="4">Invoice sent</option>
												<option value="5">Installation</option>
												<option value="10">Done</option>
												<option value="6">Repair</option>
												<option value="-1">Delete</option>
											</select>
											<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
												<ChevronDown size={24} />
											</div>
										</div>
										{/* {errors.status && (
										<p className="text-redColor-500 text-s italic p-2">
											{errors.status.message}
										</p>
									)} */}
									</div>

									<button type="submit" className="redBtn">
										Change status
									</button>
								</form>
							</div>
						</div>
					</div>
				)}
			</aside>
		</div>
	);
}
