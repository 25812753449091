import { useState, useEffect } from 'react';
import axios from 'axios';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
	addRoleToUser,
	deleteUser,
	fetchUsers,
	resetPassword,
	userInfo,
	fetchUserById,
	createDocument,
	fetchUserSetting,
	fetchUserDocuments,
	deleteUserDocument,
} from '../../slices/userSlice';
import {
	ChevronDown,
	ChevronRight,
	X,
	Edit2,
	Trash2,
	Download,
} from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateUser, fetchSelf } from '../../slices/userSlice';
import { formatPhoneNumber } from '../../hooks/phoneFormat';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import usePopup from '../../hooks/popuphook';

export default function UserDetails({ onClose }) {
	const dispatch = useDispatch();
	const api = process.env.REACT_APP_API_URL;
	const [message, setMessage] = useState(null);
	const [messageDoc, setMessageDoc] = useState(null);
	const [title, setTitle] = useState('');
	const [filePath, setFilePath] = useState('');

	const [editForm, setEditForm] = useState(false); // Toggle edit mode
	const userSettings = useSelector((state) => state.auth?.userSetting);
	const currentUser = useSelector((state) => state.auth?.userById);
	const userDocuments = useSelector((state) => state.auth?.userDocuments.items);

	const [sections, setSections] = useState({
		// User details
		generalInfo: true,
		personalInfo: false,
		employeeInfo: false,
		emergencyContact: false,
		documents: false,
		payments: false,

		// Update user details
		updateFullData: false,
		updateGeneralInfo: true,
		updatePersonalInfo: false,
		updateEmployeeInfo: false,
		updateEmergencyContact: false,
		updateRole: false,
		updateCommision: false,
		removeRole: false,
		updatePass: false,
		uploadPaymentsfiles: false,
		deleteUser: false,
	});

	// checking the user role
	const selftAccount = useSelector((state) => state?.auth?.self?.role);

	const token = localStorage.getItem('token');

	useEffect(() => {
		// if this compone is loaded fetch self and fetch attendance after 15 min of inactivity

		dispatch(fetchSelf());
	}, [dispatch, token]);
	const toggleSection = (section) => {
		setSections((prevSections) => {
			// Close all sections
			const newSections = Object.keys(prevSections).reduce((sections, key) => {
				sections[key] = false;
				return sections;
			}, {});

			// Open the clicked section
			newSections[section] = !prevSections[section];

			return newSections;
		});
	};

	// useEffect(() => {
	// 	dispatch(fetchUserSetting());
	// }, [dispatch]);

	//url picture
	const [urlPicture, setUrlPicture] = useState('');

	const userId = currentUser?.id;

	useEffect(() => {
		const fetchDocuments = async () => {
			await dispatch(fetchUserDocuments({ userId }));
		};

		fetchDocuments();
	}, [dispatch, userId]);

	// Form stuff
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm();

	const onAddRoleSubmit = (data) => {
		dispatch(addRoleToUser(data));
		onClose();
	};

	const onExtraInfo = (data) => {
		// prevent send info if the form is not submited

		if (!editForm) {
			return;
		}

		const emergPhone = formatPhoneNumber(data.emergencyPhone);

		dispatch(
			userInfo({
				id: currentUser?.id,
				metadata: {
					commission: data.commission,
					hRoof: data.hRoof,
					vRoof: data.vRoof,
					// more basic user info
					address: data?.address,
					city: data?.city,
					state: data?.state,
					zip: data?.zip,
					birthDate: data?.birthDate,
					gender: data?.gender,
					legalId: data?.legalId,
					hireDate: data?.hireDate,
					manager: data?.manager,
					department: data?.department,
					jobTitle: data?.jobTitle,
					employeeId: data?.employeeId,
					nameToPrintOnCheck: data?.nameToPrintOnCheck,
					// vendor info
					companyName: data?.companyName,
					website: data?.website,
					txId: data?.txId,
					bankName: data?.bankName,
					bankAccount: data?.bankAccount,
					bankRouting: data?.bankRouting,
					// emergency contact
					emergencyName: data?.emergencyName,
					emergencyPhone: emergPhone,
					emergencyRelation: data?.emergencyRelation,
					notes: data?.notes,
				},
			})
		);
		setEditForm(false);
		dispatch(fetchUserSetting(currentUser?.id));
		dispatch(fetchUserById(currentUser?.id));
	};

	const onSubmitUpdatePassword = (data) => {
		dispatch(
			resetPassword({
				email: currentUser?.email,
				password: data.password,
			})
		); // Pass only the new password, not the entire data object
		setEditForm(false);
		dispatch(fetchUserSetting(currentUser?.id));
		dispatch(fetchUserById(currentUser?.id));
	};

	// update User

	const onSubmitUpdateUser = (data) => {
		const { firstName, lastName, phoneNumber } = data;
		const formattedPhone = phoneNumber ? formatPhoneNumber(phoneNumber) : null;

		dispatch(
			updateUser({
				id: currentUser?.id,
				firstName,
				lastName,
				phoneNumber: formattedPhone,
				profilePicture: urlPicture,
			})
		);

		dispatch(fetchUserSetting(currentUser?.id));
		dispatch(fetchUserById(currentUser?.id));
		dispatch(fetchUsers());
	};

	// update role
	useEffect(() => {
		if (currentUser?.role) {
			setValue('role', currentUser.role);
		}
	}, [currentUser?.role, setValue]);

	// const [isLoading, setIsLoading] = useState(true);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 1500);
	// }, []);

	// if (isLoading) {
	// 	return <p>Loading...</p>;
	// }
	// SSN
	const ssn = userSettings?.metadata?.legalId;
	console.log('ssn', ssn);

	const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
	if (!ssnPattern.test(ssn)) {
		// Handle invalid SSN
	}

	const maskedSSN = userSettings?.metadata?.legalId
		? `***-**-${userSettings?.metadadata?.legalId.substr(-4)}`
		: '';

	// delte user confirmation

	const { isOpen, openPopup, closePopup } = usePopup();

	const onDeleteSubmit = (data) => {
		openPopup(data.id);
	};

	const handleDelete = () => {
		dispatch(deleteUser(currentUser?.id));
		dispatch(fetchUsers());
		closePopup();
		onClose();
	};
	// upload documents

	// create a popup for delete document

	const [isOpenAgain, setIsOpenAgain] = useState(false);
	const [docId, setDocId] = useState(null);

	const onDeleteDocument = (doc) => {
		// Open the popup
		setIsOpenAgain(true);
		setDocId(doc);
		console.log('id is open', doc);
	};

	const handleDeleteDocument = () => {
		// Check if the document deletion was successful before fetching the documents
		dispatch(deleteUserDocument({ userId: currentUser.id, documentId: docId }));
		setIsOpenAgain(false); // Close the popup
		dispatch(fetchUserDocuments({ userId: currentUser?.id }));
		console.log('delete document is close');
	};
	// here is the file url
	const [urlState, setUrlState] = useState('');
	// Uploading progress bar
	const [Msg, setMsg] = useState(null);
	const [progress, setProgress] = useState({ started: false, pc: 0, msg: '' });

	// pendding to clean up

	// clean functions

	// upload file
	const fileUploadHandler = async (file) => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		if (file) {
			const newFile = new File([file], file.name.replace(/\s/g, '_'), {
				type: file.type,
			});

			formData.append('file', newFile);

			setMsg('Uploading...');
			setProgress({ started: true, pc: 0, msg: 'Uploading...' });
		}

		try {
			const response = await axios.post(`${api}/files/upload`, formData, {
				onUploadProgress: (progressEvent) => {
					const pc = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setProgress({ started: true, pc, msg: 'Uploading...' });
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			setUrlState(response.data.fileUrl);
			setMsg('File uploaded successfully!');
			setProgress({
				started: false,
				pc: 0,
				msg: 'File uploaded successfully ready to send or add more files!',
			});
		} catch (error) {
			setMsg('Error uploading file!');
		}
	};

	const profilePictureHandler = async (file) => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		if (file) {
			const newFile = new File([file], file.name.replace(/\s/g, '_'), {
				type: file?.type,
			});

			formData.append('file', newFile);

			setMsg('Uploading...');
			setProgress({ started: true, pc: 0, msg: 'Uploading...' });
		}

		try {
			const response = await axios.post(`${api}/files/upload`, formData, {
				onUploadProgress: (progressEvent) => {
					const pc = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setProgress({ started: true, pc, msg: 'Uploading...' });
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			setUrlPicture(response.data.fileUrl);
			setMsg('File uploaded successfully!');
			setProgress({
				started: false,
				pc: 0,
				msg: 'File uploaded successfully ready to send or add more files!',
			});
		} catch (error) {
			setMsg('Error uploading file!');
		}
	};

	// create document
	const uploadDocument = async (data) => {
		try {
			if (data && data.title && urlState && currentUser && currentUser.id) {
				const resultAction = await dispatch(
					createDocument({
						userId: currentUser.id,
						title: data.title,
						filePath: urlState,
						type: 0,
					})
				);
				unwrapResult(resultAction);
				setMessageDoc('Document uploaded successfully!');
				setTitle('');
				setFilePath('');
				reset();
				dispatch(fetchUserById(userId));
				dispatch(fetchUserSetting(userId));
				dispatch(fetchUsers());
			} else {
				throw new Error('Invalid data');
			}
		} catch (error) {
			setMessageDoc('Error uploading document!');
		}
	};

	const uploadPayments = async (data) => {
		try {
			if (data && data.title && urlState && currentUser && currentUser.id) {
				const resultAction = await dispatch(
					createDocument({
						userId: currentUser.id,
						title: data.title,
						filePath: urlState,
						type: 1,
					})
				);
				unwrapResult(resultAction);
				setMessage('Payment document uploaded successfully!');
				setTitle('');
				setFilePath('');
				reset();

				dispatch(fetchUserById(userId));
				dispatch(fetchUserSetting(userId));
				dispatch(fetchUsers());
			} else {
				throw new Error('Invalid data');
			}
		} catch (error) {
			setMessage('Error uploading payment!');
		}
	};

	return (
		<div className="details-details-panel">
			{isOpen && (
				<div className="modal">
					<div className="modal-content">
						<h2>Are you sure you want to delete this user?</h2>
						<div className="btncontainer">
							<button onClick={handleDelete} className="yesBtn">
								Yes
							</button>
							<button onClick={closePopup} className="noBtn">
								No
							</button>
						</div>
					</div>
				</div>
			)}
			{isOpenAgain && (
				<div className="modal">
					<div className="modal-content">
						<h2>Are you sure you want to delete this document?</h2>
						<div className="btncontainer">
							<button onClick={handleDeleteDocument} className="yesBtn">
								Yes
							</button>
							<button onClick={() => setIsOpenAgain(false)} className="noBtn">
								No
							</button>
						</div>
					</div>
				</div>
			)}
			<aside className="details-panel">
				<div className="details-number">
					<h1>User Details</h1>
					<div className="block-left"></div>
					<div className="block-right">
						<button
							onClick={() => setEditForm(!editForm)}
							className="btn-edit-item"
						>
							{editForm ? (
								<>Cancel</>
							) : (
								<>
									Edit Details <Edit2 size={20} />
								</>
							)}
						</button>
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>

				{editForm ? (
					<>
						{/* Edit Mode */}
						{/* General Information */}
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('updateGeneralInfo')}
								>
									<h2 className="text-xl font-bold mb-4">
										General Information
									</h2>
									<button>
										{sections.updateGeneralInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.updateGeneralInfo && (
									<div className="content-block">
										<form onSubmit={handleSubmit(onSubmitUpdateUser)}>
											<div className="mb-4">
												<input
													type="file"
													onChange={(e) => {
														const file = e.target.files[0];
														profilePictureHandler(file);
													}}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.document && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.document.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="firstName"
												>
													First Name
												</label>
												<input
													{...register('firstName', {
														required: 'First Name is required',
													})}
													defaultValue={currentUser?.firstName}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.firstName && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.firstName.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="lastName"
												>
													Last Name
												</label>
												<input
													{...register('lastName', {
														required: 'Last Name is required',
													})}
													defaultValue={currentUser?.lastName}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.lastName && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.lastName.message}
													</p>
												)}
											</div>
											{/* <div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="email"
												>
													Email
												</label>
												<input
													{...register('email', {
														required: 'Email is required',
													})}
													defaultValue={currentUser?.email}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.email && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.email.message}
													</p>
												)}
											</div> */}
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="phoneNumber"
												>
													Phone Number
												</label>
												<input
													{...register('phoneNumber')}
													defaultValue={currentUser?.phoneNumber}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.phoneNumber && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.phoneNumber.message}
													</p>
												)}
											</div>
											<button type="submit" className="redBtn">
												Update General Information
											</button>
										</form>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Update Password */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('updatePass')}
								>
									<h2 className="text-xl font-bold mb-4">Update Password</h2>
									<button>
										{sections.updatePass ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.updatePass && (
									<div className="content-block">
										<form
											onSubmit={handleSubmit(onSubmitUpdatePassword)}
											className=" px-8 pb-8 mb-4"
										>
											<input
												{...register('password', {
													required: 'Password is required',
													placeholder: 'New Password',
												})}
												type="password"
												className="shadow appearance-none border rounded inline-block relative w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.password && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.password.message}
												</p>
											)}
											<button type="submit" className="redBtn">
												Update Password
											</button>
										</form>
									</div>
								)}
							</div>
						</div>

						<div className="user-information">
							{/* Update Role */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('updateRole')}
								>
									<h2 className="text-xl font-bold mb-4">Update Role</h2>
									<button>
										{sections.updateRole ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.updateRole && (
									<div className="content-block">
										<form
											onSubmit={handleSubmit(onAddRoleSubmit)}
											className=" px-8 pb-8 mb-4"
										>
											<div className="mb-4 hidden">
												<input
													{...register('email', {
														required: 'Email is required',
													})}
													defaultValue={currentUser?.email}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.email && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.email.message}
													</p>
												)}
											</div>

											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="role"
												>
													New Role
												</label>
												<div className="inline-block relative w-full">
													<select
														{...register('role', {
															required: 'Role is required',
														})}
														className="block appearance-none w-full bg-white border  hover:border-gray-500 hover:cursor-pointer px-4 py-2  rounded shadow leading-tight focus:outline-none focus:shadow-outline"
													>
														<option value="INSTALLER">INSTALLER</option>
														<option value="SALESMAN">DEALER or SALESMAN</option>
														<option value="EMPLOYEE">EMPLOYEE</option>
														<option value="ADMIN">ADMIN</option>
														<option value="SUPER">SUPER ADMIN</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<ChevronDown size={24} />
													</div>
												</div>
												{errors.role && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.role.message}
													</p>
												)}
											</div>

											<button type="submit" className="redBtn">
												Update Role
											</button>
										</form>
									</div>
								)}
							</div>
						</div>
						<form onSubmit={handleSubmit(onExtraInfo)}>
							<div className="user-information">
								{/* Personal Information */}
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('updateFullData')}
									>
										<h2 className="text-xl font-bold mb-4">
											Personal Information
										</h2>
										<button>
											{sections.updateFullData ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.updateFullData && (
										<div className="content-block">
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="address"
												>
													Address
												</label>
												<input
													{...register('address')}
													defaultValue={userSettings?.metadata?.address}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
													placeholder={userSettings?.metadata?.address}
												/>
												{errors.address && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.address.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="city"
												>
													City
												</label>
												<input
													{...register('city')}
													defaultValue={userSettings?.metadata?.city}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.city && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.city.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="state"
												>
													State
												</label>
												<input
													{...register('state')}
													defaultValue={userSettings?.metadata?.state}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.state && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.state.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="zip"
												>
													Zip
												</label>
												<input
													{...register('zip')}
													defaultValue={userSettings?.metadata?.zip}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.zip && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.zip.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="birthDate"
												>
													Birth Date
												</label>
												<input
													{...register('birthDate')}
													type="date"
													defaultValue={
														userSettings?.metadata?.birthDate
															? new Date(userSettings?.metadata?.birthDate)
																	.toISOString()
																	.split('T')[0]
															: ''
													}
													onChange={(event) => {
														setValue('birthDate', event.target.value);
													}}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.birthDate && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.birthDate.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="gender"
												>
													Gender
												</label>
												<select
													{...register('gender')}
													defaultValue={userSettings?.metadata?.gender}
													className="mr-2 leading-tight"
												>
													<option
														value={userSettings?.metadata?.gender === ''}
														disabled
													>
														Select
													</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
												</select>
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="legalId"
												>
													Social Security Number
												</label>
												<input
													{...register('legalId')}
													type={
														ssnPattern.test(userSettings?.metadata?.legalId)
															? 'password'
															: 'text'
													}
													defaultValue={maskedSSN}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.legalId && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.legalId.message}
													</p>
												)}
											</div>
										</div>
									)}
								</div>
							</div>
							{sections.updateFullData && (
								<div className="user-information">
									{/* Employee Details */}
									<div className="user-information-block">
										<div className="content-block">
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="hireDate"
												>
													Hire Date
												</label>
												<input
													{...register('hireDate')}
													type="date"
													defaultValue={
														userSettings?.metadata?.hireDate
															? new Date(userSettings?.metadata?.hireDate)
																	.toISOString()
																	.split('T')[0]
															: ''
													}
													onChange={(event) => {
														setValue('hireDate', event.target.value);
													}}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="manager"
												>
													Manager
												</label>
												<input
													{...register('manager')}
													defaultValue={userSettings?.metadata?.manager}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.manager && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.manager.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="department"
												>
													Department
												</label>
												<input
													{...register('department')}
													defaultValue={userSettings?.metadata?.department}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.department && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.department.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="jobTitle"
												>
													Job Title
												</label>
												<input
													{...register('jobTitle')}
													defaultValue={currentUser?.metadata?.jobTitle}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.jobTitle && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.jobTitle.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="employeeId"
												>
													Employee ID
												</label>
												<input
													{...register('employeeId')}
													defaultValue={currentUser?.metadata?.employeeId}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.employeeId && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.employeeId.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="nameToPrintOnCheck"
												>
													Name to Print on Check
												</label>
												<input
													{...register('nameToPrintOnCheck')}
													defaultValue={
														currentUser?.metadata?.nameToPrintOnCheck
													}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.nameToPrintOnCheck && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.nameToPrintOnCheck.message}
													</p>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
							{sections.updateFullData && (
								<div className="user-information">
									{/* Emergency Contact */}
									<div className="user-information-block">
										<div className="content-block">
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="emergencyName"
												>
													Emergency Contact Name
												</label>
												<input
													{...register('emergencyName')}
													defaultValue={userSettings?.metadata?.emergencyName}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.emergencyName && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.emergencyName.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="emergencyPhone"
												>
													Emergency Contact Phone
												</label>
												<input
													{...register('emergencyPhone')}
													defaultValue={userSettings?.emergencyPhone}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.emergencyPhone && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.emergencyPhone.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="emergencyRelation"
												>
													Emergency Contact Relation
												</label>
												<input
													{...register('emergencyRelation')}
													defaultValue={
														currentUser?.metadata?.emergencyRelation
													}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.emergencyRelation && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.emergencyRelation.message}
													</p>
												)}
											</div>
											{/* <button type="submit" className="redBtn">
												Update Emergency Contact
											</button> */}
										</div>
									</div>
								</div>
							)}
							{sections.updateFullData && (
								<>
									{currentUser?.role === 'DEALER' ||
										(currentUser?.role === 'SALESMAN' && (
											<div className="user-information">
												<div className="user-information-block">
													<div className="content-block">
														<label htmlFor="commission" className="mr-2">
															Sales Commission
														</label>
														<input
															type="number"
															{...register('commission')}
															defaultValue={userSettings?.metadata?.commission}
															className="shadow appearance-none border rounded w-16 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
															placeholder={userSettings?.metadata?.commission}
														/>{' '}
														%
														{errors.commission && (
															<p className="text-redColor-500 text-s italic p-2">
																{errors.commission.message}
															</p>
														)}
													</div>
												</div>
											</div>
										))}
								</>
							)}
							{sections.updateFullData && (
								<>
									{currentUser?.role === 'INSTALLER' && (
										<div className="user-information">
											<div className="user-information-block">
												<div className="content-block">
													<label htmlFor="commission" className="mr-2">
														Percentage for Standard and Horizontal Roof
													</label>
													<input
														type="number"
														{...register('hRoof')}
														className="shadow appearance-none border rounded w-16 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
														placeholder={userSettings?.metadata?.hRoof}
													/>
													{errors.commission && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors.commission.message}
														</p>
													)}
													<hr />
													<label htmlFor="commission" className="mr-2">
														Percentage for Vertical Roof
													</label>
													<input
														type="number"
														{...register('vRoof')}
														className="shadow appearance-none border rounded w-16 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
														placeholder={userSettings?.metadata?.vRoof}
													/>
													{errors.commission && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors.commission.message}
														</p>
													)}
												</div>
											</div>
										</div>
									)}

									<div className="w-full">
										<button type="submit" className="redBtn">
											Update Data
										</button>
									</div>
								</>
							)}
						</form>

						{/* upload documents only if the user is and superadmin or an admin */}
						{selftAccount === 'SUPER' || selftAccount === 'ADMIN' ? (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('uploadDocs')}
									>
										<h2 className="text-xl font-bold mb-4">Upload Documents</h2>
										<button>
											{sections.uploadDocs ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.uploadDocs && (
										<div className="content-block">
											<form
												onSubmit={handleSubmit(uploadDocument)}
												className=" px-8 pb-8 mb-4"
											>
												<div className="mb-4">
													<label
														className="block text-gray-700 text-s font-bold mb-2"
														htmlFor={``}
													>
														Document Title
													</label>
													<input
														type="text"
														{...register(`title`, {
															required: 'Document Title is required',
														})}
														className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
													/>
													{errors.documentTitle && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors.documentTitle.message}
														</p>
													)}

													<label
														className="block text-gray-700 text-s font-bold mb-2"
														htmlFor={`document`}
													>
														Upload Document
													</label>
													<input
														type="file"
														onChange={(e) => {
															const file = e.target.files[0];
															fileUploadHandler(file);
														}}
														className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
													/>
													{errors.document && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors.document.message}
														</p>
													)}
												</div>

												{progress.started && (
													<div className="progress-bar">
														<div
															className="progress-bar-fill"
															style={{ width: `${progress.pc}%` }}
														>
															{progress.msg}
														</div>
													</div>
												)}
												<button type="submit" className="redBtn">
													Upload Document
												</button>
											</form>
											{messageDoc && <div>{messageDoc}</div>}
										</div>
									)}
								</div>
							</div>
						) : (
							''
						)}
						{selftAccount === 'SUPER' || selftAccount === 'ADMIN' ? (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('uploadPaymentsfiles')}
									>
										<h2 className="text-xl font-bold mb-4">Upload Payments</h2>
										<button>
											{sections.uploadPaymentsfiles ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.uploadPaymentsfiles && (
										<div className="content-block">
											<form
												onSubmit={handleSubmit(uploadPayments)}
												className=" px-8 pb-8 mb-4"
											>
												<div className="mb-4">
													<label
														className="block text-gray-700 text-s font-bold mb-2"
														htmlFor={``}
													>
														Payment Description / Title
													</label>
													<input
														type="text"
														{...register(`title`, {
															required: 'Document Title is required',
														})}
														className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
													/>
													{errors.documentTitle && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors.documentTitle.message}
														</p>
													)}

													<label
														className="block text-gray-700 text-s font-bold mb-2"
														htmlFor={`document`}
													>
														Upload Document
													</label>
													<input
														type="file"
														onChange={(e) => {
															const file = e.target.files[0];
															fileUploadHandler(file);
														}}
														className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
													/>
													{errors.document && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors.document.message}
														</p>
													)}
												</div>

												{progress.started && (
													<div className="progress-bar">
														<div
															className="progress-bar-fill"
															style={{ width: `${progress.pc}%` }}
														>
															{progress.msg}
														</div>
													</div>
												)}
												<button type="submit" className="redBtn">
													Upload Payments
												</button>
											</form>
											{message && <div>{message}</div>}
										</div>
									)}
								</div>
							</div>
						) : (
							''
						)}
					</>
				) : (
					<>
						{/* View Mode */}
						{/* General Information */}

						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('generalInfo')}
								>
									<h2 className="text-xl font-bold mb-4">
										General Information
									</h2>
									<button>
										{sections.generalInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.generalInfo && (
									<div className="content-block">
										{currentUser?.profilePicture && (
											<div className="profilePict">
												<img src={currentUser?.profilePicture} alt="profile" />
											</div>
										)}
										<p className="user-information-value">
											<strong>First Name:</strong> {currentUser?.firstName}
										</p>
										<p className="user-information-value">
											<strong>Last Name:</strong> {currentUser?.lastName}
										</p>
										<p className="user-information-value">
											<strong>Email:</strong> {currentUser?.email}{' '}
											{console.log(currentUser?.email, 'email')}
										</p>
										<p className="user-information-value">
											<strong>Phone Number:</strong> {currentUser?.phoneNumber}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Personal Information */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('personalInfo')}
								>
									<h2 className="text-xl font-bold mb-4">
										Personal Information
									</h2>
									<button>
										{sections.personalInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.personalInfo && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Address:</strong>{' '}
											{userSettings?.metadata?.address
												? userSettings?.metadata?.address
												: ''}
										</p>
										<p className="user-information-value">
											<strong>City:</strong>{' '}
											{userSettings?.metadata?.city
												? userSettings?.metadata?.city
												: ''}
										</p>
										<p className="user-information-value">
											<strong>State:</strong>{' '}
											{userSettings?.metadata?.state
												? userSettings?.metadata?.state
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Zip:</strong>{' '}
											{userSettings?.metadata?.zip
												? userSettings?.metadata?.zip
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Birth Date:</strong>{' '}
											{userSettings?.metadata?.birthDate
												? userSettings?.metadata?.birthDate
												: ''}
										</p>
										<p className="user-information-value">
											<strong
												style={{
													textTransform: 'capitalize',
												}}
											>
												Gender:
											</strong>{' '}
											{userSettings?.metadata?.gender
												? userSettings?.metadata?.gender
												: ''}
										</p>
										<p className="user-information-value">
											<strong>SSN:</strong> {maskedSSN ? maskedSSN : ''}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Employee Details */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('employeeInfo')}
								>
									<h2 className="text-xl font-bold mb-4">Employee Details</h2>
									<button>
										{sections.employeeInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.employeeInfo && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Hire Date:</strong>{' '}
											{userSettings?.metadata?.hireDate
												? userSettings?.metadata?.hireDate
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Manager:</strong>{' '}
											{userSettings?.metadata?.manager
												? userSettings?.metadata?.manager
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Department:</strong>{' '}
											{userSettings?.metadata?.department
												? userSettings?.metadata?.department
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Job Title:</strong>{' '}
											{userSettings?.metadata?.jobTitle
												? userSettings?.metadata?.jobTitle
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Employee ID:</strong>{' '}
											{userSettings?.metadata?.employeeId
												? userSettings?.metadata?.employeeId
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Name to Print on Check:</strong>{' '}
											{userSettings?.metadata?.nameToPrintOnCheck
												? userSettings?.metadata?.nameToPrintOnCheck
												: ''}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Emergency Contact */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('emergencyContact')}
								>
									<h2 className="text-xl font-bold mb-4">Emergency Contact</h2>
									<button>
										{sections.emergencyContact ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.emergencyContact && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Contact Name:</strong>{' '}
											{userSettings?.metadata?.emergencyName
												? userSettings?.metadata?.emergencyName
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Contact Phone:</strong>{' '}
											{userSettings?.metadata?.emergencyPhone
												? userSettings?.metadata?.emergencyPhone
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Emergency Contact Relation:</strong>{' '}
											{userSettings?.metadata?.emergencyRelation
												? userSettings?.metadata?.emergencyRelation
												: ''}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Update Role */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('updateRole')}
								>
									<h2 className="text-xl font-bold mb-4">Role</h2>
									<button>
										{sections.updateRole ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.updateRole && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Role:</strong>{' '}
											{currentUser?.role
												? currentUser?.role
												: 'No role assigned'}
										</p>
									</div>
								)}
							</div>
						</div>

						{/* Update Commissions */}
						{currentUser?.role === 'DEALER' ||
							(currentUser?.role === 'SALESMAN' && (
								<div className="user-information">
									<div className="user-information-block">
										<div
											className="title-block"
											onClick={() => toggleSection('updateCommision')}
										>
											<h2 className="text-xl font-bold mb-4">Commissions</h2>
											<button>
												{sections.updateCommision ? (
													<ChevronDown size={24} />
												) : (
													<ChevronRight size={24} />
												)}
											</button>
										</div>
										{sections.updateCommision && (
											<div className="content-block">
												<p className="user-information-value">
													<strong>Commission:</strong>{' '}
													{userSettings?.metadata?.commission
														? `${userSettings?.metadata?.commission}%`
														: 'Not set'}
												</p>
											</div>
										)}
									</div>
								</div>
							))}
						{currentUser?.role === 'INSTALLER' && (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('updateCommision')}
									>
										<h2 className="text-xl font-bold mb-4">Commissions</h2>
										<button>
											{sections.updateCommision ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.updateCommision && (
										<div className="content-block">
											<p className="user-information-value">
												<strong>Horizontal Roof:</strong>{' '}
												{userSettings?.metadata?.hRoof
													? `${userSettings?.metadata?.hRoof}%`
													: 'Not set'}
											</p>
											<p className="user-information-value">
												<strong>Vertical Roof:</strong>{' '}
												{userSettings?.metadata?.vRoof
													? `${userSettings?.metadata?.vRoof}%`
													: 'Not set'}
											</p>
										</div>
									)}
								</div>
							</div>
						)}
						{/* when the current user is a admin you can see the documents uploaded  */}
						{selftAccount === 'SUPER' && (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('documents')}
									>
										<h2 className="text-xl font-bold mb-4">Documents</h2>
										<button>
											{sections.documents ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.documents && (
										<div className="content-block-documents">
											{userDocuments
												?.filter((doc) => doc?.type === 0)
												.map((doc, index) => (
													<div key={index} className="btn-download-file-list">
														{doc.title}
														<button
															type="button"
															onClick={() => onDeleteDocument(doc.id)}
														>
															<Trash2 size={24} />
														</button>

														<a
															href={doc.filePath}
															target="_blank"
															rel="noreferrer"
															// className="btn-download-file"
														>
															<Download size={24} />
														</a>
													</div>
												))}
										</div>
									)}
								</div>
							</div>
						)}

						{selftAccount === 'SUPER' && (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('payments')}
									>
										<h2 className="text-xl font-bold mb-4">Payments List</h2>
										<button>
											{sections.payments ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.payments && (
										<table className="content-block table-auto w-full">
											<thead>
												<tr>
													<th className="w-3/4 px-4 py-2">Title/Description</th>
													<th className="w-1/4 px-4 py-2">Download</th>
													<th className="w-1/4 px-4 py-2">Delete</th>
												</tr>
											</thead>
											<tbody>
												{userDocuments
													?.filter((doc) => doc?.type === 1)
													.map((doc, index) => (
														<tr key={index} className="border-t">
															{doc.title}
															<td className="px-4 py-2">
																{' '}
																<button
																	type="button"
																	onClick={() => onDeleteDocument(doc.id)}
																>
																	<Trash2 size={24} />
																</button>
															</td>

															<td className="px-4 py-2">
																{' '}
																<a
																	href={doc.filePath}
																	target="_blank"
																	rel="noreferrer"
																	// className="btn-download-file"
																>
																	<Download size={24} />
																</a>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									)}
								</div>
							</div>
						)}

						<div className="user-information">
							{/* Delete User */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('deleteUser')}
								>
									<h2 className="text-xl font-bold mb-4">Delete User</h2>
									<button>
										{sections.deleteUser ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.deleteUser && (
									<div className="content-block">
										<form onSubmit={handleSubmit(onDeleteSubmit)}>
											<input
												{...register('id')}
												type="text"
												className="hidden"
												defaultValue={currentUser?.id}
											/>
											<button type="submit" className="redBtn">
												Delete User
											</button>
										</form>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</aside>
		</div>
	);
}
