import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ChevronDown, ChevronRight, X, Edit2, Trash2 } from 'react-feather';
import {
	fetchMaterialById,
	fetchMaterials,
	updateMaterial,
	deleteMaterial,
	fetchCategories,
} from '../../slices/materialsSlice';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function PricingDetails({ onClose, materialId }) {
	const dispatch = useDispatch();
	const material = useSelector((state) => state.materials.material);
	const categories = useSelector((state) => state.materials.categories.items);
	const [selectedUnit, setSelectedUnit] = useState('');
	const [editFrom, setEditForm] = useState(true);
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	// Toggle sections
	const [sections, setSections] = useState({
		generalTab: false,
		measureTab: false,
		priceTab: false,
		statusTab: false,
	});

	const toggleSection = (section) => {
		setSections((prevSections) => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	const formattedCreatedAt = new Date(material?.createdAt).toLocaleString(
		'en-US',
		{
			month: '2-digit',
			day: '2-digit',
			year: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}
	);
	const formattedUpdatedAt = new Date(material?.updatedAt).toLocaleString(
		'en-US',
		{
			month: '2-digit',
			day: '2-digit',
			year: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}
	);

	const currentPage = 1;
	const pageSize = 1000;

	const onSubmit = async (data) => {
		await dispatch(
			updateMaterial({
				id: materialId,
				name: data.name,
				description: data.description,
				// unitPrice: Number(data.unitPrice),
				defaultPrice: Number(data.defaultPrice),
				measurementUnit: selectedUnit,
				categoryId: Number(data.categoryId),
				width: Number(data.width),
				height: Number(data.height),
				length: Number(data.length) || 0, // Assuming 0 if length is empty or not provided
				prices: {
					OR: Number(data.oregonPrice),
					NV: Number(data.nevadaPrice),
				},
				// quantity: Number(data.quantity),
				// stockLimit: data.stockLimit,
			})
		);

		dispatch(fetchMaterials({ pageSize, page: currentPage }));
		onClose();
	};

	useEffect(() => {
		dispatch(fetchMaterialById(materialId));
		dispatch(fetchCategories());
	}, [dispatch, materialId]);

	useEffect(() => {
		if (material) {
			Object.keys(material).forEach((field) => {
				setValue(field, material[field]);
			});
		}
		if (material?.prices) {
			setValue('oregonPrice', material.prices.OR);
			setValue('nevadaPrice', material.prices.NV);
		}
		if (material?.measurementUnit) {
			setSelectedUnit(material.measurementUnit);
		}
	}, [material, setValue]);

	const handleDelete = (id) => {
		dispatch(deleteMaterial(id));
		dispatch(fetchMaterials({ pageSize, page: currentPage }));
		onClose();
	};

	return (
		<div className="details-tab-panel">
			<aside className="details-panel">
				<div className="details-number">
					<h2 className="details-number-title">Items and stock</h2>

					<div className="block-right">
						<button
							onClick={() => handleDelete(material?.id)}
							className="btn-delete-item"
						>
							Delete Item <Trash2 size={20} />
						</button>
						<button
							onClick={() => setEditForm(!editFrom)}
							className="btn-edit-item"
						>
							{editFrom ? (
								<>
									Edit Details <Edit2 size={20} />
								</>
							) : (
								<>Cancel</>
							)}
						</button>
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>

				<div className="p-4"></div>

				{editFrom ? (
					<>
						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('generalTab')}
							>
								<h2 className="title-h2">General</h2>
								<strong>
									{sections.generalTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>
							{sections.generalTab && (
								<>
									<div className="field-section-half">
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Name</strong> {material?.name}
											</p>
										</div>
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Measurement Unit</strong>{' '}
												{material?.measurementUnit}
											</p>
										</div>
									</div>
									<hr className="mb-5" />
									<div className="field-section-full">
										<p className="p-tab-info">
											<strong>Description</strong> {material?.description}
										</p>
									</div>
									<hr className="mb-5" />
									<div className="field-section-half">
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Category</strong> {material?.categoryName}
											</p>
										</div>
										{/* <div className="inp-col">
											<p className="p-tab-info">
												<strong>Quantity</strong> {material?.quantity}
											</p>
										</div> */}
									</div>
									<hr className="mb-5" />
									<div className="field-section-half">
										{/* <div className="inp-col">
											<p className="p-tab-info">
												<strong>In Stock</strong> {material?.stockLimit}
											</p>
										</div> */}
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Color</strong> {material?.color}
											</p>
										</div>
									</div>
								</>
							)}
						</div>

						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('measureTab')}
							>
								<h2 className="title-h2">Measure</h2>
								<strong>
									{sections.measureTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>
							{sections.measureTab && (
								<>
									<div className="field-section-half">
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Width</strong> {material?.width}{' '}
												{material?.measurementUnit}
											</p>
										</div>
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Length</strong> {material?.length}{' '}
												{material?.measurementUnit}
											</p>
										</div>
									</div>
									<hr className="mb-5" />
									<div className="field-section-one-half">
										<p className="p-tab-info">
											<strong>Height</strong> {material?.height}{' '}
											{material?.measurementUnit}
										</p>
									</div>
								</>
							)}
						</div>

						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('priceTab')}
							>
								<h2 className="title-h2">Price</h2>
								<strong>
									{sections.priceTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>
							{sections.priceTab && (
								<div className="content-block">
									{/* <div className="field-section-one-half">
										<p className="p-tab-info">
											<strong>Price per Unit</strong>{' '}
											{material?.unitPrice.toFixed(2)}
										</p>
									</div> */}
									<hr className="mb-5" />
									<div className="field-section-half">
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>California Price</strong>{' '}
												{material?.defaultPrice &&
													'$' + (material?.defaultPrice).toFixed(2)}
											</p>
										</div>
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Oregon Price</strong>{' '}
												{material?.prices?.OR &&
													'$' + (material?.prices?.OR).toFixed(2)}
											</p>
										</div>
									</div>
									<hr className="mb-5" />
									<div className="field-section-one-half">
										<p className="p-tab-info">
											<strong>Nevada Price</strong>{' '}
											{material?.prices?.NV &&
												'$' + (material?.prices?.NV).toFixed(2)}
										</p>
									</div>
								</div>
							)}
						</div>

						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('statusTab')}
							>
								<h2 className="title-h2">Status</h2>
								<strong>
									{sections.statusTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>
							{sections.statusTab && (
								<div className="content-block">
									<div className="field-section-half">
										<div className="inp-col">
											<p className="p-tab-info">
												<strong>Created At</strong> {formattedCreatedAt}
											</p>
										</div>
										<div className="inp-col">
											<p className="p-tab-active">
												<strong>Status</strong>
												{material?.StockLimit === 0 ||
												material?.active === false ? (
													<>
														<div className="w-5 h-5 rounded-full bg-redColor-400 mr-2"></div>{' '}
														Inactive
													</>
												) : (
													<>
														<div className="w-5 h-5 rounded-full bg-greenColor-400 mr-2"></div>{' '}
														Active
													</>
												)}
											</p>
										</div>
									</div>
									<hr className="mb-5" />
									<div className="field-section-one-half">
										<p className="p-tab-info">
											<strong>Updated At</strong>{' '}
											{formattedUpdatedAt ? formattedUpdatedAt : 'N/A'}
										</p>
									</div>
								</div>
							)}
						</div>
					</>
				) : (
					<form onSubmit={handleSubmit(onSubmit)} className="px-8 pb-8 mb-4">
						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('generalTab')}
							>
								<h2 className="title-h2">General</h2>
								<strong>
									{sections.generalTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>

							{sections.generalTab && (
								<div className="content-block">
									<div className="field-section-full">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Name
										</label>
										<input
											{...register('name', { required: 'Name is required' })}
											className="reg-input"
										/>
										{errors.name && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.name.message}
											</p>
										)}
									</div>
									<hr className="mb-5" />

									<div className="field-section-full">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Description
										</label>
										<textarea
											{...register('description')}
											className="reg-textarea"
										/>
										{errors.description && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.description.message}
											</p>
										)}
									</div>

									<hr className="mb-5" />

									<div className="field-section-half">
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Unit
											</label>
											<select
												value={selectedUnit}
												onChange={(e) => setSelectedUnit(e.target.value)}
												className="hover:cursor-pointer text-gray-500 active:text-black hover:text-black selection:text-black"
											>
												<option value="ft">
													ft (Foot) - Area coverage in imperial system
												</option>
												<option value="sqft">
													Sqft (Square Feet) - Area coverage in imperial system
												</option>
												<option value="sqm">
													Sqm (Square Meters) - Area coverage in metric system
												</option>
												<option value="lf">
													Lf (Linear Feet) - Length measurement in feet
												</option>
												<option value="m">
													M (Meters) - Length measurement in metric system
												</option>
												<option value="in">
													In (Inches) - Small length measurement in imperial
													system
												</option>
												<option value="kg">
													Kg (Kilograms) - Weight measurement in metric system
												</option>
												<option value="lb">
													Lb (Pounds) - Weight measurement in imperial system
												</option>
												<option value="ton">
													Ton - Heavy weight measurement (imperial or metric
													tons)
												</option>
												<option value="gallon">
													Gallon - Liquid volume measurement in imperial system
												</option>
												<option value="liter">
													Liter - Liquid volume measurement in metric system
												</option>
												<option value="other">
													Other - For unspecified measurements
												</option>
											</select>

											{errors.measurementUnit && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.measurementUnit.message}
												</p>
											)}
										</div>
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Category
											</label>

											<select
												{...register('categoryId')}
												className="text-gray-500 active:text-black hover:text-black hover:cursor-pointer selection:text-black"
											>
												{categories?.map((category) => (
													<option key={category.id} value={category.id}>
														{category.name}
													</option>
												))}
											</select>
											{errors.categoryId && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.categoryId.message}
												</p>
											)}
										</div>
									</div>

									<hr className="mb-5" />

									<div className="field-section-half">
										{/* <div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												In stock
											</label>
											<input
												type="number"
												{...register('stockLimit', {
													required: 'Number is required',
													valueAsNumber: true,
												})}
												className="reg-input !w-36"
											/>
											{errors.stockLimit && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.stockLimit.message}
												</p>
											)}
										</div> */}

										{/* <div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Quantity
											</label>
											<input
												type="number"
												{...register('quantity', {
													required: 'Quantity is required',
													valueAsNumber: true,
												})}
												className="reg-input !w-36"
											/>
											{errors.quantity && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.quantity.message}
												</p>
											)}
										</div> */}
									</div>
									<hr className="mb-5" />

									<div className="field-section-one-half ">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Color
										</label>
										<input
											type="text"
											{...register('color')}
											className="reg-input !w-52"
										/>
										{errors.color && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.color.message}
											</p>
										)}
									</div>
								</div>
							)}
						</div>

						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('measureTab')}
							>
								<h2 className="title-h2">Measure</h2>
								<strong>
									{sections.measureTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>
							{sections.measureTab && (
								<div className="content-block">
									<div className="field-section-half">
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Width
											</label>
											<input
												{...register('width')}
												type="number"
												className="reg-input"
											/>
											{errors.width && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.width.message}
												</p>
											)}
										</div>
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Length
											</label>
											<input
												{...register('length')}
												type="number"
												className="reg-input"
											/>
											{errors.length && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.length.message}
												</p>
											)}
										</div>
									</div>
									<hr className="mb-5" />
									<div className="field-section-one-half">
										<label className="block text-gray-700 text-sm font-bold mb-2">
											Height
										</label>

										<input
											{...register('height')}
											type="number"
											className="reg-input !w-36"
										/>

										{errors.height && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors.height.message}
											</p>
										)}
									</div>
								</div>
							)}
						</div>

						<div className="tab-information">
							<div
								className="title-block"
								onClick={() => toggleSection('priceTab')}
							>
								<h2 className="title-h2">Prices</h2>
								<strong>
									{sections.priceTab ? (
										<ChevronDown size={24} />
									) : (
										<ChevronRight size={24} />
									)}
								</strong>
							</div>
							{sections.priceTab && (
								<div className="content-block">
									<div className="field-section-half">
										{/* <div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Price per Unit
											</label>
											<input
												type="number"
												step="0.01"
												{...register('unitPrice', {
													valueAsNumber: true,
												})}
												className="reg-input"
											/>
											{errors.unitPrice && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.unitPrice.message}
												</p>
											)}
										</div> */}
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												California
											</label>
											<input
												type="number"
												step="0.01"
												{...register('defaultPrice')}
												className="reg-input"
											/>
											{errors.defaultPrice && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.defaultPrice.message}
												</p>
											)}
										</div>
									</div>

									<hr className="mb-5" />

									<div className="field-section-half">
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Oregon
											</label>
											<input
												type="number"
												step="0.01"
												{...register('oregonPrice')}
												className="reg-input"
											/>
											{errors.oregonPrice && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.oregonPrice.message}
												</p>
											)}
										</div>
										<div className="inp-col">
											<label className="block text-gray-700 text-sm font-bold mb-2">
												Nevada
											</label>
											<input
												type="number"
												step="0.01"
												{...register('nevadaPrice')}
												className="reg-input"
											/>
											{errors.nevadaPrice && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.nevadaPrice.message}
												</p>
											)}
										</div>
									</div>
								</div>
							)}
						</div>

						<button type="submit" className="redBtn">
							Save
						</button>
					</form>
				)}
			</aside>
		</div>
	);
}
