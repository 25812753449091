// cookieService.js

import Cookies from 'js-cookie';

const options = {
	expires: 6000,
	// domain: '.bncaccess.com',
	domain: 'localhost',
	sameSite: 'strict',
	secure: true,
};

const cookieService = {
	set: (name, value) => {
		Cookies.set(name, encodeURIComponent(value), options);
	},
	getCookie: (name) => {
		const value = Cookies.get(name);
		return value ? decodeURIComponent(value) : null;
	},
	remove: (name) => {
		Cookies.remove(name, options);
	},
};

export default cookieService;
