import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderById, fetchOrders } from '../slices/ordersSlice';
import { fetchClientById, fetchClientsOrder } from '../slices/clientSlice';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
// import SummaryBtns from '../components/SummaryBtns';
import { Sliders, PlusSquare, File } from 'react-feather';
import OrderDetails from '../components/OrderDetails';
import Checklist from '../components/Checklist';
import NewOrderTemp from '../components/NewOrderTemp';
// import { formatPhoneNumber, removePhoneFormatting } from '../hooks/phoneFormat';

export default function Leads() {
	const dispatch = useDispatch();
	const orders = useSelector((state) => state?.orders?.items);
	// const workOrders = useSelector((state) => state.orders.items);
	const status = useSelector((state) => state.orders.status);
	const error = useSelector((state) => state.orders.error);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectDetials, setSelectDetails] = useState(false);
	const [newQuoteForm, setNewQuoteForm] = useState(false);
	// Search bar

	const [clientFirstName, setClientFirstName] = useState('');
	const [clientLastName, setClientLastName] = useState('');
	const [deliveryPostalCode, setDeliveryPostalCode] = useState('');
	const [pageSize, setPageSize] = useState(100);
	const [currentPage, setCurrentPage] = useState(1);

	// Toggle sections
	const [filterSection, setFilterSections] = useState({
		filterOptions: false,
	});

	useEffect(() => {
		dispatch(fetchOrders());
		dispatch(fetchClientsOrder());
	}, [dispatch]);

	if (status === 'loading') {
		return <div>Loading...</div>;
	}

	if (status === 'failed') {
		return <div>Error: {error?.message}</div>;
	}

	const openDetails = (orderId, clientId) => {
		setSelectedOrder(orderId);
		dispatch(fetchOrderById(orderId));
		dispatch(fetchClientById(clientId));
	};

	const openCheckList = (workOrders) => {
		setSelectDetails(workOrders);
	};

	const closeDetails = () => {
		setNewQuoteForm(null);
		localStorage.removeItem('clientId');
	};

	// Toggle sections

	const toggleSection = (filterSection) => {
		setFilterSections((prevSections) => ({
			...prevSections,
			[filterSection]: !prevSections[filterSection],
		}));
	};

	// Search filters

	const applyFilters = () => {
		if (clientFirstName || clientLastName || deliveryPostalCode) {
			dispatch(
				fetchOrders({
					clientFirstName: clientFirstName,
					clientLastName: clientLastName,
					deliveryPostalCode: deliveryPostalCode,
					pageSize: pageSize,
					page: currentPage,
					sortOrder: 'ASC',
				})
			);
		}
	};

	const resetFilters = () => {
		setClientFirstName('');
		setClientLastName('');
		setDeliveryPostalCode('');

		applyFilters(); // Fetch all materials without filters
		dispatch(fetchOrders());
	};

	const statusMapping = {
		1: 'Quote',
		2: 'Order set',
		3: 'Permits',
		4: 'Invoice sent',
		5: 'Installation',
		10: 'Done',
		6: 'Repair',
	};

	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					<h1>Leads</h1>
					<div className="searchbar">
						<div className="filters">
							<button onClick={() => toggleSection('filterOptions')}>
								All filters <Sliders size={24} />
							</button>
						</div>
					</div>
					{/* Filters options going here */}
					{filterSection.filterOptions && (
						<div className="filters-options mt-10">
							<input
								type="text"
								placeholder="Filter by name..."
								value={clientFirstName}
								onChange={(e) => setClientFirstName(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Filter by last name..."
								value={clientLastName}
								onChange={(e) => setClientLastName(e.target.value)}
							/>

							<input
								type="text"
								placeholder="Filter by postal code..."
								value={deliveryPostalCode}
								onChange={(e) => setDeliveryPostalCode(e.target.value)}
							/>

							<input
								type="number"
								placeholder="Page size..."
								value={pageSize}
								onChange={(e) => setPageSize(e.target.value)}
							/>
							<input
								type="number"
								placeholder="Current page..."
								value={currentPage}
								onChange={(e) => setCurrentPage(e.target.value)}
							/>
							<button onClick={applyFilters} className="filter-btn">
								Search
							</button>
							<button onClick={resetFilters} className="filter-btn">
								Reset Inputs
							</button>
						</div>
					)}

					<div className="dashboard-content">
						<div className="action-btns">
							<button
								className="btn-new-user"
								onClick={() => setNewQuoteForm(true)}
							>
								Start a new Quote <PlusSquare size={24} />
							</button>
						</div>
						<table className="min-w-full">
							<thead>
								<tr>
									<th>Name</th>
									<th>Date</th>
									<th>Order ID</th>
									<th>Status</th>
									<th>Address</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody>
								{orders
									.filter((order) => order.status === 1)
									.map((order) => (
										<tr key={order.id} className="select-by-name">
											<td
												onClick={() => {
													openDetails(order.id, order.clientId);
												}}
											>
												{order.clientName}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{new Date(order.createdAt).toLocaleString('en-US', {
													month: '2-digit',
													day: '2-digit',
													year: '2-digit',
													// hour: '2-digit',
													// minute: '2-digit',
												})}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{order.reference}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{statusMapping[order.status]}
											</td>
											<td onClick={() => openDetails(order.id)}>
												{order.clientEmail}
											</td>
											<td>
												<button
													// onClick={() => openCheckList(workOrders)}
													onClick={() => openDetails(order.id, order.clientId)}
													// className="btn-details"
												>
													<File size={24} />
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
						{selectedOrder && (
							<OrderDetails
								order={selectedOrder}
								onClose={() => setSelectedOrder(null)}
							/>
						)}
						{selectDetials && (
							<Checklist
								workOrders={selectDetials}
								onClose={() => setSelectDetails(null)}
							/>
						)}
						{newQuoteForm && <NewOrderTemp onClose={() => closeDetails()} />}
					</div>
				</main>
			</div>
		</div>
	);
}
