import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderById, fetchOrders } from '../slices/ordersSlice';
import { fetchClientById } from '../slices/clientSlice';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import SummaryBtns from '../components/SummaryBtns';
import { Search, Sliders, List } from 'react-feather';
import OrderDetails from '../components/OrderDetails';
import Checklist from '../components/Checklist';

export default function Repairs() {
	const dispatch = useDispatch();
	const orders = useSelector((state) => state.orders.items);
	const workOrders = useSelector((state) => state.orders.items);
	const status = useSelector((state) => state.orders.status);
	const error = useSelector((state) => state.orders.error);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectDetials, setSelectDetails] = useState(false);

	useEffect(() => {
		dispatch(fetchOrders());
	}, [dispatch]);

	if (status === 'loading') {
		return <div>Loading...</div>;
	}

	if (status === 'failed') {
		return <div>Error: {error}</div>;
	}

	const openDetails = (orderId, clientId) => {
		setSelectedOrder(orderId);
		dispatch(fetchOrderById(orderId));
		dispatch(fetchClientById(clientId));
	};

	const openCheckList = (workOrders) => {
		setSelectDetails(workOrders);
	};

	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1 overflow-hidden">
				<Sidebar />
				<main className="flex-1 overflow-auto p-8">
					{/* <SummaryBtns /> */}
					<div className="searchbar">
						<div className="filters">
							<button>
								All filters <Sliders size={24} />
							</button>
						</div>
						<div className="search-input">
							<Search size={16} />
							<input type="text" placeholder="Search" />
						</div>
					</div>
					<div className="dashboard-content">
						<table className="min-w-full">
							<thead>
								<tr>
									<th>Name</th>
									<th>Date</th>
									<th>Order ID</th>
									<th>Status</th>
									<th>Address</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order) => {
									return (
										order.status === 6 && (
											<tr key={order.id} className="select-by-name">
												<td
													onClick={() => openDetails(order.id, order.clientId)}
												>
													{order.clientName}
												</td>
												<td onClick={() => openDetails(order.id)}>
													{new Date(order?.createdAt).toLocaleString('en-US', {
														month: '2-digit',
														day: '2-digit',
														year: '2-digit',
														hour: '2-digit',
														minute: '2-digit',
													})}
												</td>
												<td onClick={() => openDetails(order.id)}>
													{order.reference}
												</td>
												<td onClick={() => openDetails(order.id)}>
													{order.status === 1 ? 'Active' : 'Inactive'}
												</td>
												<td onClick={() => openDetails(order.id)}>
													{order.clientEmail}
												</td>
												<td>
													<button
														onClick={() => openCheckList(workOrders)}
														className="btn-details"
													>
														Checklist <List size={24} />
													</button>
												</td>
											</tr>
										)
									);
								})}
							</tbody>
						</table>
						{selectedOrder && (
							<OrderDetails
								order={selectedOrder}
								onClose={() => setSelectedOrder(null)}
							/>
						)}
						{selectDetials && (
							<Checklist
								workOrders={selectDetials}
								onClose={() => setSelectDetails(null)}
							/>
						)}
					</div>
				</main>
			</div>
		</div>
	);
}
