import { useSelector } from 'react-redux';
import { ChevronDown, ChevronRight, FilePlus, List } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toggleSection } from './../../hooks/toggleSection';

export default function CreateNewClient({
	handleExistingClient,
	setExistingClient,
	createNewClient,
	existingClient,
	sections,
	setSections,
}) {
	const clients = useSelector((state) => state?.clients.items.items);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	return (
		<div className="tab-information">
			<div className="tab-information-block">
				<div
					className="title-block"
					onClick={(e) => toggleSection('client', setSections, e)}
				>
					<h2 className="title-h2">Client Information</h2>
					<button>
						{sections.client ? (
							<ChevronDown size={24} />
						) : (
							<ChevronRight size={24} />
						)}
					</button>
				</div>
				{sections.client && (
					<>
						<div className="btns-selection">
							<div className="action-btns">
								<button
									className="btn-tab-selection"
									onClick={() => setExistingClient(true)}
								>
									Existing Client <List size={24} />
								</button>
							</div>
							<div className="action-btns">
								<button
									className="btn-tab-selection"
									onClick={() => setExistingClient(false)}
								>
									Create a new client <FilePlus size={24} />
								</button>
							</div>
						</div>
						{existingClient ? (
							<>
								<div className="field-section-half">
									<div className="inp-col">
										<label
											className="block text-gray-700 text-sm font-bold mb-2"
											htmlFor="client"
										>
											Select Client
										</label>
										<select
											{...register('client', {
												required: 'Client is required',
											})}
											onChange={(e) => handleExistingClient(e.target.value)}
											className="select-input"
										>
											<option value="">Select Client</option>
											{clients
												?.slice()
												.sort((a, b) => a.firstName.localeCompare(b.firstName))
												.map((client) => (
													<option key={client.id} value={client.id}>
														{client.firstName} {client.lastName}
													</option>
												))}
										</select>
										{errors.client && (
											<p className="text-redColor-500 text-s italic p-2">
												{typeof errors.client.message === 'object'
													? JSON.stringify(errors.client.message)
													: errors.client.message}
											</p>
										)}
									</div>
								</div>
							</>
						) : (
							<form
								onSubmit={handleSubmit(createNewClient)}
								className="new-order-form"
							>
								<div className="field-section-half">
									<div className="inp-col">
										<label htmlFor="firstName">
											<strong>First Name</strong>
										</label>
										<input
											{...register('firstName', {
												required: 'First Name is required',
											})}
										/>
										{errors.firstName && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.firstName.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="lastName">
											<strong>Last Name</strong>
										</label>

										<input
											{...register('lastName', {
												required: 'Last Name is required',
											})}
										/>
										{errors.lastName && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.lastName.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="companyName">
											<strong>Company</strong>
										</label>
										<input
											{...register('companyName', {
												required: 'Company is required',
											})}
										/>
										{errors.company && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.company.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="email">
											<strong>Email</strong>
										</label>
										<input
											{...register('email', {
												required: 'Email is required',
											})}
										/>
										{errors.email && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.email.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="phoneNumber">
											<strong>Phone Number</strong>
										</label>
										<input
											placeholder="Phone Number"
											{...register('phoneNumber', {
												pattern: {
													value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
													message:
														'Phone number must be in the format 123-456-7890',
												},
											})}
										/>
										{errors.phoneNumber && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.phoneNumber.message}
											</p>
										)}
									</div>
									{/* <div className="inp-col">
													<label htmlFor="source">
														<strong>Source</strong>
													</label>
													<input
														{...register('source')}
														placeholder="Source"
													
													/>
													{errors.source && (
														<p className="text-redColor-500 text-s italic p-2">
															{errors?.source.message}
														</p>
													)}
												</div> */}
									<div className="inp-col">
										<label htmlFor="address">
											<strong>Address</strong>
										</label>
										<input
											{...register('address', {
												required: 'Address is required',
											})}
										/>
										{errors.address && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.address.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="city">
											<strong>City</strong>
										</label>
										<input
											{...register('city', {
												required: 'City is required',
											})}
										/>
										{errors.city && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.city.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="state">
											<strong>State</strong>
										</label>
										<input
											{...register('state', {
												required: 'State is required',
											})}
										/>
										{errors.state && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.state.message}
											</p>
										)}
									</div>
									<div className="inp-col">
										<label htmlFor="postalCode">
											<strong>Postal Code</strong>
										</label>
										<input
											{...register('postalCode', {
												required: 'Postal Code is required',
											})}
										/>
										{errors.postalCode && (
											<p className="text-redColor-500 text-s italic p-2">
												{errors?.postalCode.message}
											</p>
										)}
									</div>
								</div>
								<div className="w-full ">
									<button type="submit" className="redBtn">
										Create Client
									</button>
								</div>
							</form>
						)}
					</>
				)}
			</div>
		</div>
	);
}
