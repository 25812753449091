import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createUser } from '../../slices/userSlice';
import { ChevronDown, X } from 'react-feather';

export default function NewUser({ onClose }) {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		dispatch(
			createUser({
				email: data?.email,
				password: data?.password,
				firstName: data?.firstName,
				lastName: data?.lastName,
				phoneNumber: data?.phoneNumber,
				role: data?.role,
				metadata: {},
			})
		);
		onClose();
	};

	return (
		<div className="new-user-details-panel">
			<aside className="details-panel">
				<div className="details-number">
					<h1>Create a New User</h1>
					<div className="block-right">
						<button onClick={onClose} className="close-btn">
							<X size={24} />
						</button>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className=" px-8 pb-8 mb-4">
					<div className="mb-4">
						<label
							className="block text-gray-700 text-sm font-bold mb-2"
							for="Email"
						>
							Email
						</label>
						<input
							{...register('email', { required: 'Email is required' })}
							placeholder="Email"
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
						/>
						{errors.email && (
							<p className="text-redColor-500 text-s italic p-2">
								{errors.email.message}
							</p>
						)}
					</div>

					<div className="mb-4">
						<label
							className="block text-gray-700 text-sm font-bold mb-2"
							for="Password"
						>
							Password
						</label>

						<input
							{...register('password', { required: 'Password is required' })}
							placeholder="Password"
							type="password"
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
						/>
						{errors.password && (
							<p className="text-redColor-500 text-s italic p-2">
								{errors.password.message}
							</p>
						)}
					</div>
					<div className="mb-4">
						<label
							className="block text-gray-700 text-sm font-bold mb-2"
							for="firstName"
						>
							First Name
						</label>

						<input
							{...register('firstName', { required: 'First Name is required' })}
							placeholder="First Name"
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
						/>
						{errors.firstName && (
							<p className="text-redColor-500 text-s italic p-2">
								{errors.firstName.message}
							</p>
						)}
					</div>
					<div className="mb-4">
						<label
							className="block text-gray-700 text-sm font-bold mb-2"
							for="lastName"
						>
							Last Name
						</label>

						<input
							{...register('lastName', { required: 'Last Name is required' })}
							placeholder="Last Name"
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
						/>
						{errors.lastName && (
							<p className="text-redColor-500 text-s italic p-2">
								{errors.lastName.message}
							</p>
						)}
					</div>
					<div className="mb-4">
						<label
							className="block text-gray-700 text-sm font-bold mb-2"
							for="phoneNumber"
						>
							Phone Number
						</label>

						<input
							placeholder="Phone Number"
							{...register('phoneNumber', {
								pattern: {
									value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
									message: 'Phone number must be in the format 123-456-7890',
								},
							})}
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
						/>
						{errors.phoneNumber && (
							<p className="text-redColor-500 text-s italic p-2">
								{errors.phoneNumber.message}
							</p>
						)}
					</div>
					<div className="mb-4">
						<label
							className="block text-gray-700 text-s font-bold mb-2"
							for="role"
						>
							Role
						</label>
						<div className="inline-block relative w-full">
							<select
								{...register('role', { required: 'Role is required' })}
								className="block appearance-none w-full bg-white border  hover:border-gray-500 hover:cursor-pointer px-4 py-2  rounded shadow leading-tight focus:outline-none focus:shadow-outline"
							>
								<option value="">Select Role</option>
								<option value="INSTALLER">Installer</option>
								<option value="SALESMAN">Dealer</option>
								<option value="EMPLOYEE">Employee</option>
								<option value="ADMIN">Admin</option>
								<option value="SUPER">Super</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<ChevronDown size={24} />
							</div>
						</div>
						{errors.role && (
							<p className="text-redColor-500 text-s italic p-2">
								{errors.role.message}
							</p>
						)}
					</div>

					<button type="submit" className="redBtn">
						Create User
					</button>
				</form>
			</aside>
		</div>
	);
}
