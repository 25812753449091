import { useEffect, useState, useRef } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginForm from './components/Login';
import Dashboard from './pages/Dashboard';
import Billing from './pages/Billing';
import jwService from './services/jwService';
import Inventory from './pages/Inventory';
import Leads from './pages/Leads';
import Orders from './pages/Orders';
import Clients from './pages/Clients';
import Settings from './pages/Settings';
import Clockin from './pages/Clockin';
import Repairs from './pages/Repairs';
import Pricing from './pages/Pricing';
import cookieService from './services/cookieService';
import UserProfile from './pages/UserProfile';
import Installation from './pages/Installation';

function App() {
	const token =
		useSelector((state) => state.auth?.token) || localStorage.getItem('token');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const navigate = useNavigate();
	const inactivityTimer = useRef(null);

	useEffect(() => {
		const cookieToken = cookieService.getCookie('_bnc');
		setIsAuthenticated(!!cookieToken || !!token);
		if (token) {
			jwService.storeToken({
				token: token,
				expires: 3600,
			});
		}
	}, [token]);

	// after 30 minutes without activity, the user will be logged out
	useEffect(() => {
		if (isAuthenticated) {
			// Clear the existing timer
			if (inactivityTimer.current) clearTimeout(inactivityTimer.current);

			// Start a new timer
			inactivityTimer.current = setTimeout(() => {
				// Redirect to login page after 30 minutes of inactivity
				navigate('/');
			}, 1800000); // 30 minutes
		}

		// Clear the timer when the component unmounts
		return () => {
			if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
		};
	}, [isAuthenticated, navigate]);

	// Clear and restart the timer whenever there is user activity
	document.onmousemove = document.onkeydown = () => {
		if (inactivityTimer.current) clearTimeout(inactivityTimer.current);

		// Restart the timer
		inactivityTimer.current = setTimeout(() => {
			// remove the token from the store and cookies
			localStorage.removeItem('token');
			// Redirect to login page after 30 minutes of inactivity
			navigate('/');
		}, 1800000); // 30 minutes
	};

	return (
		<div className="w-full">
			<Routes>
				<Route path="/" element={<LoginForm />} />
				<Route
					path="/dashboard"
					element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
				/>
				<Route
					path="/profile"
					element={isAuthenticated ? <UserProfile /> : <Navigate to="/" />}
				/>
				<Route
					path="/inventory"
					element={isAuthenticated ? <Inventory /> : <Navigate to="/" />}
				/>
				<Route
					path="/pricing"
					element={isAuthenticated ? <Pricing /> : <Navigate to="/" />}
				/>
				<Route
					path="/clients"
					element={isAuthenticated ? <Clients /> : <Navigate to="/" />}
				/>
				<Route
					path="/orders"
					element={isAuthenticated ? <Orders /> : <Navigate to="/" />}
				/>
				<Route
					path="/installations"
					element={isAuthenticated ? <Installation /> : <Navigate to="/" />}
				/>
				<Route
					path="/leads"
					element={isAuthenticated ? <Leads /> : <Navigate to="/" />}
				/>
				<Route
					path="/repairs"
					element={isAuthenticated ? <Repairs /> : <Navigate to="/" />}
				/>

				<Route
					path="/billing"
					element={isAuthenticated ? <Billing /> : <Navigate to="/" />}
				/>
				<Route
					path="/clock-in"
					element={isAuthenticated ? <Clockin /> : <Navigate to="/" />}
				/>

				<Route
					path="/settings"
					element={isAuthenticated ? <Settings /> : <Navigate to="/" />}
				/>
			</Routes>
		</div>
	);
}

export default App;
