import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
	fetchUsers,
	resetPassword,
	fetchUserById,
	fetchUserSetting,
	fetchUserDocuments,
} from '../slices/userSlice';
import { ChevronDown, ChevronRight, X, Edit2, Download } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateUser, fetchSelf } from '../slices/userSlice';
import { formatPhoneNumber } from '../hooks/phoneFormat';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import user_placeholder from '../assets/user_placeholder.png';
import { useNavigate } from 'react-router-dom';

export default function CurrentUserProfile({ currentProfile }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const api = process.env.REACT_APP_API_URL;

	const [editForm, setEditForm] = useState(false); // Toggle edit mode

	const [sections, setSections] = useState({
		// User details
		generalInfo: true,
		personalInfo: false,
		employeeInfo: false,
		emergencyContact: false,
		documents: false,
		payments: false,

		// Update user details
		updateFullData: false,
		updateGeneralInfo: true,
		updatePersonalInfo: false,
		updateEmployeeInfo: false,
		updateEmergencyContact: false,
		updateCommision: false,
		removeRole: false,
		updatePass: false,
	});

	// checking the user role
	const selftAccount = useSelector((state) => state?.auth?.self?.role);
	const currentUser = useSelector((state) => state?.auth?.self);

	const token = localStorage.getItem('token');

	useEffect(() => {
		// if this compone is loaded fetch self and fetch attendance after 15 min of inactivity
		dispatch(fetchUserById(currentProfile));
		dispatch(fetchSelf());
	}, [dispatch, token]);
	const toggleSection = (section) => {
		setSections((prevSections) => {
			// Close all sections
			const newSections = Object.keys(prevSections).reduce((sections, key) => {
				sections[key] = false;
				return sections;
			}, {});

			// Open the clicked section
			newSections[section] = !prevSections[section];

			return newSections;
		});
	};

	// useEffect(() => {
	// 	dispatch(fetchUserSetting());
	// }, [dispatch]);

	const userSettings = useSelector((state) => state.auth?.userSetting);
	const userDocuments = useSelector((state) => state.auth?.userDocuments.items);

	//url picture
	const [urlPicture, setUrlPicture] = useState('');

	const userId = currentUser?.id;

	useEffect(() => {
		const fetchDocuments = async () => {
			await dispatch(fetchUserDocuments({ userId }));
		};

		fetchDocuments();
	}, [dispatch, userId]);

	useEffect(() => {
		dispatch(fetchUserSetting(currentUser?.id));
	}, [dispatch, currentUser?.id]);

	// Form stuff
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm();

	// const onExtraInfo = (data) => {
	// 	// prevent send info if the form is not submited

	// 	if (!editForm) {
	// 		return;
	// 	}

	// 	const emergPhone = formatPhoneNumber(data.emergencyPhone);

	// 	dispatch(
	// 		userInfo({
	// 			id: currentUser?.id,
	// 			metadata: {
	// 				commission: data.commission,
	// 				hRoof: data.hRoof,
	// 				vRoof: data.vRoof,
	// 				// more basic user info
	// 				address: data?.address,
	// 				city: data?.city,
	// 				state: data?.state,
	// 				zip: data?.zip,
	// 				birthDate: data?.birthDate,
	// 				gender: data?.gender,
	// 				legalId: data?.legalId,
	// 				hireDate: data?.hireDate,
	// 				manager: data?.manager,
	// 				department: data?.department,
	// 				jobTitle: data?.jobTitle,
	// 				employeeId: data?.employeeId,
	// 				nameToPrintOnCheck: data?.nameToPrintOnCheck,
	// 				// vendor info
	// 				companyName: data?.companyName,
	// 				website: data?.website,
	// 				txId: data?.txId,
	// 				bankName: data?.bankName,
	// 				bankAccount: data?.bankAccount,
	// 				bankRouting: data?.bankRouting,
	// 				// emergency contact
	// 				emergencyName: data?.emergencyName,
	// 				emergencyPhone: emergPhone,
	// 				emergencyRelation: data?.emergencyRelation,
	// 				notes: data?.notes,
	// 			},
	// 		})
	// 	);

	// };

	const onSubmitUpdatePassword = (data) => {
		dispatch(
			resetPassword({
				email: currentUser?.email,
				password: data.password,
			})
		); // Pass only the new password, not the entire data object
		setEditForm(false);

		dispatch(fetchUserById(currentUser?.id));
		dispatch(fetchUserSetting(currentProfile));
	};

	// update User

	const onSubmitUpdateUser = (data) => {
		const { firstName, lastName, phoneNumber } = data;
		const formattedPhone = phoneNumber ? formatPhoneNumber(phoneNumber) : null;

		dispatch(
			updateUser({
				id: currentUser?.id,
				firstName,
				lastName,
				phoneNumber: formattedPhone,
				profilePicture: urlPicture,
			})
		);

		dispatch(fetchUserSetting(currentProfile));
		dispatch(fetchUserById(currentUser?.id));
		dispatch(fetchUsers());
		setEditForm(false);
		navigate('/');
	};

	// update role
	useEffect(() => {
		if (currentUser?.role) {
			setValue('role', currentUser.role);
		}
	}, [currentUser?.role, setValue]);

	// const [isLoading, setIsLoading] = useState(true);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 1500);
	// }, []);

	// if (isLoading) {
	// 	return <p>Loading...</p>;
	// }

	// here is the file url
	const [urlState, setUrlState] = useState('');
	// Uploading progress bar
	const [Msg, setMsg] = useState(null);
	const [progress, setProgress] = useState({ started: false, pc: 0, msg: '' });

	// pendding to clean up

	const profilePictureHandler = async (file) => {
		const token = localStorage.getItem('token');

		const formData = new FormData();
		if (file) {
			const newFile = new File([file], file.name.replace(/\s/g, '_'), {
				type: file.type,
			});

			formData.append('file', newFile);

			setMsg('Uploading...');
			setProgress({ started: true, pc: 0, msg: 'Uploading...' });
		}

		try {
			const response = await axios.post(`${api}/files/upload`, formData, {
				onUploadProgress: (progressEvent) => {
					const pc = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setProgress({ started: true, pc, msg: 'Uploading...' });
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			setUrlPicture(response.data.fileUrl);
			setMsg('File uploaded successfully!');
			setProgress({
				started: false,
				pc: 0,
				msg: 'File uploaded successfully ready to send or add more files!',
			});
		} catch (error) {
			setMsg('Error uploading file!');
		}
	};

	return (
		<div className="profilepage">
			<aside className="details-panel">
				<div className="details-number">
					<h1>My Account</h1>
					<div className="block-left"></div>
					<div className="block-right">
						<button
							onClick={() => setEditForm(!editForm)}
							className="btn-edit-item"
						>
							{editForm ? (
								<>Cancel</>
							) : (
								<>
									Edit Details <Edit2 size={20} />
								</>
							)}
						</button>
						{/* <button onClick={onClose} className="close-btn">
							<X size={24} />
						</button> */}
					</div>
				</div>

				{editForm ? (
					<>
						{/* Edit Mode */}
						{/* General Information */}
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('updateGeneralInfo')}
								>
									<h2 className="text-xl font-bold mb-4">
										General Information
									</h2>
									<button>
										{sections.updateGeneralInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.updateGeneralInfo && (
									<div className="content-block">
										<form onSubmit={handleSubmit(onSubmitUpdateUser)}>
											<div className="mb-4">
												<input
													type="file"
													onChange={(e) => {
														const file = e.target.files[0];
														profilePictureHandler(file);
													}}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.document && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.document.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="firstName"
												>
													First Name
												</label>
												<input
													{...register('firstName', {
														required: 'First Name is required',
													})}
													defaultValue={currentUser?.firstName}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.firstName && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.firstName.message}
													</p>
												)}
											</div>
											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="lastName"
												>
													Last Name
												</label>
												<input
													{...register('lastName', {
														required: 'Last Name is required',
													})}
													defaultValue={currentUser?.lastName}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.lastName && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.lastName.message}
													</p>
												)}
											</div>

											<div className="mb-4">
												<label
													className="block text-gray-700 text-s font-bold mb-2"
													htmlFor="phoneNumber"
												>
													Phone Number
												</label>
												<input
													{...register('phoneNumber')}
													defaultValue={currentUser?.phoneNumber}
													className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
												/>
												{errors.phoneNumber && (
													<p className="text-redColor-500 text-s italic p-2">
														{errors.phoneNumber.message}
													</p>
												)}
											</div>
											<button type="submit" className="redBtn">
												Update General Information
											</button>
										</form>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Update Password */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('updatePass')}
								>
									<h2 className="text-xl font-bold mb-4">Update Password</h2>
									<button>
										{sections.updatePass ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.updatePass && (
									<div className="content-block">
										<form
											onSubmit={handleSubmit(onSubmitUpdatePassword)}
											className=" px-8 pb-8 mb-4"
										>
											<input
												{...register('password', {
													required: 'Password is required',
													placeholder: 'New Password',
												})}
												type="password"
												className="shadow appearance-none border rounded inline-block relative w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-gray-500"
											/>
											{errors.password && (
												<p className="text-redColor-500 text-s italic p-2">
													{errors.password.message}
												</p>
											)}
											<button type="submit" className="redBtn">
												Update Password
											</button>
										</form>
									</div>
								)}
							</div>
						</div>
					</>
				) : (
					<>
						{/* View Mode */}
						{/* General Information */}

						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('generalInfo')}
								>
									<h2 className="text-xl font-bold mb-4">
										General Information
									</h2>
									<button>
										{sections.generalInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.generalInfo && (
									<div className="content-block">
										{currentUser?.profilePicture ? (
											<div className="profilePict">
												<img src={currentUser?.profilePicture} alt="profile" />
											</div>
										) : (
											<div className="profilePict">
												<img src={user_placeholder} alt="profile" />
											</div>
										)}
										<p className="user-information-value">
											<strong>First Name:</strong> {currentUser?.firstName}
										</p>
										<p className="user-information-value">
											<strong>Last Name:</strong> {currentUser?.lastName}
										</p>
										<p className="user-information-value">
											<strong>Email:</strong> {currentUser?.email}
										</p>
										<p className="user-information-value">
											<strong>Phone Number:</strong> {currentUser?.phoneNumber}
										</p>

										<p className="user-information-value">
											<strong>Role:</strong>{' '}
											{currentUser?.role
												? currentUser?.role
												: 'No role assigned'}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Personal Information */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('personalInfo')}
								>
									<h2 className="text-xl font-bold mb-4">
										Personal Information
									</h2>
									<button>
										{sections.personalInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.personalInfo && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Address:</strong>{' '}
											{userSettings?.metadata?.address
												? userSettings?.metadata?.address
												: ''}
										</p>
										<p className="user-information-value">
											<strong>City:</strong>{' '}
											{userSettings?.metadata?.city
												? userSettings?.metadata?.city
												: ''}
										</p>
										<p className="user-information-value">
											<strong>State:</strong>{' '}
											{userSettings?.metadata?.state
												? userSettings?.metadata?.state
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Zip:</strong>{' '}
											{userSettings?.metadata?.zip
												? userSettings?.metadata?.zip
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Birth Date:</strong>{' '}
											{userSettings?.metadata?.birthDate
												? userSettings?.metadata?.birthDate
												: ''}
										</p>
										<p className="user-information-value">
											<strong
												style={{
													textTransform: 'capitalize',
												}}
											>
												Gender:
											</strong>{' '}
											{userSettings?.metadata?.gender
												? userSettings?.metadata?.gender
												: ''}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Employee Details */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('employeeInfo')}
								>
									<h2 className="text-xl font-bold mb-4">Employee Details</h2>
									<button>
										{sections.employeeInfo ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.employeeInfo && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Hire Date:</strong>{' '}
											{userSettings?.metadata?.hireDate
												? userSettings?.metadata?.hireDate
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Manager:</strong>{' '}
											{userSettings?.metadata?.manager
												? userSettings?.metadata?.manager
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Department:</strong>{' '}
											{userSettings?.metadata?.department
												? userSettings?.metadata?.department
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Job Title:</strong>{' '}
											{userSettings?.metadata?.jobTitle
												? userSettings?.metadata?.jobTitle
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Employee ID:</strong>{' '}
											{userSettings?.metadata?.employeeId
												? userSettings?.metadata?.employeeId
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Name to Print on Check:</strong>{' '}
											{userSettings?.metadata?.nameToPrintOnCheck
												? userSettings?.metadata?.nameToPrintOnCheck
												: ''}
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="user-information">
							{/* Emergency Contact */}
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('emergencyContact')}
								>
									<h2 className="text-xl font-bold mb-4">Emergency Contact</h2>
									<button>
										{sections.emergencyContact ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.emergencyContact && (
									<div className="content-block">
										<p className="user-information-value">
											<strong>Contact Name:</strong>{' '}
											{userSettings?.metadata?.emergencyName
												? userSettings?.metadata?.emergencyName
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Contact Phone:</strong>{' '}
											{userSettings?.metadata?.emergencyPhone
												? userSettings?.metadata?.emergencyPhone
												: ''}
										</p>
										<p className="user-information-value">
											<strong>Emergency Contact Relation:</strong>{' '}
											{userSettings?.metadata?.emergencyRelation
												? userSettings?.metadata?.emergencyRelation
												: ''}
										</p>
									</div>
								)}
							</div>
						</div>

						{/* Update Commissions */}
						{currentUser?.role === 'DEALER' ||
							(currentUser?.role === 'SALESMAN' && (
								<div className="user-information">
									<div className="user-information-block">
										<div
											className="title-block"
											onClick={() => toggleSection('updateCommision')}
										>
											<h2 className="text-xl font-bold mb-4">Commissions</h2>
											<button>
												{sections.updateCommision ? (
													<ChevronDown size={24} />
												) : (
													<ChevronRight size={24} />
												)}
											</button>
										</div>
										{sections.updateCommision && (
											<div className="content-block">
												<p className="user-information-value">
													<strong>Commission:</strong>{' '}
													{userSettings?.metadata?.commission
														? `${userSettings?.metadata?.commission}%`
														: 'Not set'}
												</p>
											</div>
										)}
									</div>
								</div>
							))}
						{currentUser?.role === 'INSTALLER' && (
							<div className="user-information">
								<div className="user-information-block">
									<div
										className="title-block"
										onClick={() => toggleSection('updateCommision')}
									>
										<h2 className="text-xl font-bold mb-4">Commissions</h2>
										<button>
											{sections.updateCommision ? (
												<ChevronDown size={24} />
											) : (
												<ChevronRight size={24} />
											)}
										</button>
									</div>
									{sections.updateCommision && (
										<div className="content-block">
											<p className="user-information-value">
												<strong>Horizontal Roof:</strong>{' '}
												{userSettings?.metadata?.hRoof
													? `${userSettings?.metadata?.hRoof}%`
													: 'Not set'}
											</p>
											<p className="user-information-value">
												<strong>Vertical Roof:</strong>{' '}
												{userSettings?.metadata?.vRoof
													? `${userSettings?.metadata?.vRoof}%`
													: 'Not set'}
											</p>
										</div>
									)}
								</div>
							</div>
						)}
						<div className="user-information">
							<div className="user-information-block">
								<div
									className="title-block"
									onClick={() => toggleSection('payments')}
								>
									<h2 className="text-xl font-bold mb-4">Payments</h2>
									<button>
										{sections.payments ? (
											<ChevronDown size={24} />
										) : (
											<ChevronRight size={24} />
										)}
									</button>
								</div>
								{sections.payments && (
									<table className="content-block table-auto w-full">
										<thead>
											<tr>
												<th className="w-3/4 px-4 py-2">Title / Description</th>
												<th className="w-1/4 px-4 py-2">Download</th>
											</tr>
										</thead>
										<tbody>
											{userDocuments
												?.filter((doc) => doc.type === 1)
												.map((doc, index) => (
													<tr key={index} className="border-t">
														<td className="px-4 py-2">{doc.title}</td>
														<td className="px-4 py-2">
															<a
																href={doc.filePath}
																target="_blank"
																rel="noreferrer"
																className="flex justify-center"
															>
																<Download size={24} />
															</a>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								)}
							</div>
						</div>
					</>
				)}
			</aside>
		</div>
	);
}
