import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/userSlice';
import ordersSlice from '../slices/ordersSlice';
import workOrdersSlice from '../slices/workOrdersSlice';
import clientSlice from '../slices/clientSlice';
import attendance from '../slices/attendanceSlice';
import materialsSlice from '../slices/materialsSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    attendance: attendance,
    clients: clientSlice,
    orders: ordersSlice,
    workOrders: workOrdersSlice,
    materials: materialsSlice,
});

 export default rootReducer;

