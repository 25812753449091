import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit';
import api from '../config/api'; 

interface ErrorType {
    message: string; 
  }


export const fetchAttendance = createAsyncThunk(
    'users/fetchAttendance',
    async (_, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.get('/Attendances?pageSize=1000&sortOrder=DESC', { headers });
        return response.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  // post /Attendance token required
  interface CreateAttendancePayload {
    date: string;
    checkInTime: string;
    IsBreak: boolean;
  }

  export const createAttendance = createAsyncThunk(
    'users/createAttendance',
    async (attendanceData: CreateAttendancePayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.post('/Attendances', attendanceData, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // PUT /Attendance/{Id} token required

  interface UpdateAttendancePayload {
    id: string;
    date: string;
    checkInTime: string;
    checkOutTime: string;
  }

  export const updateAttendance = createAsyncThunk(
    'users/updateAttendance',
    async (attendanceData: UpdateAttendancePayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/Attendances/${attendanceData.id}`, attendanceData, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  // search attendance by date userId, startDate, endDate, sortOrder = DESC, pageSize = 1000

  interface SearchAttendancePayload {
    userId: string;
    startDate: string;
    endDate: string;
  }

  export const searchAttendance = createAsyncThunk(
    'users/searchAttendance',
    async (searchData: SearchAttendancePayload, { rejectWithValue, getState }) => {
      try {
        const token = (getState() as any).auth.token;
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/Attendances?userId=${searchData.userId}&startDate=${searchData.startDate}&endDate=${searchData.endDate}&sortOrder=DESC&pageSize=1000`, { headers });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );


  const attendanceSlice = createSlice({
    name: 'attendance',
    initialState: {
      items: [],
      searchItems: [],
      status: 'idle',
      clockin: null,
      clockout: null,
      error: null as ErrorType | null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAttendance.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchAttendance.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.items = action.payload;
        })
        .addCase(fetchAttendance.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message ? { message: action.error.message } : null;
        })
        .addCase(createAttendance.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createAttendance.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.clockin = action.payload;
        })
        .addCase(createAttendance.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message ? { message: action.error.message } : null;
        })
        .addCase(updateAttendance.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(updateAttendance.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.clockout = action.payload;
        })
        .addCase(updateAttendance.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message ? { message: action.error.message } : null;
        })
        .addCase(searchAttendance.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(searchAttendance.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.searchItems = action.payload;
        })
        .addCase(searchAttendance.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message ? { message: action.error.message } : null;
        });
    }
  });


    export default attendanceSlice.reducer;