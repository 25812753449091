// Create a hook to handle delete confirmation popup

import { useState } from 'react';

export default function usePopup() {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(null);

	const openPopup = (id) => {
		setIsOpen(true);
		setSelectedId(id);
	};

	const closePopup = () => {
		setIsOpen(false);
		setSelectedId(null);
	};

	return {
		isOpen,
		selectedId,
		openPopup,
		closePopup,
	};
}
