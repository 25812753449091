// tokenService.js
import cookieService from './cookieService';

export const storeToken = (token, expires) => {
	cookieService.set('_bnc', token, { expires: expires });
	if (expires) {
		cookieService.set('_bnct', expires.getTime());
	}
};

const cleanToken = () => {
	cookieService.remove('_bnc');
	cookieService.remove('_bnct');
};

// const getToken = () => {
// 	const token = cookieService.get('_bnc');
// 	const expires = cookieService.get('_bnct');

// 	if (token && expires && new Date().getTime() / 1000 < expires) {
// 		return token;
// 	}

// 	// Token has expired or is not set
// 	return null;
// };
const getToken = () => {
	return cookieService.getCookie('_bnc');
};

const jwService = {
	storeToken,
	cleanToken,
	getToken,
};

export default jwService;
