import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import SummaryBtns from '../components/SummaryBtns';

export default function Dashboard() {
	return (
		<div className="flex flex-col h-screen dashboard-page">
			<Header />
			<div className="flex flex-1">
				<Sidebar />
				<main className="dashboard-graph-content">
					<SummaryBtns />
				</main>
			</div>
		</div>
	);
}
